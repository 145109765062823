import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { CategoriesData } from '../../../../data/CategoriesData';
import classnames from 'classnames';
import styles from './ExpandableList.module.scss'
import { useTranslation } from 'react-i18next';
import { List, ListItemText } from '@mui/material';

const ExpandableList = ({ closeMenu = () => {} }) => {
  const { CategoriesTranslations, CategoriesImages, SubCategoriesTranslations, Categories, SubCategories } = CategoriesData();
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <List>
      {Categories.map((category) => {
        return (
          <Link
              key={category.name}
              to={`/${i18n.language}/places/category/${category.id}`} 
              className={classnames(styles.CategotyItem)}
              style={{textDecoration: 'none'}}
              sx={{
                bgcolor: '#1A2532', 
                color: 'white',
                boxShadow: 'none',
                border: 'none',
                '&:before': {
                  display: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0 !important',
                  borderBottom: 'none !important',
                },
                '&.MuiAccordion-root': {
                  borderBottom: 'none !important',
                }
              }}
            >
              <div
                className={classnames(styles.sideMenuTextListItem)}
                onClick={() => closeMenu(true)}
              >
                <img src={CategoriesImages[category.name]} alt={category.name} className={classnames(styles.categoryIcon)} />
                {CategoriesTranslations[category.name]}
              </div>
          </Link>
        );
      })}
    </List>
  );
};

export default React.memo(ExpandableList);
