import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import ResponsiveHeader from './components/header/Header';
import HomePage from './layouts/home/HomePage';
import LoginPage from './layouts/authorization/login/LoginPage';
import RegistrationPage from './layouts/authorization/registration/RegistrationPage';
import './assets/css/variables.scss'
import './assets/css/helper.scss'
import Footer from './components/footer/Footer';
import DynamicComponent from './components/dynamicHeader/DynamicComponent';
import './App.scss'
import CategoryPlacesPage from './layouts/category_places/CategoryPlacesPage';
import AllPlacesPage from './layouts/all_places/AllPlacesPage';
import AllEventsPage from './layouts/all_events/AllEventsPage';
import { Helmet } from 'react-helmet';
import BackToTopButton from './components/mui/backToTopButton/BackToTopButton';
import TagManager from 'react-gtm-module';
import AdminDynamicComponent from './components/dynamicHeader/AdminDynamicComponent';
import { isUserAdmin } from './services/cookie/CookieManager.js';
import { getRequest } from './services/fetch/AxiosHelper.js';
import LanguageWrapper from './layouts/common/LanguageWrapper.js';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import { ClipLoader } from 'react-spinners';
import PartnershipRequestPage from './layouts/partnership/PartnershipRequestPage.js';
import ThankYouPagePartnership from './layouts/partnership/ThankYouPage.js';

const PasswordPage = lazy(() => import('./layouts/authorization/password/PasswordPage'));
const RecoveryReqeustPage = lazy(() => import('./layouts/authorization/recovery/RecoveryReqeustPage'));
const AboutUs = lazy(() => import('./layouts/about_us/AboutUs'));
const ProfilePage = lazy(() => import('./layouts/profile/ProfilePage'));
const EventList = lazy(() => import('./layouts/event_list/EventList'));
const AdminHeader = lazy(() => import('./layouts/admin_panel/header/AdminHeader'));
const NotFoundPage = lazy(() => import('./layouts/common/NotFound'))
const CookieBanner = lazy(() => import('./components/cookie/CookieBanner'))
const ConfirmMailPage = lazy(() => import('./layouts/confirm/ConfirmMailPage.js'))
const AdminPanelRoutes = lazy(() => import('./layouts/common/AdminPanelRoutes.js'))
const PlacePage = lazy(() => import('./layouts/places/PlacePage'))
const EventPage = lazy(() => import('./layouts/events/EventPage'))
const NewsListPage = lazy(() => import('./layouts/news/list/NewsListPage'))
const NewsReview = lazy(() => import('./layouts/news/review/NewsReview'))
const NewsSelection = lazy(() => import('./layouts/news/selection/NewsSelection'))
const FranchisePage = lazy(() => import('./layouts/franchise/FranchisePage.js'))


const App = () => {

  const [nameUpdated, setNameUpdated] = useState(true);
  const navigate = useNavigate();
  const PageViewTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: location.pathname,
          pageTitle: document.title
        }
      });
    }, [location]);
    return null;
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-M6WKT3K6'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const checkUserInfo = () => {
    getRequest("/user/allowed")
              .then((response) => response.data)
              .then((data) => {
                if (data.role !== 'ADMIN') {
                  navigate('/404'); 
                }
              })
              .catch((error) => {
                  console.log(error)
                  navigate('/404'); 
              });
  }

  const ProtectedRoute = ({ children }) => {
    
    const isAuthenticated = () => {
      return isUserAdmin(); 
    };
  
    useEffect(() => {
      if (!isAuthenticated()) {
        checkUserInfo()
      }
    }, []);
  
    return isAuthenticated() ? children : null; 
  };

  const { i18n } = useTranslation();
  const languages = ["en", "lv", "ru"];

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      const pathSegments = window.location.pathname.split('/').filter(Boolean);
      if (languages.includes(pathSegments[0])) {
        pathSegments[0] = lng;
      } else {
        pathSegments.unshift(lng);
      }
      navigate('/' + pathSegments.join('/'));
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
    
  }, [i18n, navigate]);

  return (
    <div>
      <Provider store={store}>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "We Go Tonight",
              "url": "https://wegotonight.com",
              "logo": "https://wegotonight.com/favicon_96x96.png"
            }
          `}
        </script>
      </Helmet>
      <DynamicComponent>
        <ResponsiveHeader nameUpdated={nameUpdated}/>
      </DynamicComponent>
      <AdminDynamicComponent>
        <AdminHeader/>
      </AdminDynamicComponent>
      <div className="page-container">
        <Suspense fallback={
          <div className={"loading"} > 
            <ClipLoader color="#FE754B" size={250}/> 
          </div>
        }>
        <PageViewTracker />
        <LanguageWrapper>
          <Routes>
              {languages.map((appLang) => {
                return (
                  <Route key={appLang} path={`/${appLang}/`}>

                    <Route index element={<HomePage />} />
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="places">
                      <Route index element={<Navigate to="all" />}  />
                      <Route path=":name" element={<PlacePage />} />
                      <Route path="category/:categoryId" element={<CategoryPlacesPage />} />
                      <Route path="all" element={<AllPlacesPage />} />
                    </Route>

                    <Route path="events">
                      <Route index element={<Navigate to="all" />}  />
                      <Route path=":name" element={<EventPage />} />
                      <Route path="all" element={<AllEventsPage />} />
                    </Route>

                    <Route path="news">
                      <Route index element={<Navigate to="all" />}  />
                      <Route path="all" element={<NewsListPage />} />
                      <Route path="review/:newsId" element={<NewsReview />} />
                      <Route path="selection/:newsId" element={<NewsSelection />} />
                    </Route>

                    <Route path="event-list">
                      <Route path=":name" element={<EventList />} />
                    </Route>

                    <Route path="profile" element={<ProfilePage  nameUpdated={() => setNameUpdated(!nameUpdated)}/>} />

                    <Route path="about-us" element={<AboutUs />} />

                    <Route path="partnership" element={<PartnershipRequestPage />} />        
                    <Route path="partnership/thank-you" element={<ThankYouPagePartnership />} />
                    <Route path="franchise/:franchiseId" element={<FranchisePage />} />
                    <Route 
                      path="adminPanel/*" 
                      element={
                        <ProtectedRoute>
                          <AdminPanelRoutes />
                        </ProtectedRoute>
                      } 
                    />

                    <Route path="auth">
                        <Route path="login" element={<LoginPage/>} />
                        <Route path="registration" element={<RegistrationPage/>} />
                        <Route path="forgot" element={<RecoveryReqeustPage/>} /> 
                        <Route path="password">
                          <Route path=":name" element={<PasswordPage />} />
                        </Route> 
                        <Route path="confirm">
                          <Route index element={<Navigate to="/" />}  />
                          <Route path=":token" element={<ConfirmMailPage />} />
                    </Route> 
                    
                    </Route>
                  </Route>
                );
              })}
              

          </Routes>
          </LanguageWrapper>
        </Suspense>
      </div>
      <DynamicComponent>
        <Footer />
      </DynamicComponent>
      <CookieBanner />
      <BackToTopButton />
      </Provider>
    </div>
  );
};

export default App;