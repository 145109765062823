import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import classnames from 'classnames';
import styles from './CategoryPlacesPage.module.scss'
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { CategoriesData } from '../../data/CategoriesData';
import { Helmet } from 'react-helmet';
import TitlesData from '../../data/TitlesData';
import PlaceCard from '../../components/mui/placeCard/PlaceCard';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import { useTranslation } from 'react-i18next';
import SortingOrders from '../../data/SortingOrders';
import { Select, MenuItem, Button, Checkbox, Popover, Collapse } from '@mui/material';
import { ReactComponent as CustomArrowIcon } from '../../assets/images/common/orange-dropdown.svg'
import { ReactComponent as  WhiteDropDownLess } from '../../assets/images/common/white-dropdown-less.svg'
import { ReactComponent as  WhiteDropDownMore } from '../../assets/images/common/white-dropdown-more.svg'
import CheckIcon from '@mui/icons-material/Check';
import OrangeButtonVioletHover from '../../components/mui/orangeButton/OrangeButtonVioletHover';
import clearImageOrange  from '../../assets/images/common/clear-orange.svg';
import clearImageWhite from '../../assets/images/common/clear-white.svg';
import largeWhiteCloseIcon from '../../assets/images/common/large-white-close-icon.svg';
import { useNavigate, useLocation  } from 'react-router-dom';
import { CommonUtils } from '../../utils/CommonUtils';

const CategoryPlacesPage = () => {

  const { t, i18n } = useTranslation();
  const { CategoriesTranslations, Categories, CategoriesTranslationsNotResolved } = CategoriesData();
  
  const navigate = useNavigate();
  const location = useLocation();
  const initialMount = useRef(true);
  const params = useParams()
  const {Titles} = TitlesData()
  const prevCategoryIdRef = useRef();
  const prevLanguage = useRef(i18n.language);
  const [categoryName, setCategoryName] = useState('')
  const { getFormmatedWorkingHours } = CommonUtils();

  const linksData = [
    { path: "/", label: "home" },
    { path: `/places/category/${params.categoryId}`, label: CategoriesTranslationsNotResolved[categoryName] }
  ];

  const { SortingOrder, SortingOrderTranslation } = SortingOrders();
  const [sortingOrder, setSortingOrder] = useState(SortingOrder.ALPHABETICAL)

  const [placesList, setPlacesList] = useState();

  const [filters, setFilters] = useState({ categories: [], features: [], classifications: [] });
  const [selectedFilters, setSelectedFilters] = useState({ categoryIds: [parseInt(params.categoryId)], classificationsIds: [], featureIds: [] });
  const [removedSelectedFilters, setRemovedSelectedFilters] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const [openClassifications, setOpenClassifications] = useState(false)
  const [openFeatures, setOpenFeatures] = useState(false)

  const getClassificationNameById = (id) => {
    const classification = filters.classifications?.find(classification => classification.id === id);
    return classification ? classification.name : null;
  }

  const parseFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      categoryIds: searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').map(Number) : [],
      classificationsIds: searchParams.get('classificationsIds') ? searchParams.get('classificationsIds').split(',').map(Number) : [],
      featureIds: searchParams.get('featureIds') ? searchParams.get('featureIds').split(',').map(Number) : [],
      sortingOrder: searchParams.get('sortingOrder') || SortingOrder.ALPHABETICAL,
      scrollY: searchParams.get('scrollY') || 0
    };
  }

  const getFeatureNameById = (id) => {
    const feature = filters.features?.find(feature => feature.id === id);
    return feature ? feature.name : null;
  }

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  
  const handleDropdownChange = (event) => {
    const newSortingOrder = event.target.value;
    setPlacesList([]);
    setSortingOrder(newSortingOrder);

    const searchParams = new URLSearchParams(selectedFilters);
    searchParams.set('sortingOrder', newSortingOrder);
    const newParam = searchParams.toString()
    navigate(`?${newParam}`, { replace: true });
    const request = {
      categoryIds: selectedFilters.categoryIds,
      classificationsIds: selectedFilters.classificationsIds,
      featureIds: selectedFilters.featureIds,
      sortingOrder: newSortingOrder
    }
    getPlaces(request)
  };

  const getFiltersByCriteria = (criteria) => {
    setRemovedSelectedFilters(false)
    postCsrf(`filter/available?language=${i18n.language}`, JSON.stringify(criteria))
            .then((response) => response.data)
            .then((data) => {
              setFilters(prevFilters => ({
                ...prevFilters,
                classifications: data.classifications || [],
                features: data.features || []
              }));

              const updatedSelectedFilters = { ...selectedFilters };
              updatedSelectedFilters.categoryIds = [params.categoryId]
              if (data.classifications != null) {
                  updatedSelectedFilters.classificationsIds = selectedFilters.classificationsIds.filter(id => data.classifications?.find(cls => cls.id === id));
              }
              if (data.features != null) {
                  updatedSelectedFilters.featureIds = selectedFilters.featureIds.filter(id => data.features?.find(feat => feat.id === id));
              }

              if ((updatedSelectedFilters.classificationsIds?.length < selectedFilters.classificationsIds?.length) || (updatedSelectedFilters.featureIds?.length < selectedFilters.featureIds?.length)) {
                setRemovedSelectedFilters(true)
                setSelectedFilters(updatedSelectedFilters)
              }
            })
            .catch((error) => {
                console.log(error)
            });
  }

  const clearAllFilters = () => {
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      classificationsIds: [],
      featureIds: []
    }));
  }

  const handleFilterChange = (filterType, filterId) => {
    setSelectedFilters((prevSelectedFilters) => {
      const isSelected = prevSelectedFilters[filterType].includes(filterId);
  
      let newFilterIds;
      if (isSelected) {
        newFilterIds = prevSelectedFilters[filterType].filter(id => id !== filterId);
      } else {
        newFilterIds = [...prevSelectedFilters[filterType], filterId];
      }
  
      return {
        ...prevSelectedFilters,
        [filterType]: newFilterIds,
      };
    });
  };

  const getPlaces = (filters) => {
    let classificationsIds = []
    let featureIds = []
    let newSortingOrder = sortingOrder
    const prevCategoryId = prevCategoryIdRef.current;
    if (!filters) {
      if (params.categoryId !== prevCategoryId && prevCategoryId) {
        featureIds = []
        classificationsIds = []
        newSortingOrder = SortingOrder.ALPHABETICAL
      } else {
        featureIds = selectedFilters.featureIds
        classificationsIds = selectedFilters.classificationsIds
        newSortingOrder = sortingOrder
      }
    } else {
      featureIds = filters.featureIds
      classificationsIds = filters.classificationsIds
      newSortingOrder = filters.sortingOrder
    }
    
    getRequest(`places/category?categoryId=${params.categoryId}&sort=${newSortingOrder}&language=${i18n.language}&classificationIds=${classificationsIds}&featureIds=${featureIds}`)
        .then((response) => response.data)
        .then((data) => {
            setPlacesList(data)
            let scrollY = parseFiltersFromURL().scrollY
            setTimeout(() => {
              window.scrollTo({
                left: 0,
                top: scrollY ? scrollY : 0,
                behavior: 'smooth'
              });
            }, 300);
        })
        .catch((error) => {
            console.log(error)
        });
    prevCategoryIdRef.current = params.categoryId;
  }

  const onSaveFilters = () => {
    setAnchorEl(null);
    const searchParams = new URLSearchParams(selectedFilters);
    searchParams.set('sortingOrder', sortingOrder);
    const stringParams = searchParams.toString();
    navigate(`?${stringParams}`, { replace: true });
    getPlaces()
  }

  useEffect(() => {
    setSelectedFilters(({categoryIds: [params.categoryId], classificationsIds: [], featureIds: []}));
    setFilters(({categories: [], classifications: [], features: []}));
    setOpenClassifications(false)
    setOpenFeatures(false)
    const urlFilters = parseFiltersFromURL()
    if (params.categoryId !== prevCategoryIdRef.current && prevCategoryIdRef.current) {
      setSortingOrder(SortingOrder.ALPHABETICAL)
    } else {
      setSortingOrder(urlFilters.sortingOrder)
    }
    if (urlFilters.classificationsIds?.length > 0 || urlFilters.featureIds?.length > 0) {
      setSelectedFilters(urlFilters)
      setSortingOrder(urlFilters.sortingOrder)
      getFiltersByCriteria(urlFilters)
      getPlaces(urlFilters)
    } else {
      getFiltersByCriteria(selectedFilters)
    }
    let category = Categories.find((category) => category.id.toString() === params.categoryId)
    setCategoryName(category.name)
  }, [params.categoryId]);

  useEffect(() => {
    if (removedSelectedFilters) {
      setRemovedSelectedFilters(false)
    }
    if (initialMount.current) {
      const newFilters = { categoryIds: selectedFilters.categoryIds, featureIds: [], classificationsIds: [] };
      const urlFilters = parseFiltersFromURL()
      if (urlFilters.classificationsIds?.length === 0 && urlFilters.featureIds?.length === 0) {
          getFiltersByCriteria(newFilters);
      }
    } else {
      getFiltersByCriteria(selectedFilters)
    }

    initialMount.current = false;
  }, [selectedFilters, i18n.language]); 

  useEffect(() => {
    if (i18n.language !== prevLanguage.current) {
      prevLanguage.current = i18n.language;
      const searchParams = new URLSearchParams(selectedFilters);
      searchParams.set('sortingOrder', sortingOrder);
      searchParams.set("scrollY", window.scrollY)
      const parameters = searchParams.toString()
      navigate(`?${parameters}`, { replace: true });
      getPlaces()
    }
  }, [i18n.language]); 


  useEffect(() => {
    const urlFilters = parseFiltersFromURL()
    if (urlFilters.classificationsIds?.length > 0 || urlFilters.featureIds?.length > 0) {
      setSelectedFilters(urlFilters);
      getPlaces(urlFilters)
    }
  }, [location.search]);

  useEffect(() => {
    const urlFilters = parseFiltersFromURL()

    const request = {
      categoryIds: urlFilters.categoryIds,
      classificationsIds: urlFilters.classificationsIds,
      featureIds: urlFilters.featureIds,
      sortingOrder: urlFilters.sortingOrder
    }

    getPlaces(request)

  }, [params.categoryId], sortingOrder);

  useEffect(() => {
    let category = Categories.find((category) => category.id.toString() === params.categoryId)
    setCategoryName(category.name)
  }, [])

  return (
    <div className={classnames(styles.mainContent)}>
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{Titles.wgt + CategoriesTranslations[categoryName]}</title>
        </Helmet>
        <div className={classnames('row', styles.row, styles.timePeriod)}>
            <DynamicBreadcrumbs links={linksData} />
            <div className={classnames('row', styles.row, 'd-flex', 'justify-content-left', 'mt-5')}>
                <div className={classnames('col-12')}>
                    <h2>{CategoriesTranslations[categoryName]}</h2>
                    <i className={classnames(styles.dividingLine)}></i>
                </div>
            </div>

            <div className={classnames(styles.filterAndSortingRow)}>
              <div className={classnames(styles.sortingWrapper, 'col-6', 'col-xxl-4', 'col-xl-4', 'col-lg-5', 'col-md-4', 'col-sm-6')}>
                <Select
                      renderValue={() => t("sort")}
                      value={sortingOrder}
                      onChange={handleDropdownChange}
                      IconComponent={CustomArrowIcon}
                      sx={{
                        width: '100%',
                        height: '40px',
                        color: 'white',
                        fontFamily: 'Arial',
                        fontSize: '15px',
                        border: '1px solid #FE754B',
                        borderRadius: '8px',
                        '& ,MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiPaper-root': {
                          backgroundColor: '#1A2532',
                      
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          boxShadow: 'none',
                          outline: 'none',
                          border: 'none'
                        },
                        '& .MuiSelect-icon': {
                          marginRight: '5px'
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: 'transparent',
                          },
                        },
                        sx: {
                          '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: 'transparent',
                            '&:hover': {
                              backgroundColor: 'inherit',  
                            }
                          },
                          '& .MuiMenuItem-root.Mui-selected.Mui-focusVisible': {
                            backgroundColor: 'transparent'
                          },
                          marginTop: '10px',
                          '& .MuiList-root': {
                            backgroundColor: '#1A2532',
                            border: '1px solid #FE754B',
                            borderRadius: '8px',
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#FE754B',
                              color: 'white', 
                            } 
                          },
                        },
                      }}
                    >
                      {Object.entries(SortingOrder).map(([key, value]) => (
                        <MenuItem key={key} value={value} sx={{ 
                          color: 'white',
                          '&:hover svg': {
                            color: 'white'
                          }
                          }}>
                          {SortingOrderTranslation[key]}
                          {sortingOrder === value && <CheckIcon sx={{pl: '10px', ml: 'auto', color: '#FE754B' }} />} 
                        </MenuItem>
                      ))}
                </Select>
              </div>
              <div className={classnames(styles.filterWrapper, 'col-6', 'col-xxl-4', 'col-xl-4', 'col-lg-5', 'col-md-4', 'col-sm-6')}>
                <Button
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    aria-describedby={popoverId}
                    variant="contained"
                    onClick={handleClickPopover}
                    sx={{
                      width: '100%',
                      backgroundColor: '#FE754B',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontFamily: 'Arial',
                      fontSize: '15px',
                      fontWeight: '400', 
                      '& .MuiButton-endIcon': {
                        position: 'absolute',
                        right: '8px',
                        marginLeft: '0',
                        marginRight: '5px'
                      },
                      ':hover': {
                        backgroundColor: '#4137AF'
                      }
                    }}
                    endIcon={openPopover ? <WhiteDropDownLess /> : <WhiteDropDownMore /> }
                >
                  {t("filters")}
                </Button>

                <Popover
                  id={popoverId}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  marginThreshold={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '.MuiPopover-paper': {
                      width: anchorEl?.clientWidth,
                      maxWidth: anchorEl?.clientWidth,
                      border: "1px solid #FE754B",
                      marginTop: '10px',
                      backgroundColor: '#1A2532',
                      maxHeight: '100%!important',
                      
                      '@media (max-width:992px)': {
                        position: 'fixed',
                        top: '0!important',
                        left: '0!important',
                        right: '0!important',
                        bottom: '0!important',
                        padding: '0!important',
                        margin: '0!important',
                        width: '100%!important',
                        height: '100%!important',
                        maxWidth: '100%!important',
                        maxHeight: '100%!important',
                        borderRadius: 0,
                        border: 'none',
                      }
                    }
                  }}
                >
                  <div 
                    className={classnames(styles.mobileFiltersHeadingContainer)}
                  >
                      <div 
                          className={classnames(styles.filtersMobileHeading)}
                      >
                          {t("filters")}
                      </div>
                      <img 
                          className={classnames(styles.closeFiltersIcon)}
                          src={largeWhiteCloseIcon}
                          alt=""
                          onClick={() => setAnchorEl(null)}
                      />
                          
                  </div>

                  <div 
                    className={classnames(styles.appliedFiltersHeading)}
                  >
                      <div className={classnames(styles.appliedFiltersTitle)}>
                          {t("appliedFilters")}
                      </div>
                      <div
                        onClick={() => clearAllFilters()}
                        className={classnames(styles.clearFilters)}
                      >
                          <div className={classnames(styles.clearTitle)}>
                            {t("clearAll")}
                          </div>
                          <img 
                            src={clearImageOrange}
                            alt=""
                            />
                      </div>
                  </div>

                  <div className={classnames(styles.appliedFiltersContainer)}>
                      {
                          (selectedFilters?.classificationsIds?.length === 0 && selectedFilters?.featureIds?.length === 0) ? (
                            <div className={classnames(styles.appliedFilterWrapperEmpty)}>
                                <img 
                                  alt=""
                                  src={clearImageWhite} 
                                  />
                            </div>
                          ) : (
                              <>
                                  {
                                      selectedFilters?.classificationsIds?.map((classificationId) => {
                                          return (
                                              <div className={classnames(styles.appliedFilterWrapper)}>
                                                  <div className={classnames(styles.filterName)}>
                                                      {getClassificationNameById(classificationId)}
                                                  </div>
                                                  <img 
                                                      onClick={() => handleFilterChange('classificationsIds', classificationId)}
                                                      className={classnames(styles.removeFilterIcon)} 
                                                      src={clearImageWhite}
                                                      alt=""
                                                  />
                                              </div>
                                          );
                                      })
                                  }
                                  {
                                      selectedFilters?.featureIds?.map((featureId) => {
                                          return (
                                              <div className={classnames(styles.appliedFilterWrapper)}>
                                                  <div className={classnames(styles.filterName)}>
                                                      {getFeatureNameById(featureId)}
                                                  </div>
                                                  <img 
                                                      onClick={() => handleFilterChange('featureIds', featureId)}
                                                      className={classnames(styles.removeFilterIcon)} 
                                                      src={clearImageWhite}
                                                      alt=""
                                                  />
                                              </div>
                                          );
                                      })
                                  }
                              </>
                          )
                      }
                  </div>
     

                  { filters.classifications?.length > 0  && 
                  <>
                  <div
                    onClick={() => {
                      setOpenClassifications(!openClassifications);
                      if (openFeatures) setOpenFeatures(false);
                    }}
                    className={classnames(styles.filterHeadingPopover)}
                    >
                    <div>{t("specificity")}</div>
                    {openClassifications ? <WhiteDropDownLess /> : <WhiteDropDownMore />}
                  </div>
                
                  <Collapse in={openClassifications} timeout="auto" unmountOnExit>
                    <div className={classnames(styles.filetItemsContainer)}>
                      {
                        filters.classifications?.map((filter) => (
                          <MenuItem 
                            disableRipple
                            disableTouchRipple
                            key={filter.id} value={filter.id}
                            onClick={() => handleFilterChange('classificationsIds', filter.id)}
                            >
                              <Checkbox 
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                  color: "#FE754B",
                                  marginRight: '10px',
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "#FE754B",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: 0,
                                    padding: 0,
                                  },
                                  "& svg": {
                                    scale: "1.4",
                                  },
                                  '&.Mui-checked': {
                                    color: '#FE754B',
                                    backgroundColor: "white",
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 20,
                                      color: "FE754B",
                                    },
                                    "&.MuiCheckbox-root": {
                                      borderRadius: 0,
                                      padding: 0,
                                    },
                                    "& svg": {
                                      scale: "1.4",
                                    },
                                  }
                                }}
                                checked={selectedFilters["classificationsIds"].includes(filter.id)} 
                              />
                              <div className={classnames(styles.filterItemText)}>
                                {filter.name}
                              </div>
                               
                          </MenuItem>          
                        ))
                      }
                    </div>
                  </Collapse>
                  </>
                  }
                  <div 
                    onClick={() => {
                      setOpenFeatures(!openFeatures);
                      if (openClassifications) setOpenClassifications(false);
                    }}
                    className={classnames(styles.filterHeadingPopover)}
                    >
                    <div>{t("features")}</div>
                    {openFeatures ? <WhiteDropDownLess /> : <WhiteDropDownMore />}
                  </div>
                
                  <Collapse in={openFeatures} timeout="auto" unmountOnExit>
                    <div className={classnames(styles.filetItemsContainer)}>
                      {
                        filters.features?.map((filter) => (
                          <MenuItem 
                            disableRipple
                            disableTouchRipple
                            onClick={() => handleFilterChange('featureIds', filter.id)}
                            key={filter.id} value={filter.id}>
                            <Checkbox
                              disableRipple
                              disableFocusRipple
                              disableTouchRipple
                              checked={selectedFilters["featureIds"].includes(filter.id)}
                              sx={{
                                color: "#FE754B",
                                marginRight: '10px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                  color: "#FE754B",
                                },
                                "&.MuiCheckbox-root": {
                                  borderRadius: 0,
                                  padding: 0,
                                },
                                "& svg": {
                                  scale: "1.4",
                                },
                                '&.Mui-checked': {
                                  color: '#FE754B',
                                  backgroundColor: "white",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "FE754B",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: 0,
                                    padding: 0,
                                  },
                                  "& svg": {
                                    scale: "1.4",
                                  },
                                }
                              }}
                              />
                              <div className={classnames(styles.filterItemText)}>
                                  {filter.name}
                              </div>
                          </MenuItem>
                                      
                        ))
                      }
                    </div>
                  </Collapse>
                  <div className={classnames(styles.buttonWrappenSave)}>
                    <OrangeButtonVioletHover
                      title={t("save")}
                      onClick={() => onSaveFilters()}
                    >

                    </OrangeButtonVioletHover>
                  </div>

                </Popover>
              </div>
            </div>
        </div>
        <div className={classnames('row', styles.row, 'mt-3')}>
            {
            placesList?.map((place) => {
                return (            
                    <div key={place.id} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                        <PlaceCard
                            title={place.placeName}
                            description={place.placeDescription}
                            logo={place.mainImage}
                            date={getFormmatedWorkingHours(place.workingHours)}
                            uri={`/places/${place.uri}`}
                            isFavourite={place.isFavourite}
                            address={place.placeAddress}
                            rating={place.rating}
                            reviewCount={place.reviewCount}
                            placeSpecifications={place.placeSpecifications}
                            breadcrumbs={linksData}
                            isWgtChoice={place.isWgtChoice}
                            onNavigate={() => {
                              const searchParams = new URLSearchParams(selectedFilters);
                              searchParams.set('sortingOrder', sortingOrder);
                              searchParams.set("scrollY", window.scrollY)
                              const parameters = searchParams.toString()
                              navigate(`?${parameters}`, { replace: true });
                            }}
                            />                                    
                    </div>
                );
            })
            }
        </div>
    </div>
  );
};

export default CategoryPlacesPage;