import axios from 'axios';
import { getAuthToken } from '../cookie/CookieManager';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

export const postCsrf = async (path, data) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.post(path, data, config);
}

export const getRequest = (path) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    
    return axios.get(path, config);
}

export const deleteCsrf = async (path) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.delete(path, config);
}

export const postCsrfForm = async (path, data) => {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    let config = {}
    if (getAuthToken() !== null && getAuthToken() !== "null" && typeof(getAuthToken()) !== "undefined") {
        config = {
            headers : {'Authorization': `Bearer ${getAuthToken()}`}
        };
    }
    return axios.post(path, data, config);
}