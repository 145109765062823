import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import styles from './PartnershipRequestPage.module.scss'
import { useTranslation } from 'react-i18next';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import { Stepper, Step } from '@mui/material';
import ContactInfoForm from './forms/ContactInfoForm';
import PlaceInfoForm from './forms/PlaceInfoForm';
import SpecificationForm from './forms/SpecificationForm';
import AdditionalInfoForm from './forms/AdditionalInfoForm';
import WorkingHoursForm from './forms/WorkingHoursForm';
import BreakfastHoursForm from './forms/BreakfastHoursForm';
import BusinessLunchHoursForm from './forms/BusinessLunchHoursForm';
import { StyledStepConnector, StyledStepIcon, StyledStepLabel } from './CustomStyles';
import { postCsrf } from '../../services/fetch/AxiosHelper';
import TransparentButton from '../../components/mui/transparentButton/TransparentButton';
import VioletButton from '../../components/mui/violetButton/VioletButton';

const StepContent = ({ step, formData, setFormData, formErrors, setFormErrors, 
                        placeInfo, setPlaceInfo, workingHours, setWorkingHours, 
                        breakfastHours, setBreakfastHours, businessLunchHours, setBusinessLunchHours,
                        selectedClassifications, setSelectedClassifications, selectedFeatures, setSelectedFeatures, 
                        specificationData, setSpecificationData }) => {
  switch (step) {
    case 0:
      return <ContactInfoForm formData={formData} setFormData={setFormData} formErrors={formErrors} setFormErrors={setFormErrors} />;
    case 1:
      return <PlaceInfoForm formData={placeInfo} setFormData={setPlaceInfo} formErrors={formErrors} setFormErrors={setFormErrors} />;
    case 2:
      return <SpecificationForm 
                selectedClassifications={selectedClassifications} setSelectedClassifications={setSelectedClassifications} 
                selectedFeatures={selectedFeatures} setSelectedFeatures={setSelectedFeatures}
                specificationData={specificationData} setSpecificationData={setSpecificationData}
                formErrors={formErrors} setFormErrors={setFormErrors} 
              />;
    case 3:
      return <AdditionalInfoForm formData={placeInfo} setFormData={setPlaceInfo} formErrors={formErrors} setFormErrors={setFormErrors} />;
    case 4:
      return <WorkingHoursForm workingHours={workingHours} setWorkingHours={setWorkingHours} formErrors={formErrors} setFormErrors={setFormErrors} />;
    case 5:
      return <BreakfastHoursForm breakfastHours={breakfastHours} setBreakfastHours={setBreakfastHours} formErrors={formErrors} setFormErrors={setFormErrors} />;
    case 6:
      return <BusinessLunchHoursForm businessLunchHours={businessLunchHours} setBusinessLunchHours={setBusinessLunchHours} formErrors={formErrors} setFormErrors={setFormErrors} />;
    default:
      return 'Unknown Step';
  }  
};

const PartnershipRequestPage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const steps = [t('contactInfo'), t('placeInfo'), t('specifications'), t('additionalInfo'), t('workingHours'), t('breakfasts'), t('businessLunch')];
    const [activeStep, setActiveStep] = useState(0);
    
    const initialFormData = {
      name: '',
      surname: '',
      email: '',
      phone: null,
      place: null,
      workingHours: null,
      breakfastHours: null,
      businessLunchHours: null,
      features: null,
      classifications: null,
      specifications: null
    };

    const initialPlaceInfo = {
      name: '',
      category: '',
      subcategory: '',
      descriptionRu: '',
      descriptionLv: '',
      descriptionEn: '',
      address: '',
      phone: '',
      email: '',
      menuUrl: null,
      facebook: null,
      instagram: null,
      telegram: null,
      site: null,
      wifiName: null,
      wifiPassword: null,
      mainImageUrl: '',
      mainImagePhoneUrl: '',
      subImg1: null,
      subImg2: null,
      subImg3: null,
      subImg4: null,
      parkingType: '',
    };

    const initialSpecificationData = {
      allowSmoking: false,
      allowVaping: false,
      adultsOnly: false
    }
    
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [placeInfo, setPlaceInfo] = useState(initialPlaceInfo);
  const [specificationData, setSpecificationData] = useState(initialSpecificationData);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [workingHours, setWorkingHours] = useState({});
  const [breakfastHours, setBreakfastHours] = useState({});
  const [businessLunchHours, setBusinessLunchHours] = useState({});

    const handleNext = () => {
      if (validateStep(activeStep)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
      if (validateStep(activeStep)) {
        formData.workingHours = formatHours(workingHours);
        formData.breakfastHours = formatHours(breakfastHours);
        formData.businessLunchHours = formatHours(businessLunchHours);
        formData.place = placeInfo;
        formData.classifications = selectedClassifications;
        formData.features = selectedFeatures;
        formData.specifications = specificationData;
        postCsrf('/partnership/send', JSON.stringify(formData))
                .then((response) => response.data)
                .then((data) => {
                  resetForms();
                  navigate(`/${i18n.language}/partnership/thank-you`);
            }).catch((error) => {
                console.error('Error submitting form:', error)
          });
      }
    };


    const resetForms = () => {
      setFormData(initialFormData);
      setPlaceInfo(initialPlaceInfo);
      setSpecificationData(initialSpecificationData);
      setSelectedClassifications([]);
      setSelectedFeatures([]);
      setWorkingHours({});
      setBreakfastHours({});
      setBusinessLunchHours({});
    };

    const formatHours = (hours) => {
      return Object.keys(hours).map(day => ({
        dayOfWeek: day,
        openingTime: formatTime(hours[day]?.openingTime),
        closingTime: formatTime(hours[day]?.closingTime)
      }));
    };

    const linksData = [
        { path: "/", label: "home" },
        { path: "/partnership", label: "partnership" }
    ];

    const formatTime = (dayjsDate) => {
      if (dayjsDate && dayjsDate.isValid()) {
          const date = dayjsDate.toDate();
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
      }
      return null;
    };

    const validateContactInfoForm = () => {
      let errors = {};
      if (formData.name?.trim().length === 0) errors.name = t("thisFieldRequired");
      if (formData.surname?.trim().length === 0) errors.surname = t("thisFieldRequired");
      if (!formData.email) errors.email = t("thisFieldRequired");
      if (!formData.phone) errors.phone = t("thisFieldRequired");
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validatePlaceInfoForm = () => {
      let errors = {};
      if (!placeInfo.name) errors.name = t("thisFieldRequired");
      if (!placeInfo.category) errors.category = t("thisFieldRequired");
      if (!placeInfo.subcategory) errors.subcategory = t("thisFieldRequired");
      if (!placeInfo.descriptionRu && !placeInfo.descriptionLv && !placeInfo.descriptionEn) errors.descriptionEn = t("descriptionPartnershipFormError")
      if (!placeInfo.address) errors.address = t("thisFieldRequired");
      if (!placeInfo.mainImageUrl) errors.mainImageUrl = t("thisFieldRequired");
      if (!placeInfo.mainImagePhoneUrl) errors.mainImagePhoneUrl = t("thisFieldRequired");
      console.log(errors)
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateSpecificationForm = () => {
      let errors = {};
      if (selectedFeatures.length === 0) errors.selectedFeatures = t("thisFieldRequired");
      if (selectedClassifications.length === 0) errors.selectedClassifications = t("thisFieldRequired");
      console.log(errors)
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateAdditionalInfoForm = () => {
      let errors = {};
      if (!placeInfo.phone) errors.phone = t("thisFieldRequired");
      if (!placeInfo.email) errors.email = t("thisFieldRequired");
      if (!placeInfo.parkingType) errors.parkingType = t("thisFieldRequired");
      console.log(errors)
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateWorkingHoursForm = () => {
      let errors = {};

      if (Object.keys(workingHours).length !== 0) {
        Object.keys(workingHours).forEach((day) => {
          if (workingHours[day].openingTime && workingHours[day].closingTime) {

            if (workingHours[day].openingTime.toString() === "Invalid Date" ||
            workingHours[day].closingTime.toString() === "Invalid Date") errors.workingHours = t("thisFieldRequired");

          } else if (!workingHours[day].openingTime && workingHours[day].closingTime ||
            workingHours[day].openingTime && !workingHours[day].closingTime) {
            errors.workingHours = t("thisFieldRequired");
          }

        });
      } else {
        errors.workingHours = t("thisFieldRequired");
      }
      
      console.log(errors);
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateBreakfastsForm = () => {
      let errors = {};

      Object.keys(breakfastHours).forEach((day) => {
        if (breakfastHours[day].openingTime && breakfastHours[day].closingTime) {

          if (breakfastHours[day].openingTime.toString() === "Invalid Date" ||
          breakfastHours[day].closingTime.toString() === "Invalid Date") errors.breakfastHours = t("thisFieldRequired");

        } else if (!breakfastHours[day].openingTime && breakfastHours[day].closingTime ||
                    breakfastHours[day].openingTime && !breakfastHours[day].closingTime) {
          errors.breakfastHours = t("thisFieldRequired");
        }
      });
      
      console.log(errors);
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateBusinessLunchForm = () => {
      let errors = {};

      Object.keys(businessLunchHours).forEach((day) => {
        if (businessLunchHours[day].openingTime && businessLunchHours[day].closingTime) {

          if (businessLunchHours[day].openingTime.toString() === "Invalid Date" ||
          businessLunchHours[day].closingTime.toString() === "Invalid Date") errors.businessLunchHours = t("thisFieldRequired");

        } else if (!businessLunchHours[day].openingTime && businessLunchHours[day].closingTime ||
                    businessLunchHours[day].openingTime && !businessLunchHours[day].closingTime) {
          errors.businessLunchHours = t("thisFieldRequired");
        }
      });
      
      console.log(errors);
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const validateStep = (step) => {
      switch (step) {
        case 0:
          return validateContactInfoForm();
        case 1:
          return validatePlaceInfoForm();
        case 2:
          return validateSpecificationForm();
        case 3:
          return validateAdditionalInfoForm();
        case 4:
          return validateWorkingHoursForm();
        case 5:
          return validateBreakfastsForm();
        case 6:
          return validateBusinessLunchForm();
        default:
          return true;
      }  
    }
    return (
      <div className={classnames(styles.mainContent)}>
        <div className={classnames(styles.infoSection)}>
          <div className={classnames('row', styles.row, styles.bannerContent)}>
            <div className={classnames(styles.breadcrumbs)}>
              <DynamicBreadcrumbs links={linksData} /> 
            </div>
            <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-6', 'col-xl-6', 'col-xxl-6', 'col-12', 'align-self-center')}>
              <div className={classnames('row', styles.row, 'd-flex', 'justify-content-left', styles.titleHeader)}>
                <div className={classnames('col-12')}>
                  <h2>{t("partnership").toUpperCase()}</h2>
                  <i className={classnames(styles.dividingLine)}></i>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames(styles.stepper)}>
            <Stepper activeStep={activeStep} connector={<StyledStepConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StyledStepLabel StepIconComponent={StyledStepIcon}>{label}</StyledStepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          
          <div>
            <div className={classnames(styles.stepContent)}>
                <StepContent 
                  step={activeStep} 
                  formData={formData} setFormData={setFormData} 
                  formErrors={formErrors} setFormErrors={setFormErrors} 
                  placeInfo={placeInfo} setPlaceInfo={setPlaceInfo}
                  selectedClassifications={selectedClassifications} setSelectedClassifications={setSelectedClassifications} 
                  selectedFeatures={selectedFeatures} setSelectedFeatures={setSelectedFeatures}
                  specificationData={specificationData} setSpecificationData={setSpecificationData}
                  workingHours={workingHours} setWorkingHours={setWorkingHours}
                  breakfastHours={breakfastHours} setBreakfastHours={setBreakfastHours}
                  businessLunchHours={businessLunchHours} setBusinessLunchHours={setBusinessLunchHours}  
                />
                <div className={classnames('row', styles.row, styles.navigationButtons, 'justify-content-center')}>

                  <div className={classnames('col-6', 'align-self-center', 'px-4')}>
                    <TransparentButton
                      title={t("back")}
                      isDisabled={activeStep === 0} 
                      onClick={handleBack}/>
                  </div>

                  <div className={classnames('col-6', 'align-self-center', 'px-4')}>
                    {activeStep === steps.length-1 ? (
                      <VioletButton 
                        title={t("submit")}
                        onClick={handleSubmit}/>
                    ) : (
                      <VioletButton 
                        title={t("next")}
                        onClick={handleNext}/>
                    )}
                  </div>
                    
                </div>
              </div>
          </div>
        </div>
    </div>
      );
    };

export default React.memo(PartnershipRequestPage);