import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AdminDynamicComponent = ({ children }) => {
  const location = useLocation();
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    const languagePrefixPattern = "\\/(en|ru|lv)";
    const isAdminPanel = new RegExp(`^${languagePrefixPattern}\/adminPanel`).test(location.pathname);

    setShowNavBar(isAdminPanel);
  }, [location]);

  return (
    <div>
      {showNavBar && children}
    </div>
  );
};

export default AdminDynamicComponent;
