import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styles from './ShareModal.module.scss';
import classnames from 'classnames';
import CopyToClipboardButton from '../../copyToClipboardButton/CopyToClipboardButton';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ShareModal = ({ show, onHide, link }) => {

  const { t, i18n } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCopyClick = () => {
      setSnackbarMessage(t("copiedToClipboard"));
      setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbarOpen(false);
  };

  return (
      <>
        <Modal
            open={show}
            onClose={onHide}
            aria-labelledby="share-modal-title"
            disableAutoFocus={true}
            disableScrollLock={true}
            sx={{
                marginLeft: '20px',
                marginRight: '20px',
            }}
        >
            <Box 
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '360px',
                bgcolor: '#1C1A32',
                border: '2px solid #FE754B',
                color: 'white',
                p: 3,
                borderRadius: '25px'
              }}
            >
                <Snackbar 
                  open={snackbarOpen} 
                  autoHideDuration={500} 
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  sx={{ width: '90%' }}
                >
                  <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                      {snackbarMessage}
                  </Alert>
                </Snackbar>
                <div
                  className={classnames(styles.shareTitle)}
                >
                    {t("share")}
                </div>
                <div
                    className={classnames(styles.shareText)}
                    id="modal-modal-description" 
                    >
                    {t("shareThisLink")}
                </div>

                <div className={classnames(styles.iconTextInput)}>
                    <TextField
                        value={link}
                        disabled={true}
                        FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                        sx={{
                        '&': {
                            width: '100%',
                            marginBottom: '10px', 
                            marginTop: '15px',
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '40px', 
                            color: 'black',
                            backgroundColor: 'white',
                            width: '100%',
                            border: '2px #4137AF solid !important',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            backgroundColor: 'transparent',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white !important'
                          },
                          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white !important',
                          },
                          '& .Mui-focused.MuiInputLabel-root': {
                            color: '#FE754B !important',
                          },
                          '& .MuiInputAdornment-root img': {
                            paddingLeft: '2px',
                            paddingRight: '2px'
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <div onClick={handleCopyClick}>
                                    <CopyToClipboardButton className={classnames(styles.copyIcon)} value={link} />
                                  </div>
                              </InputAdornment>
                          )
                      }}
                    />
                </div>
                <Box 
                  textAlign="center" 
                  sx={{ mt: 2 }}
                  >
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={onHide}
                      sx={{
                        width: '50%',
                        border: '1px solid #4137AF',
                        backgroundColor: "#4137AF",
                        borderRadius: '25px',
                        fontFamily: 'Arial',
                        fontSize: '12px',
                        fontWeight: '400',
                        letterSpacing: '-0.24px',
                        color: 'white',
                        '&:hover': {
                            border: '1px solid #FE754B',
                            backgroundColor: "#FE754B",
                            transition: 'background-color .3s, color .3s, border .3s'
                        }
                      }}
                      >
                        {t("close")}
                    </Button>
                </Box>
            </Box>
        </Modal>
      </>
  );
};

export default React.memo(ShareModal);
