import React, { useEffect, useState, useCallback }  from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import styles from './SideMenu.module.scss'
import  './SideMenu.scss'
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { getRequest } from '../../services/fetch/AxiosHelper';
import { Link, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import closeIconImage from '../../assets/images/common/closeOrange.svg';
import VioletButton from '../mui/violetButton/VioletButton';
import ExpandableList from '../mui/list/expandalbe/ExpandableList';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { getAuthToken, logout } from '../../services/cookie/CookieManager';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export default function SideMenu() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams()
  const [searchResults, setSearchResults] = useState(null)
  const [openMain, setOpenMain] = useState(false);
  const [isUserAuth, setIsUserAuth] = useState(getAuthToken())
  const { pathname } = useLocation();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setOpenMain(isExpanded ? panel : false);
  };

  const handleInput = (newValue) => {
    getRequest(`places/search?placeName=${newValue}`)
    .then((response) => response.data)
    .then((data) => {
        setSearchResults(data);
    })
    .catch((error) => {
        console.log(error);
    });
  }

  useEffect(() => {
    setIsUserAuth(getAuthToken())
  }, [params.name]);

  const toggleDrawer = (anchor, open) => {
    setState(prevState => ({ ...prevState, [anchor]: open }));
  };

  const handleDrawerClose = useCallback(() => {
    toggleDrawer('left', false);
  }, []);

  const closeAndCollapse = () => {
    setOpenMain(false)
    toggleDrawer('left', false)
  }

  const changePage = useCallback(() => {
    closeAndCollapse();
  }, []);
  
  const onLogoutClick = useCallback(() => {
    logout();
    closeAndCollapse();
    window.location.reload();
  }, []);

  const accordionStyle = {
    background: 'transparent', 
    boxShadow: 'none',
    border: 'none',
    bgcolor: '#1A2532', 
    color: 'white',
    margin: 0
};

const summaryStyle = {
    padding: 0,
    paddingRight: '16px',
    marging: 0,
    minHeight: 0
};

const detailsStyle = {
    padding: 0,
};

  const renderCategoriesAccordion = () => (
    <Accordion 
      expanded={openMain === 'panel1'} 
      onChange={handleAccordionChange('panel1')}
      style={accordionStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: 'white' }}/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        style={summaryStyle}
        sx={{
          '.MuiAccordionSummary-content': {
              margin: 0,
          },
          '.Mui-expanded' : {
            margin: '0!important',
          }
      }}
      >
        <div className={classnames(styles.sideMenuTextListItem)}>
          {t("categories")}
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={detailsStyle}
      >
        <ExpandableList closeMenu={closeAndCollapse} />
      </AccordionDetails>
    </Accordion>
  );


  const list = () => (
    <Box
      sx={{ 
        width: 300,
        bgcolor: '#1A2532', 
        color: 'white'
       }}
      role="presentation"
    >
      <List>

          {renderCategoriesAccordion()}

          <ListItem key={t("news")} disablePadding>
              <Link 
                to={`/${i18n.language}/news/all`} 
                className={classnames(styles.linkToPageSideMenu)}
              >
                <div
                  className={classnames(styles.sideMenuTextListItem)}
                  onClick={() => changePage()}
                >
                  {t("news")}
                </div>
              </Link>
          </ListItem>

          <ListItem key={t("places")} disablePadding>
              <Link 
                to={`/${i18n.language}/places/all`} 
                className={classnames(styles.linkToPageSideMenu)}
              >
                <div
                  className={classnames(styles.sideMenuTextListItem)}
                  onClick={() => changePage()}
                >
                  {t("places")}
                </div>
              </Link>
          </ListItem>

          <ListItem key={t("eventList")} disablePadding>
              <Link 
                to={`/${i18n.language}/events/all`} 
                className={classnames(styles.linkToPageSideMenu)}
              >
                <div
                  className={classnames(styles.sideMenuTextListItem)}
                  onClick={() => changePage()}
                >
                  {t("eventList")}
                </div>
              </Link>
          </ListItem>

          <ListItem key={t("aboutUs")} disablePadding>
              <Link 
                to={`/${i18n.language}/about-us`} 
                className={classnames(styles.linkToPageSideMenu)}
              >
                <div
                  className={classnames(styles.sideMenuTextListItem)}
                  onClick={() => changePage()}
                >
                  {t("aboutUs")}
                </div>
                
              </Link>
          </ListItem>

      </List>
    </Box>
  );

  return (
    <>
      <Button sx={{ pl: 0, mr: 0, display: 'block' }} onClick={() => toggleDrawer('left', true)}>
          <span className={classnames(styles.hamburgerIcon)}></span>
      </Button>
          <Drawer 
            className="muiDrawerPaper"
            open={state['left']}
            PaperProps={{
              sx: {
                backgroundColor: "#1A2532",
                color: "white",
              }
            }}
            onClose={() => handleDrawerClose()}
          >
            <div className={classnames(styles.closeIcon)}  onClick={() => toggleDrawer('left', false)}>
              <img width="14px" height="14px" className={styles.logo} src={closeIconImage} alt="close" />
            </div>

            <Autocomplete
              blurOnSelect    
              disablePortal
              popupIcon={null}
              autoHighlight={true}
              clearIcon={<CloseIcon style={{ color: 'white', zIndex: '99999999'}} />}
              id="placeSearch"
              options={searchResults || []}
              noOptionsText={t("nothingExists")}
              className="autocompleteHeader"
              getOptionLabel={(option) => option.placeName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onInputChange={(event, newValue) => handleInput(newValue)}
              renderInput={(params) => 
                <TextField {...params} 
                  variant="outlined"
                  label={t("enterPlaceNamePlaceholder")}
                  sx={{ 
                    maxWidth: 'calc(100% - 32px)',
                    mx: '16px'
                  }}
                />}
              PaperComponent={({ children }) => (
                <Paper 
                  sx={{ 
                    '.MuiAutocomplete-noOptions': {
                      color: 'white'
                    },
                    '.MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: '#FE754B',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#FE754B',
                      },
                    },
                    backgroundColor: '#1A2532',
                    color: 'white', 
                    border: '2px solid #FE754B',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    borderTopRightRadius: '0',
                    borderTopLeftRadius: '0',
                    borderTop: 'none',
                    overflow: 'hidden',
                    '.MuiAutocomplete-listbox': {
                      paddingTop: '0px',
                      paddingBottom: '15px',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      scrollbarWidth: 'none', 
                      msOverflowStyle: 'none',  
                      '.MuiAutocomplete-option': {
                        '&:hover': {
                          backgroundColor: '#FE754B',
                        },
                      },
                    },
                  }} 
                  children={children} 
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  closeAndCollapse()
                  navigate(`/${i18n.language}/places/${newValue.uri}`);
                }
              }}
            />
            {list('left')}
            <Divider 
              sx={{ 
                borderBottomWidth: '2px',
                background: '#FE754B',
                opacity: '1'
              }}
              variant="middle"
            />
            
            <div className={classnames(styles.bottomContentWrapper)}>  
              {isUserAuth == null && 
              <div className={classnames(styles.sideMargins, 'mt-3')}>
                <Link
                  to={{
                      pathname: `/${i18n.language}/auth/login`
                  }} 
                  state={{ previousPath: pathname }}
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    textDecoration: 'none' 
                  }}
                >
                  <VioletButton
                    title={t("logIn")}
                    onClick={() => closeAndCollapse()}
                  /> 
                </Link>
              </div>
                
              }
              {isUserAuth != null && 
              <List>
                <ListItem key={t("myPage")} disablePadding>
                  <Link 
                    to={`/${i18n.language}/profile/#changeProfile`} 
                    className={classnames(styles.linkToPageSideMenu)}
                  >
                    <div
                      className={classnames(styles.sideMenuTextListItem)}
                      onClick={() => changePage()}
                    >
                      {t("myPage")}
                    </div>
                  </Link>
                </ListItem>
                <ListItem key={t("favPlaces")} disablePadding>
                  <Link 
                    to={`/${i18n.language}/profile/#favPlaces`} 
                    className={classnames(styles.linkToPageSideMenu)}
                  >
                    <div
                      className={classnames(styles.sideMenuTextListItem)}
                      onClick={() => changePage()}
                    >
                      {t("favPlaces")}
                    </div>
                  </Link>
                </ListItem>
                <ListItem key={t("favEvents")} disablePadding>
                  <Link 
                      to={`/${i18n.language}/profile/#favEvents`} 
                      className={classnames(styles.linkToPageSideMenu)}
                    >
                    <div
                      className={classnames(styles.sideMenuTextListItem)}
                      onClick={() => changePage()}
                    >
                      {t("favEvents")}
                    </div>
                  </Link>
                </ListItem>
                <ListItem key={t("logOut")} disablePadding>
                    <div
                      className={classnames(styles.sideMenuTextListItem)}
                      onClick={() => onLogoutClick()}
                    >
                      {t("logOut")}
                    </div>
                </ListItem>
              </List>
             }
          </div>
          </Drawer>   
    </>
  );
}