import { useTranslation } from 'react-i18next';


const CommonUtils = () => {

  const { t, i18n } = useTranslation();

  
  function delay(ms) {
      return function(x) {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
      };
    }

  function formatTimeRangeToEuropean(startHours, startMinutes, endHours, endMinutes) {
    const formatNumber = (number) => number.toString().padStart(2, '0');
    const formattedStart = `${formatNumber(startHours)}:${formatNumber(startMinutes)}`;
    const formattedEnd = `${formatNumber(endHours)}:${formatNumber(endMinutes)}`;
    return `${formattedStart} - ${formattedEnd}`;
  }
    

  function formatDateAndStatus(startDateStr, endDateStr) {

    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const currentDate = new Date();
    const options = {
        day: 'numeric', 
        month: 'short', 
        hour: '2-digit', 
        minute: '2-digit',
        hourCycle: 'h23'
    };
    const formattedDateStart = new Intl.DateTimeFormat(i18n.language, options).format(startDate).replace(/\./g, '');
    const formattedDateEnd = new Intl.DateTimeFormat(i18n.language, options).format(endDate).replace(/\./g, '');

    let status = '';
    if (startDate <= currentDate && endDate >= currentDate) {
        status = "(Live)";
    }

    return `${formattedDateStart} - ${formattedDateEnd} ${status}`;
  }

  function getFormmatedWorkingHours(workingHours) {
    workingHours = workingHours ? workingHours : [];
    const getHoursFormat = (hour, minute) => {
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };
  
    const currentTime = new Date();
    const currentDay = currentTime.toLocaleString('en', { weekday: 'long' }).toLocaleUpperCase();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
  
    const todayWorkingHours = workingHours.find(wh => wh.dayOfWeek === currentDay);
  
    if (!todayWorkingHours) {
      return t("todayClosed");
    }
  
    const { openingTime, closingTime } = todayWorkingHours;
    const isOvernight = closingTime[0] < openingTime[0] || 
                        (closingTime[0] === openingTime[0] && closingTime[1] < openingTime[1]);
    
    let isOpen;
    if (isOvernight) {
      isOpen = currentHour > openingTime[0] || 
               (currentHour === openingTime[0] && currentMinute >= openingTime[1]) || 
               currentHour < closingTime[0] || 
               (currentHour === closingTime[0] && currentMinute <= closingTime[1]);
    } else {
      isOpen = (currentHour > openingTime[0] || (currentHour === openingTime[0] && currentMinute >= openingTime[1])) &&
               (currentHour < closingTime[0] || (currentHour === closingTime[0] && currentMinute <= closingTime[1]));
    }
  
    return `${getHoursFormat(openingTime[0], openingTime[1])} - ${getHoursFormat(closingTime[0], closingTime[1])} (${isOpen ? t("open") : t("closed")})`;
  }

  return { formatDateAndStatus, formatTimeRangeToEuropean, delay, getFormmatedWorkingHours};

}

export { CommonUtils };