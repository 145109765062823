import React from 'react';
import classnames from 'classnames';
import styles from './PartnershipRequestPage.module.scss'
import { useTranslation } from 'react-i18next';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import phone from '../../assets/images/common/tg-robot-xmas.webp';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const ThankYouPage = () => {
    const { t, i18n } = useTranslation();

    const linksData = [
        { path: "/", label: "home" },
        { path: "/partnership", label: "partnership" }
    ];

    return (
      <div className={classnames(styles.mainContent)}>
        <div className={classnames(styles.infoSection)}>
          <div className={classnames('row', styles.row, styles.bannerContent)}>
            <div className={classnames(styles.breadcrumbs)}>
              <DynamicBreadcrumbs links={linksData} /> 
            </div>
            <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-6', 'col-xl-6', 'col-xxl-6', 'col-12', 'align-self-center')}>
              <div className={classnames('row', styles.row, 'd-flex', 'justify-content-left', styles.titleHeader)}>
                <div className={classnames('col-12')}>
                  <h2>{t("partnership").toUpperCase()}</h2>
                  <i className={classnames(styles.dividingLine)}></i>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <div className={classnames(styles.stepContent)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={5} display="flex" justifyContent="center">
                        <img loading="lazy" className={styles.robotImg} src={phone} alt="phone" />
                    </Grid>

                    <Grid item xs={12} md={6} lg={7} alignSelf="center" className={styles.thankYouText}>
                        <h2>{t("thankYouParthership").toLocaleUpperCase()}</h2>
                        <br/>
                        <p>{t("weWillContactYou")}</p>
                    </Grid>
                </Grid>

                <div className={classnames('row', styles.row, styles.navigationButtons, 'justify-content-center')}>

                    <Link
                        className={classnames(styles.homepageButton)}
                        to={{ pathname: `/${i18n.language}` }}
                        style={{ display: 'inline-block', textDecoration: 'none' }}
                        state={{ breadcrumbs: linksData }}
                    >
                        {t("homepage")}
                    </Link>
                    
                </div>
              </div>
          </div>
        </div>
    </div>
      );
    };

export default React.memo(ThankYouPage);