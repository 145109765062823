import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CopyToClipboardButton({ value, className="", onCopyClick = () => {} }) {
    const handleCopyClick = () => {
        navigator.clipboard.writeText(value);
        onCopyClick()
    };

  return (
    <div className={className}>
        <ContentCopyIcon 
          sx={{color: '#FE754B'}}
          onClick={handleCopyClick}
        />
    </div>
  );
}

export default CopyToClipboardButton;
