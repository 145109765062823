import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const doesNotMatchCategoryPlaces = !/^\/places\/sub-category\/(\d+)$/.test(pathname);
    const doesNotMatchAllPlaces = !/^\/places\/all$/.test(pathname);
    if (doesNotMatchCategoryPlaces && doesNotMatchAllPlaces) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  }, [pathname]);

  return null;
}