import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import Unauthorized from '../../unauthorized/Unauthorized';
import { postCsrf } from '../../../services/fetch/AxiosHelper';
import { Link } from 'react-router-dom';
import notFavouriteIcon from '../../../assets/images/common/favouriteOrange.svg'
import favouriteIcon from '../../../assets/images/common/heart-fill-orange.svg'
import notFavouriteIconWgtChoice from '../../../assets/images/common/favourite.svg'
import favouriteIconWgtChoice from '../../../assets/images/common/heart-fill.svg'
import geoIcon from '../../../assets/images/common/geo-card.svg'
import ShareModal from '../../modals/shareModal/ShareModal';
import { useTranslation } from 'react-i18next';
import styles from './CustomCard.module.scss'
import classnames from 'classnames';
import clock from '../../../assets/images/common/clockWhite.svg'
import Zoom from '@mui/material/Zoom';
import { ClickAwayListener } from '@mui/material';
import { getAuthToken } from '../../../services/cookie/CookieManager';
import { useDispatch } from 'react-redux';
import { updateFavourites } from '../../../redux/actions';

function CustomCard({ title, logo, isFavourite, uri, date, heartClicked = () => {}, onNavigate = () => {}, address, isWgtChoice = false, isFranchise = false}) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [showUnauthorized, setShowUnauthorized] = useState(false);
    
    const [isFav, setIsFav] = useState(isFavourite)

    const handleShowShareModal = () => setShowShareModal(true); 
    const handleCloseShareModal = () => setShowShareModal(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const handleCloseUnauthorized = () => setShowUnauthorized(false);
    const [showTooltip, setShowTooltip] = useState(false)
    const [touchStart, setTouchStart] = useState(null);
    const [mouseMoved, setMouseMoved] = useState(false);

    const handleClick = (event) => {
        if (!mouseMoved) {
            console.log(event)
        } else {
            event.preventDefault()
        }
    };
    
    const handleScroll = () => {
        if (showTooltip) {
          setShowTooltip(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
          window.removeEventListener('scroll', handleScroll, true);
        };
      }, [showTooltip]);

    const handleClickOnFavouriteIcon = () => {
        if (getAuthToken() == null) {
            setShowUnauthorized(true)
        } else {
            postCsrf(`${uri}/toggleFavourite`)
            .then((response) => response.data)
            .then((data) => {
                setIsFav(data)
                dispatch(updateFavourites());
                heartClicked()
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }

    const onTouchStart = (e) => {
        setShowTooltip(false);
    }

    useEffect(() => {
        window.addEventListener('touchstart', onTouchStart);
        return () => {
          window.removeEventListener('touchstart', onTouchStart);
        };
      }, [touchStart]);
      useEffect(() => {
        window.addEventListener('touchstart', onTouchStart);
        return () => {
        window.removeEventListener('touchstart', onTouchStart);

        };
    }, [touchStart]);

    return (
        <div>
            {showUnauthorized && <Unauthorized show={showUnauthorized} handleClose={handleCloseUnauthorized}/>}
            {showShareModal && <ShareModal show={showShareModal} onHide={handleCloseShareModal} link={"https://wegotonight.com" + uri}/>}
            <Card className={classnames(styles.customCard, isWgtChoice ? styles.cardWgtChoice : styles.cardDefault)}>
                <Link 
                    onMouseMove={() => setMouseMoved(true)}
                    onMouseDown={() => setMouseMoved(false)}
                    onMouseUp={(event) => handleClick(event)}
                    to={"/" + i18n.language + uri} 
                    style={{ textDecoration: 'none' }}
                    >
                    <CardMedia
                        loading="lazy" 
                        component="img"
                        alt="Logo"
                        height="161"
                        image={logo}
                        onClick={() => onNavigate()}
                    />
                </Link>
                <div className={classnames(styles.mainSlideCardContainer)}>
                    <CardContent>
                        <div className={classnames(styles.sliderCardTitle)}>
                            {title}
                        </div>
                        { (date != null && date.length > 0) &&
                            <div className={classnames(styles.workingTimeContainer)}>
                                <img width="14px" height="14px" src={clock} alt="clock image"/>  <div className={classnames(styles.workingHours)}>{date}</div>
                            </div>
                        }
                        { (address != null && address) &&
                            <ClickAwayListener onClickAway={() => setShowTooltip(false) }>                        
                                <Tooltip
                                    arrow
                                    TransitionComponent={Zoom}
                                    placement="bottom-start"
                                    title={address}
                                    open={showTooltip}
                                    onOpen={() => setShowTooltip(true)}
                                    onClose={() => setShowTooltip(false)}
                                    disableTouchListener
                                    disableFocusListener
                                > 
                                { address && address != null &&
                                    <div className={classnames(styles.sliderCardTags)} onClick={() => setShowTooltip(!showTooltip)}>
                                        <div className={classnames(styles.addressContainer)}>
                                            <img width="13px" height="13px" src={geoIcon} alt="geo icon"/> <div className={classnames(styles.addressValue)}>{address}</div>
                                        </div>
                                    </div>
                                }
                                </Tooltip> 
                            </ClickAwayListener>
                        }
                    </CardContent>

                </div>
                <div className={classnames(styles.buttonsContainer)}>
                        <Link 
                            className={classnames(styles.sliderCardButtonRead, isWgtChoice ? styles.sliderCardButtonReadWgtChoice : styles.sliderCardButtonReadDeafult)}
                            to={"/" + i18n.language + uri} 
                            style={{display: 'inline-block', textDecoration: 'none' }}
                            onClick={() => onNavigate()}
                        >
                            {t("readMore")}
                        </Link>
                        <div>
                            { !isFranchise && 
                                <Tooltip title={isFav ? t("removeFromFavorites") : t("addToFavorites")}>
                                    <IconButton onClick={() => { handleClickOnFavouriteIcon() }}>
                                    {isFav ? 
                                            <img width="24px" height="24px" src={isWgtChoice ? favouriteIconWgtChoice : favouriteIcon} alt="Favourite" /> : 
                                            <img width="24px" height="24px" src={isWgtChoice ? notFavouriteIconWgtChoice : notFavouriteIcon} alt="Not Favourite" />
                                        }
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title={t("share")}>
                                <IconButton onClick={() => { handleShowShareModal() }}>
                                    <ShareIcon style={{ color: isWgtChoice ? 'white' : '#FE754B' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                </div>
                    
            </Card>
            
        </div>
    );
}

export default React.memo(CustomCard);
