import arrowRight from '../../assets/images/common/chevron-right.svg';
import styles from './CategoryRow.module.scss'
import classnames from 'classnames';

const CategoryRow = ({categoryImage, categoryName, showArrow, onHower, onClick}) => {

const hower = () => {
    if (onHower != null) {
        onHower()
    }
}

const click = () => {
    if (onClick != null) {
        onClick()
    }
}

return (
    <div 
        onMouseOver={hower}
        onClick={click}
        className={classnames(styles.categoryItem)}
    >
        <div className={classnames(styles.imageAndName)}>
            <img width="24px" height="24px" src={categoryImage} alt="category" />
            <div className={classnames(styles.categoryName)}>{categoryName}</div>
        </div>
        { showArrow && <img src={arrowRight} alt="more categories" /> }                  
    </div>
  );
};


export default CategoryRow;