import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid, TextField } from '@mui/material';
import { StyledTimePicker, StyledTextField, StyledTextHeading } from '../CustomStyles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Reminder from '../../../components/mui/reminder/Reminder';



const BreakfastHoursForm = ({ breakfastHours, setBreakfastHours, formErrors, setFormErrors }) => {
  const { t, i18n } = useTranslation();

  const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

  const handleTimeChange = (day, type, time) => {
    setBreakfastHours(prevState => ({
        ...prevState,
        [day]: { ...prevState[day], [type]: time }
    }));
    if (formErrors["breakfastHours"]) {
      setFormErrors({ ...formErrors, ["breakfastHours"]: '' });
    }
  };

  const renderDayFields = () => {
    return daysOfWeek.map(day => (
      <Grid container spacing={2} key={day}>
        <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
          <StyledTextHeading style={{ paddingBottom: '0' }}>{day}</StyledTextHeading>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledTimePicker
              label={t("startTime")}
              ampm={false}
              value={breakfastHours[day]?.openingTime || null}
              onChange={(value) => handleTimeChange(day, 'openingTime', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormHelperText
            sx={{
                color: '#FFC0CB !important',
                marginLeft: '16px'
            }}
            >{formErrors.breakfastHours ? formErrors.breakfastHours : "\u00A0"}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledTimePicker
              label={t("endTime")}
              ampm={false}
              value={breakfastHours[day]?.closingTime || null}
              onChange={(value) => handleTimeChange(day, 'closingTime', value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FormHelperText
              sx={{
                  color: '#FFC0CB !important',
                  marginLeft: '16px'
              }}
              >{formErrors.breakfastHours ? formErrors.breakfastHours : "\u00A0"}
            </FormHelperText>
        </Grid>
      </Grid>
    ));
  };

  return (
    <div>
      <Grid 
          container 
          spacing={2}
          sx={{
              marginTop: '16px',
              marginLeft: 0,
              justifyContent: 'center'
          }}
          >
        
        <Reminder
          content={t("breakfastsFormReminder")}
        />

        <Grid item xs={12}>
          <StyledTextHeading>{t("breakfasts")}</StyledTextHeading>
        </Grid>

        <div>
          {renderDayFields()}
        </div>
      </Grid>
    </div>
  );
};

export default React.memo(BreakfastHoursForm);