import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Privacy.module.scss'
import classnames from 'classnames';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Privacy = ({ open, onClose }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={classnames(styles.privacy)}>
            <Dialog 
                open={open} 
                onClose={onClose}
                fullWidth 
                maxWidth="lg" 
                sx={{ maxWidth: '80vw', margin: 'auto', '@media (max-width: 600px)': { maxWidth: '95vw' } }}
                >
                <DialogTitle>
                    <div className={classnames(styles.privacyTitle)}>{t('privacy')}</div>
                    <IconButton 
                        aria-label="close" 
                        sx={{ position: 'absolute', right: 1, top: 1 }} 
                        onClick={onClose}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classnames(styles.nonListContentPrivacy)}>
                        {t("privacyPurpose")}
                    </div>
                    
                    <div className={classnames(styles.privacyList)}>
                        <ol>
                            <li key={"1"}>{t('dataController')}
                                <ol>
                                    <li key={"1.1"}>{t('dataController11')}<a href={`https://wegotonight.com/${i18n.language}`} target="_self">{t("dataControllerWebsite")}</a>;</li>
                                    <li key={"1.2"}>{t('dataController12')}<a href="mailto:info@wegotonight.com">info@wegotonight.com</a>.</li>
                                </ol>
                            </li>
                            <li key="2">{t('lawsAndRegulations')}
                                <ol>
                                    <li key={"2.1"}>{t('lawsAndRegulations21')}</li>
                                    <li key={"2.2"}>{t('lawsAndRegulations22')}</li>
                                </ol>
                            </li>

                            <li key={"3"}>{t('personalDataProcessing')}
                                <ol>
                                    <li key={"3.1"}>{t("personalDataProcessing31")}
                                        <ol>
                                            <li key={"3.1.1"}>{t("personalDataProcessing311")}</li>
                                            <li key={"3.1.2"}>{t("personalDataProcessing312")}</li>
                                            <li key={"3.1.3"}>{t("personalDataProcessing313")}</li>
                                            <li key={"3.1.4"}>{t("personalDataProcessing314")}</li>
                                            <li key={"3.1.5"}>{t("personalDataProcessing315")}</li>
                                            <li key={"3.1.6"}>{t("personalDataProcessing316")}</li>
                                            <li key={"3.1.7"}>{t("personalDataProcessing317")}</li>
                                            <li key={"3.1.8"}>{t("personalDataProcessing318")}</li>
                                            <li key={"3.1.9"}>{t("personalDataProcessing319")}</li>
                                            <li key={"3.1.10"}>{t("personalDataProcessing3110")}</li>
                                            <li key={"3.1.11"}>{t("personalDataProcessing3111")}</li>
                                        </ol>
                                    </li>
                                    <li key={"3.2"}>{t("personalDataProcessing32")}
                                        <ol>
                                            <li key={"3.2.1"}>{t("personalDataProcessing321")}</li>
                                            <li key={"3.2.2"}>{t("personalDataProcessing322")}</li>
                                            <li key={"3.2.3"}>{t("personalDataProcessing323")}</li>
                                            <li key={"3.2.4"}>{t("personalDataProcessing324")}</li>
                                            <li key={"3.2.5"}>{t("personalDataProcessing325")}</li>
                                            <li key={"3.2.6"}>{t("personalDataProcessing326")}</li>
                                            <li key={"3.2.7"}>{t("personalDataProcessing327")}</li>
                                            <li key={"3.2.8"}>{t("personalDataProcessing328")}</li>
                                        </ol>
                                    </li>
                                    <li key={"3.3"}>{t("personalDataProcessing33")}</li>
                                    <li key={"3.4"}>{t("personalDataProcessing34")}</li>
                                    <li key={"3.5"}>{t("personalDataProcessing35")}</li>
                                    <li key={"3.6"}>{t("personalDataProcessing36")}</li>
                                    <li key={"3.7"}>{t("personalDataProcessing37")}</li>
                                    <li key={"3.8"}>{t("personalDataProcessing38")}</li>
                                    <li key={"3.9"}>{t("personalDataProcessing39")}</li>
                                    <li key={"3.10"}>{t("personalDataProcessing3_10")}</li>
                                    <li key={"3.11"}>{t("personalDataProcessing3_11")}</li>
                                    <li key={"3.12"}>{t("personalDataProcessing3_12")}</li>
                                </ol>
                            </li>
                            <li key={"4"}>{t('categoriesPersonalData')}
                                <ol>
                                    <li key={"4.1"}>{t('categoriesPersonalData41')}</li>
                                    <li key={"4.2"}>{t('categoriesPersonalData42')}</li>
                                    <li key={"4.3"}>{t('categoriesPersonalData43')}</li>
                                    <li key={"4.4"}>{t('categoriesPersonalData44')}</li>
                                    <li key={"4.5"}>{t('categoriesPersonalData45')}</li>
                                    <li key={"4.6"}>{t('categoriesPersonalData46')}</li>
                                    <li key={"4.7"}>{t('categoriesPersonalData47')}</li>
                                    <li key={"4.8"}>{t('categoriesPersonalData48')}</li>
                                    <li key={"4.9"}>{t('categoriesPersonalData49')}</li>
                                    <li key={"4.10"}>{t('categoriesPersonalData410')}</li>
                                    <li key={"4.11"}>{t('categoriesPersonalData411')}</li>
                                    <li key={"4.12"}>{t('categoriesPersonalData412')}</li>
                                    <li key={"4.13"}>{t('categoriesPersonalData413')}</li>
                                    <li key={"4.14"}>{t('categoriesPersonalData414')}</li>
                                    <li key={"4.15"}>{t('categoriesPersonalData415')}</li>
                                </ol>
                            </li>
                            <li key={"5"}>{t('justificationPersonalData')}
                                <ol>
                                    <li key={"5.1"}>{t('justificationPersonalData51')}</li>
                                    <li key={"5.2"}>{t('justificationPersonalData52')}</li>
                                    <li key={"5.3"}>{t('justificationPersonalData53')}</li>
                                    <li key={"5.4"}>{t('justificationPersonalData54')}</li>
                                    <li key={"5.5"}>{t('justificationPersonalData55')}</li>
                                </ol>
                            </li>
                            <li key={"6"}>{t('sourcesOfacquisition')}
                                <ol>
                                    <li key={"6.1"}>{t('sourcesOfacquisition61')}</li>
                                    <li key={"6.2"}>{t('sourcesOfacquisition62')}</li>
                                    <li key={"6.3"}>{t('sourcesOfacquisition63')}</li>
                                    <li key={"6.4"}>{t('sourcesOfacquisition64')}</li>
                                    <li key={"6.5"}>{t('sourcesOfacquisition65')}</li>
                                    <li key={"6.6"}>{t('sourcesOfacquisition66')}<a href={`https://wegotonight.com/${i18n.language}`} target="_self">{t("dataControllerWebsite")}</a>.</li>
                                </ol>
                            </li>
                            <li key={"7"}>{t('dataProcessingProcessSubject')}
                                <ol>
                                    <li key={"7.1"}>{t('dataProcessingProcessSubject71')}</li>
                                    <li key={"7.2"}>{t('dataProcessingProcessSubject72')}</li>
                                    <li key={"7.3"}>{t('dataProcessingProcessSubject73')}</li>
                                    <li key={"7.4"}>{t('dataProcessingProcessSubject74')}</li>
                                    <li key={"7.5"}>{t('dataProcessingProcessSubject75')}</li>
                                    <li key={"7.6"}>{t('dataProcessingProcessSubject76')}</li>
                                    <li key={"7.7"}>{t('dataProcessingProcessSubject77')}</li>
                                </ol>
                            </li>
                            <li key={"8"}>{t('cookieProcessingDataSubject')}
                                <ol>
                                    <li key={"8.1"}>{t('cookieProcessingDataSubject81')}</li>
                                    <li key={"8.2"}>{t('cookieProcessingDataSubject82')}</li>
                                    <li key={"8.3"}>{t('cookieProcessingDataSubject83')}</li>
                                </ol>
                            </li>
                            <li key={"9"}>{t('periodStorageData')}
                                <ol>
                                    <li key={"9.1"}>{t('periodStorageData91')}</li>
                                    <li key={"9.2"}>{t('periodStorageData92')}</li>
                                    <li key={"9.3"}>{t('periodStorageData93')}</li>
                                    <li key={"9.4"}>{t('periodStorageData94')}</li>
                                    <li key={"9.5"}>{t('periodStorageData95')}</li>
                                    <li key={"9.6"}>{t('periodStorageData96')}</li>
                                </ol>
                            </li>
                            <li key={"10"}>{t('sharingAndIssuing')}
                                <ol>
                                    <li key={"10.1"}>{t('sharingAndIssuing101')}</li>
                                    <li key={"10.2"}>{t('sharingAndIssuing102')}</li>
                                    <li key={"10.3"}>{t('sharingAndIssuing103')}</li>
                                    <li key={"10.4"}>{t('sharingAndIssuing104')}</li>
                                </ol>
                            </li> 
                            <li key={"11"}>{t('protectionDataSubject')}
                                <ol>
                                    <li key={"11.1"}>{t('protectionDataSubject111')}</li>
                                    <li key={"11.2"}>{t('protectionDataSubject112')}</li>
                                    <li key={"11.3"}>{t('protectionDataSubject113')}</li>
                                    <li key={"11.4"}>{t('protectionDataSubject114')}</li>
                                </ol>
                            </li> 
                            <li key={"12"}>{t('profilingLogic')}
                                <ol>
                                    <li key={"12.1"}>{t('profilingLogic121')}</li>
                                </ol>
                            </li> 
                            <li key={"13"}>{t('rightsDataSubject')}
                                <div className={classnames(styles.nonListContentPrivacy)}>{t("rightsDataSubjectParagraph")}</div>
                                <ol>
                                    <li key={"13.1"}>{t('rightsDataSubject131')}</li>
                                    <li key={"13.2"}>{t('rightsDataSubject132')}</li>
                                    <li key={"13.3"}>{t('rightsDataSubject133')}</li>
                                    <li key={"13.4"}>{t('rightsDataSubject134')}</li>
                                    <li key={"13.5"}>{t('rightsDataSubject135')}</li>
                                    <li key={"13.6"}>{t('rightsDataSubject136')}</li>
                                    <li key={"13.7"}>{t('rightsDataSubject137')}<a href="mailto:info@wegotonight.com">info@wegotonight.com</a>. {t('rightsDataSubject137_1')}</li>
                                </ol>
                                <br/>
                                <div className={classnames(styles.nonListContentPrivacy)}>{t("rightsDataSubjectEnd")}</div>
                            </li>
                            <li key={"14"}>{t('otherPrivacy')}
                                <ol>
                                    <li key={"14.1"}>{t('otherPrivacy141')}</li>
                                </ol>
                            </li> 
                            <li key={"15"}>{t('communicationPrivacy')}
                                <ol>
                                    <li key={"15.1"}>{t('communicationPrivacy151')}<a href="mailto:info@wegotonight.com">info@wegotonight.com</a>.</li>
                                    <li key={"15.2"}>{t('communicationPrivacy152')}</li>
                                </ol>
                            </li>
                            <br/>
                            <div className={classnames(styles.nonListContentPrivacy)}>{t("notSatisfiedPrivacy")}<a href={`https://www.dvi.gov.lv/lv/`} target="_blank">https://www.dvi.gov.lv/lv/</a>{")"}.</div>
                            <br/>
                            <div className={classnames(styles.nonListContentPrivacy)}>{t("privacyEnd")}<a href={`https://wegotonight.com/${i18n.language}`} target="_self">{t("dataControllerWebsite")}</a>.</div>
                        </ol>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Privacy;