import React from 'react';
import styled from 'styled-components';
import { Grid, TextField, Typography, Select, MenuItem, Checkbox, Button } from '@mui/material';
import { StepLabel, StepConnector, StepIcon } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';

export const StyledTextField = React.memo(styled(TextField)({
    '&&': {
        width: '100%',

        '& .MuiOutlinedInput-root': {
            transition: 'none',
            borderRadius: '40px',
            color: 'black',
            backgroundColor: 'white',
            width: '100%',
            border: '2px #4137AF solid !important',
        },
       ' & .MuiOutlinedInput-input': {
        transition: 'none',
            padding: '16px 28px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent'
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white!important'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
           borderColor: 'white!important'
        },
        '& .Mui-focused.MuiInputLabel-root': {
            color: '#FE754B !important',
        },
        '& .MuiInputAdornment-root img': {
            paddingLeft: '8px'
        },
        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& .MuiFormHelperText-root': {
        color: '#FFC0CB',
      }
    }  
}))

export const StyledTimePicker = React.memo(styled(TimePicker)({
  '&&': {
      width: '100%',

      '& .MuiOutlinedInput-root': {
          transition: 'none',
          borderRadius: '40px',
          color: 'black',
          backgroundColor: 'white',
          width: '100%',
          border: '2px #4137AF solid !important',
      },
     ' & .MuiOutlinedInput-input': {
          transition: 'none',
          padding: '14px 20px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
          backgroundColor: 'transparent'
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white!important'
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
         borderColor: 'white!important'
      },
      '& .Mui-focused.MuiInputLabel-root': {
          color: '#transparent !important',
      },
      '& .MuiInputAdornment-root img': {
          paddingLeft: '8px'
      },

      '& .MuiInputLabel-root': {
          color: '#CBCBCB',
          paddingLeft: '8px'
      },

      '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        color: 'transparent' // Change to your desired shrink state color
      }
  }  
}))

export const StyledSelect = React.memo(styled(Select)(({ theme }) => ({
  width: '100%',
  height: '40px',
  color: 'white!important',
  border: '1px solid #FE754B',
  borderRadius: '8px',
  '.MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
    backgroundColor: 'transparent'
  },
  '.MuiPaper-root': {
    backgroundColor: '#1A2532'
  },
  '.MuiOutlinedInput-notchedOutline': {
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
  },

  '.MuiSelect-icon': {
    marginRight: '5px'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px'
  }
})))

export const StyledMenuItem = React.memo(styled(MenuItem)({
    color: 'white!important',
    '&:hover svg': {
        color: 'white'
    },

  '&.Mui-selected': {
    color: 'white!important',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'inherit'
    }
  },

  '&.Mui-selected.Mui-focusVisible': {
    backgroundColor: 'transparent'
  }
}))

export const StyledGrid = React.memo(styled(Grid)({
        
}))

export const StyledCheckBox = React.memo(styled(Checkbox)({
  color: "#FE754B",
  marginRight: '10px',
  marginTop: '5px',
  marginBottom: '5px',
  "& .MuiSvgIcon-root": {
      fontSize: 20,
      color: "#FE754B",
  },
  "&.MuiCheckbox-root": {
      borderRadius: 0,
      padding: 0,
  },
  "& svg": {
      scale: "1.4",
  },
  '&.Mui-checked': {
      color: '#FE754B',
      backgroundColor: "white",
      "& .MuiSvgIcon-root": {
          fontSize: 20,
          color: "FE754B",
      },
      "&.MuiCheckbox-root": {
          borderRadius: 0,
          padding: 0,
      },
      "& svg": {
          scale: "1.4",
      },
  }                             
}))

export const StyledTextHeading = React.memo(styled(Typography)({
    color: "white",
    paddingBottom: '20px',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 'bold!important',
    letterSpacing: '-0.24px'    
}))

export const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-label': {
    color: 'white',
    fill: 'white',
    fontFamily: 'Arial',
    fontWeight: '600',

    '&.Mui-completed': {
      color: 'white',
      fontFamily: 'Arial',
      fontWeight: '600',
    },
    '&.Mui-active': {
      color: 'white',
      fontFamily: 'Arial',
      fontWeight: '600',
    },
  },
});

export const StyledStepConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    borderColor: '#FE754B',
  },
});

export const StyledStepIcon = styled(StepIcon)({
  '&.MuiStepIcon-root': {
    color: '#4137AF',
    height: '33px',
    width: '33px',

    border: '3px solid #4137AF',
    borderRadius: '50%',
    backgroundColor: '#4137AF',
    
    '& .MuiStepIcon-text': {
      fill: 'white',
      fontFamily: 'Arial',
      fontWeight: '700',
      fontSize: '1rem',
    },

    '&.Mui-completed': {
      color: '#4137AF',
      border: '3px solid white',
      borderRadius: '50%',
      backgroundColor: 'white',
      
      '& .MuiStepIcon-text': {
        fill: 'white',
        fontFamily: 'Arial',
        fontWeight: '700',
        fontSize: '1rem',

      },
    },
    '&.Mui-active': {
      color: '#FE754B',
      border: '3px solid #FE754B',
      borderRadius: '50%',
      backgroundColor: '#FE754B',

      '& .MuiStepIcon-text': {
        fill: 'white',
        fontFamily: 'Arial',
        fontWeight: '700',
        fontSize: '1rem',
      }
    },
  },
});