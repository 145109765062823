import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from './translations/translationsEn'
import { ru } from './translations/translationsRu'
import { lv } from './translations/translationsLv'

const pathSegments = window.location.pathname.split('/').filter(Boolean);

const getBrowserLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage
  return browserLang.slice(0, 2)
}

const getSavedLanguage = () => {
    const languages = ['en', 'lv', 'ru'];
    const language = pathSegments[0];
    return (!languages.includes(language)) ? getBrowserLanguage() : language;
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en, ru, lv
    },
    fallbackLng: getBrowserLanguage(),
    lng: getSavedLanguage(),
    interpolation: {
      escapeValue: true,
    }
  });

export default i18n;