import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styles from './DynamicBreadcrumbs.module.scss'
import { useTranslation } from 'react-i18next';

function DynamicBreadcrumbs({ links }) {
    const { t, i18n } = useTranslation();

    return (
        <Breadcrumbs
            aria-label="breadcrumb" 
            sx={{
                marginTop: '20px',
                backgroundColor: 'transparent',
                '& .MuiBreadcrumbs-separator': {
                    color: '#FE754B',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                }
            }}
        >
            {links.map((link, index) => {
                if (index === links.length - 1) {
                    return (
                        <div 
                            key={index}
                            className={styles.currentDestination}
                        >
                            {t(link.label)}
                        </div>
                    );
                } else {
                    return (
                        <Link
                            key={index}
                            to={"/" + i18n.language + link.path} 
                            underline="none" 
                            className={styles.link}
                        >
                            {t(link.label)}
                        </Link>
                    );
                }
            })}
        </Breadcrumbs>
    );
}

export default DynamicBreadcrumbs;