import { useTranslation } from 'react-i18next';

const TitlesData = () => {
const { t, i18n } = useTranslation();

const Titles = {
    home: `WGT | ${t("homePage")}`,
    about: `WGT | ${t("aboutUs")}`,
    profile: `WGT | ${t("myPage")}`,
    wgtPlaces: `${t("wgtChoicePlaces")}`,
    wgtEvents: `${t("wgtChoiceEvents")}`,
    placesBy: `WGT | ${t("placesByCategory")}`,
    wgt: `WGT | `,
    eventList: `WGT | ${t("eventList")}`,
    login: `WGT | ${t("logIn")}`,
    forgot: `WGT | ${t("forgotPassword")}`,
    registration: `WGT | ${t("registration")}`,
    forgotPassword: `WGT | ${t("forgotPassword")}`,
    recoveryPassword: `WGT | ${t("recoveryPassword")}`,
    partnership: `WGT | ${t("partnership")}`,
  };
  
  return {Titles}
}

export default TitlesData;