import styles from './RegistrationPage.module.scss'
import './RegistrationPage.scss'
import React, { useState } from 'react';
import './RegistrationPage.module.scss';
import backarrow from '../../../assets/images/common/arrow-left.svg';
import logo from '../../../assets/images/common/logo-orange-pin.svg';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { postCsrf } from '../../../services/fetch/AxiosHelper';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TitlesData from '../../../data/TitlesData';
import Privacy from '../../../components/privacy/Privacy';
import VioletButton from '../../../components/mui/violetButton/VioletButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormHelperText, InputAdornment, TextField } from '@mui/material';
import eyeIcon from '../../../assets/images/authorization/eye.svg';
import eyeIconGray from '../../../assets/images/authorization/eye-gray.svg';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import DateOfBirthPicker from '../../../components/mui/date/DateOfBirthPicker';

const RegistrationPage = () => {
    const { Titles } = TitlesData()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);

    const [formState, setFormState] = useState({
        login: '',
        password: '',
        firstName: '',
        lastName: '',
        confirmPassword: '',
        gender: 'male',
        dateOfBirth: { day: '', month: '', year: '' }
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        login: "",
        password: "",
        confirmPassword: "",
        dateOfBirth: ""
    });

    const handleDateChange = (dateOfBirth) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            dateOfBirth: ""
        }));

        setFormState(prevState => ({
            ...prevState,
            dateOfBirth
        }));
    };

    const handleChange = (event) => {

        const { name, value, checked, type } = event.target;
        
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ""
        }));

        setFormState((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setFormState(prevState => ({
                ...prevState,
                gender: newAlignment
            }));
        }
    };

    const isEmailFree = () => {
        if (emailPattern.test(formState.login)) {
            postCsrf('/v1/isEmailFree', JSON.stringify({ email: formState.login }))
                .then((response) => response.data)
                .then((data) => {
                    if (data === false) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            login: t("emailAlreadyExists")
                        }));
                    }
                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                login: t("emailInvalid")
            }));
        }
    }

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = formState.firstName ? "" : t("firstNameRequired");
        tempErrors.lastName = formState.lastName ? "" : t("lastNameRequired");
        tempErrors.login = emailPattern.test(formState.login) ? "" : t("emailInvalid");
        tempErrors.password = passwordPattern.test(formState.password) ? "" : t("wrongPasswordPattern");
        tempErrors.confirmPassword = formState.password === formState.confirmPassword ? "" : t("passwordsNotMatch");
        tempErrors.dateOfBirth = formState.dateOfBirth.day && formState.dateOfBirth.month && formState.dateOfBirth.year ? "" : t("allDateFieldsRequired");
        setErrors(tempErrors);
        return Object.values(tempErrors).every(value => value === "");
    };
    

    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

    const handlePrivacyPolicyClose = () => {
        setPrivacyPolicyOpen(false);
    };
    
    const handleShowPrivacyPolicy = () => {
        setPrivacyPolicyOpen(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validate()) return;
        postCsrf('/v1/registration', JSON.stringify(formState))
            .then((response) => response.data)
            .then((data) => {
                setFormState({
                    login: '',
                    password: '',
                    firstName: '',
                    lastName: ''
                })
                setSuccessDialogOpen(true); 
                
            })
            .catch((error) => {
                if (error.response?.status === 409) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        login: t("emailAlreadyExists")
                    }));
                }
                console.error('Error submitting form:', error);
            });
    };

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
        navigate(`/${i18n.language}/auth/login`)
    }

    const onBackPressed = () => {
        navigate(-1)
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevState => !prevState);
    };
    
    return (
        <div className={classnames(styles.registrationPageContainer, styles.backgroundTint)}>
            <Helmet htmlAttributes={{ lang: i18n.language }}>
                <title>{Titles.registration}</title>
            </Helmet>
            <div className={classnames(styles.header, 'col-12')}>

                <div className={classnames(styles.backContainer, classnames('col-xl-3', 'col-lg-3', 'col-md-3', 'col-sm-2', 'col-2'))} onClick={onBackPressed}>
                    <img src={backarrow} alt="iconBack" />
                    <div className={classnames(styles.backText, 'd-none', 'd-lg-block')}>{t("back")}</div>
                </div>

                <div className={classnames(styles.logoContainer, 'col-xl-6', 'col-lg-6', 'col-md-6', 'col-sm-8', 'col-9')}>
                    <Link
                        to={{ pathname: `/${i18n.language}` }} 
                        style={{
                            display: 'inline-block',
                            width: 'auto',
                            textDecoration: 'none' 
                        }}
                    >
                        <img className={styles.logo} src={logo} alt="logo" />    
                    </Link>
                </div>

            </div>

            <div className={classnames(styles.registrationFormContaner, 'col-12')}>
                <form onSubmit={handleSubmit} className={classnames('col-xl-4', 'col-lg-5','col-md-6', 'col-sm-9', 'col-11')}>
                    <div className={classnames(styles.nameSurname, 'col-12')}>
                        
                        <div className={classnames(styles.name, 'col-6')}>
                            <TextField
                                className={'registrationFirstName'}
                                placeholder={t("name")}
                                name="firstName"
                                onChange={handleChange} 
                                value={formState.firstName}
                                type='name'
                                helperText={errors.firstName || "\u00A0"}
                                error={!!errors.firstName}
                                FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                                sx={{
                                    '&': {
                                        width: '100%',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '40px', 
                                        color: 'black',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        border: '2px #4137AF solid !important',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '16px 28px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white !important'
                                    },
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white !important',
                                    },
                                    '& .Mui-focused.MuiInputLabel-root': {
                                        color: '#FE754B !important',
                                    },
                                    '& .MuiInputAdornment-root img': {
                                        paddingLeft: '8px'
                                    }
                                }}
                            />
                        </div>
                        
                        <div className={classnames(styles.surname, 'col-6')}>
                            <TextField
                                    className={'registrationLastName'}
                                    placeholder={t("surname")}
                                    name="lastName"
                                    onChange={handleChange} 
                                    value={formState.lastName}
                                    type='lastName'
                                    helperText={errors.lastName || "\u00A0"}
                                    error={!!errors.lastName}
                                    FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                                    sx={{
                                        '&': {
                                            width: '100%',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '40px', 
                                            color: 'black',
                                            backgroundColor: 'white',
                                            width: '100%',
                                            border: '2px #4137AF solid !important',
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            padding: '16px 28px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            backgroundColor: 'transparent',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white !important'
                                        },
                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white !important',
                                        },
                                        '& .Mui-focused.MuiInputLabel-root': {
                                            color: '#FE754B !important',
                                        },
                                        '& .MuiInputAdornment-root img': {
                                            paddingLeft: '8px'
                                        }
                                    }}
                            />
                        </div>

                    </div>
                    
                    <TextField
                        className={'registrationEmail'}
                        placeholder={t("email")}
                        name="login"
                        onChange={handleChange} 
                        value={formState.login}
                        type='email'
                        helperText={errors.login || "\u00A0"}
                        error={!!errors.login}
                        onBlur={isEmailFree}
                        FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                        sx={{
                            '&': {
                                width: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px', 
                                color: 'black',
                                backgroundColor: 'white',
                                width: '100%',
                                border: '2px #4137AF solid !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '16px 28px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important'
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important',
                            },
                            '& .Mui-focused.MuiInputLabel-root': {
                                color: '#FE754B !important',
                            },
                            '& .MuiInputAdornment-root img': {
                                paddingLeft: '8px'
                            }
                        }}
                    />
                                    
                    <TextField
                        className={'registrationPassword'}
                        placeholder={t("password")}
                        name="password"
                        onChange={handleChange} 
                        value={formState.password}
                        type={showPassword ? 'text' : 'password'}
                        helperText={errors.password || "\u00A0"}
                        error={!!errors.password}
                        FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                        sx={{
                            '&': {
                                width: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px', 
                                color: 'black',
                                backgroundColor: 'white',
                                width: '100%',
                                border: '2px #4137AF solid !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '16px 28px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important'
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important',
                            },
                            '& .Mui-focused.MuiInputLabel-root': {
                                color: '#FE754B !important',
                            },
                            '& .MuiInputAdornment-root img': {
                                paddingLeft: '8px',
                                paddingRight: '8px'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div
                                        className={classnames(styles.showPasswordIcon)}
                                        onClick={() => togglePasswordVisibility()}>
                                        <img 
                                            src={showPassword ? eyeIconGray : eyeIcon}
                                            alt="show password" 
                                            />
                                    </div>
                                </InputAdornment>
                            )
                          }}
                    />

                    <TextField
                        className={'registrationConfirmPassword'}
                        placeholder={t("confirmPassword")}
                        name="confirmPassword"
                        onChange={handleChange} 
                        value={formState.confirmPassword}
                        type={showConfirmPassword ? 'text' : 'password'}
                        helperText={errors.confirmPassword || "\u00A0"}
                        error={!!errors.confirmPassword}
                        FormHelperTextProps={{ style: { color: '#FFC0CB' } }}
                        sx={{
                            '&': {
                                width: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px', 
                                color: 'black',
                                backgroundColor: 'white',
                                width: '100%',
                                border: '2px #4137AF solid !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '16px 28px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important'
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important',
                            },
                            '& .Mui-focused.MuiInputLabel-root': {
                                color: '#FE754B !important',
                            },
                            '& .MuiInputAdornment-root img': {
                                paddingLeft: '8px',
                                paddingRight: '8px'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div
                                        className={classnames(styles.showPasswordIcon)}
                                        onClick={() => toggleConfirmPasswordVisibility()}>
                                        <img 
                                            src={showConfirmPassword ? eyeIconGray : eyeIcon}
                                            alt="show password" 
                                            
                                            />
                                    </div>
                                </InputAdornment>
                            )
                        }}
                    />
                    <ToggleButtonGroup
                        value={formState.gender}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="gender"
                        className={styles.buttonGroupContainer}
                        fullWidth
                        >
                            <ToggleButton 
                                value="male" 
                                aria-label={t("male")}
                                className={`gender-toggle ${formState.gender === 'male' ? styles.buttonSelected : styles.buttonNotSelected}`}
                                disableRipple
                                >
                                {t("male")}
                            </ToggleButton>
                            <ToggleButton 
                                value="female" 
                                aria-label={t("female")}
                                className={`gender-toggle ${formState.gender === 'female' ? styles.buttonSelected : styles.buttonNotSelected}`}
                                disableRipple
                                >
                                {t("female")}
                            </ToggleButton>
                    </ToggleButtonGroup>

                    <div className={'dateOfBirthContainer'}>
                        <DateOfBirthPicker
                            onDateChange={handleDateChange}
                        />
                        <FormHelperText
                            sx={{
                                color: '#FFC0CB',
                                marginLeft: '16px'
                            }}
                            >{errors.dateOfBirth ? errors.dateOfBirth : "\u00A0"}
                        </FormHelperText>
                    </div>

                    <div className='mt-4 mb-4'>
                        <VioletButton
                            title={t("registration")}
                            type='submit'
                        />
                    </div>

                </form>
                <div className={classnames('col-12')}>
                        <div className={classnames(styles.registerQuestion)}>
                            <div className={classnames('col-1', 'col-md-0', 'col-sm-0', 'col-md-0', 'col-lg-0', 'col-xl-0')}></div>
                            <div className={classnames(styles.whiteLine, 'col-xl-1', 'col-lg-1', 'col-md-1', 'col-sm-1', 'col')}></div>
                            <div className={classnames(styles.headingLines, 'col-xl-2', 'col-lg-3', 'col-md-4', 'col-sm-4', 'col-6')}>{t("alreadyRegister")}</div>
                            <div className={classnames(styles.whiteLine, 'col-xl-1', 'col-lg-1', 'col-md-1', 'col-sm-1', 'col')}></div>
                            <div className={classnames('col-1', 'col-md-0', 'col-sm-0', 'col-md-0', 'col-lg-0', 'col-xl-0')}></div>
                        </div>
                        <div className={classnames(styles.joinContainer, 'col-12', 'mt-xl-5', 'mt-lg-5', 'mt-md-4', 'mt-sm-4', 'mt-4')}>
                            <div className={classnames('col-xl-3', 'col-lg-3', 'col-md-6', 'col-sm-8', 'col-11')}>
                                <Link
                                    to={{ pathname: `/${i18n.language}/auth/login` }} 
                                    className={classnames(styles.redirectoLoign)}
                                >
                                    <b>{t('enterAction')}</b>
                                </Link>
                                {t('toYourAccount')}
                                <b>{t('weGoTonight')}</b>.
                            </div>
                        </div>
                        <div className={classnames(styles.termsContainer, 'col-12', 'mt-xl-5', 'mt-lg-5', 'mt-md-4', 'mt-sm-4', 'mt-4')}>
                            <div className={classnames('col-xl-4', 'col-lg-5', 'col-md-6', 'col-sm-8', 'col-11')}>
                                {t('byContinueYouAgree')}<a className={styles.linkTerms} onClick={() => handleShowPrivacyPolicy()}>{(t('privacy'))}</a>
                            </div>
                        </div>
                </div>
            </div>
            <Privacy open={isPrivacyPolicyOpen} onClose={handlePrivacyPolicyClose}></Privacy>
            <Dialog open={isSuccessDialogOpen} onClose={handleSuccessDialogClose}>
                <DialogContent>
                    <DialogContentText>
                        {t("toActivateCheckEmail")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccessDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RegistrationPage;