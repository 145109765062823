import React, { useState } from 'react';
import styles from './Footer.module.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import instagramWhite from '../../assets/images/common/instagram-white.svg';
import facebookWhite from '../../assets/images/common/facebook-white.svg';
import telegramWhite from '../../assets/images/common/telegram-white.svg';
import logoOrange from '../../assets/images/common/logo-orange-pin.svg';
import whatsapp from '../../assets/images/common/whatsapp.svg';
import { Link, useNavigate } from 'react-router-dom';
import Privacy from '../privacy/Privacy';

const Footer = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };

  const handleShowPrivacyPolicy = () => {
    setPrivacyPolicyOpen(true);
  };

  return (
    <footer>
        <div className={classnames('row', styles.row)}>
          <div className={classnames(styles.footerContainer)}>
            <div className={classnames(styles.footerMainContent)}>
              <div className={classnames(styles.footerLogoContent)}>
                <img className={classnames(styles.footerLogo)} width="197px" height="57px" src={logoOrange} alt="footer wgt" onClick={() => navigate(`/${i18n.language}/`)}/>
                {/* <div className={classnames(styles.mobileApps)}>
                  <img className={classnames(styles.appstoreLogo)} src={appstore} alt="appstore logo"/>
                  <img className={classnames(styles.googleplayLogo)} src={googleplay} alt="googleplay logo"/>
                </div> */}
              </div>
              <div className={classnames(styles.footerLinksContainer)}>
                <div className={classnames(styles.footerLinksNavigation)}>
                    <h3 className={classnames(styles.footerLinksTitle)}>{t("navigation")}</h3>
                    <div className={classnames(styles.footerLinksList)}>

                      <Link
                          to={`/${i18n.language}/`} 
                          style={{ width: '100%', textDecoration: 'none' }}
                          className={classnames(styles.footerNavigationLink)} 
                        >
                        {t("home")}
                      </Link>

                      <Link 
                          to={`/${i18n.language}/news/all`} 
                          style={{ width: '100%', textDecoration: 'none' }}
                          className={classnames(styles.footerNavigationLink)} 
                        >
                        {t("news")}
                      </Link>

                      <Link 
                          to={`/${i18n.language}/places/all`} 
                          style={{ width: '100%', textDecoration: 'none' }}
                          className={classnames(styles.footerNavigationLink)} 
                        >
                        {t("places")}
                      </Link>
                      
                      <Link 
                          to={`/${i18n.language}/events/all`} 
                          style={{ width: '100%', textDecoration: 'none' }}
                          className={classnames(styles.footerNavigationLink)} 
                        >
                        {t("eventList")}
                      </Link>

                      <Link 
                          to={`/${i18n.language}/about-us`} 
                          style={{ width: '100%', textDecoration: 'none' }}
                          className={classnames(styles.footerNavigationLink)} 
                        >
                        {t("aboutUs")}
                      </Link>

                    </div>
                </div>
                
                <div className={classnames(styles.footerLinksContacts)}>
                    <h3 className={classnames(styles.footerLinksTitle)}>{t("contacts")}</h3>
                    <div className={classnames(styles.footerLinksList)}>
                      <div className={classnames(styles.footerNavigationTitle)}>{t("support")}</div>
                      <div className={classnames(styles.footerSupport)}>
                        <img width="15px" height="15px" className={classnames(styles.socialIcon)} src={whatsapp} alt="whatsapp icon"/>
                        <div className={classnames(styles.footerChat)}>{t("chat")}</div>
                      </div>
                      <div className={classnames(styles.footerEmailContainer)}>
                        <div className={classnames(styles.footerEmailLabel)}>{t("mail")}</div>
                        <a href="mailto:info@wegotonight.com" className={classnames(styles.footerEmail)}>info@wegotonight.com</a>
                      </div>
                    </div>
                </div>

                <div className={classnames(styles.footerLinksInfo)}>
                    <h3 className={classnames(styles.footerLinksTitle)}>{t("information")}</h3>
                    <div className={classnames(styles.footerLinksList)}>
                      <div className={classnames(styles.footerNavigationLink)} onClick={() => handleShowPrivacyPolicy()}>{t("cookiePolicyFooter")}</div>
                    </div>
                </div>

              </div>
            </div>
            <div className={classnames(styles.footerBottom)}>
              <div className={classnames(styles.footerBottomContent)}>
                <div className={classnames(styles.copyright)}>
                  Copyright © 2024 by WGT
                </div>
                <div className={classnames(styles.socials)}>
                    <div className={classnames(styles.subscribe)}>{t("subscribe")}</div>
                    <a href="https://www.instagram.com/wegotonight_riga/" target="_blank"><img width="16px" height="16px" className={classnames(styles.socialIcon)} src={instagramWhite} alt="instagram"/></a>
                    <a href="https://www.facebook.com/wegotonight/" target="_blank"><img width="16px" height="16px" className={classnames(styles.socialIcon)} src={facebookWhite} alt="facebook"/></a>
                    <a href="https://t.me/wegotonightinriga/" target="_blank"><img width="16px" height="16px" className={classnames(styles.socialIcon)} src={telegramWhite} alt="telegram"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Privacy open={isPrivacyPolicyOpen} onClose={handlePrivacyPolicyClose}></Privacy>
    </footer>
  );
};

export default React.memo(Footer);
