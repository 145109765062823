import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CustomArrowIcon } from '../../../assets/images/common/blue-dropdown.svg'
const DateOfBirthPicker = ({onDateChange = {}}) => {

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [daysInMonth, setDaysInMonth] = useState(31);
  const { t, i18n } = useTranslation();

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  useEffect(() => {
    if (onDateChange) {
        onDateChange({ day, month, year });
    }
    }, [day, month, year]);

  useEffect(() => {
    switch (month) {
      case 'February':
        setDaysInMonth(isLeapYear(year) ? 29 : 28);
        break;
      case 'April':
      case 'June':
      case 'September':
      case 'November':
        setDaysInMonth(30);
        break;
      default:
        setDaysInMonth(31);
    }

    if (day > daysInMonth) setDay('');
  }, [month, year, day]);

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push(i);
    }
    return years;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Select
            name='day'
            data-selector="dob-day"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            label="Day"
            IconComponent={CustomArrowIcon}
            displayEmpty
            sx={{
                color: day ? 'black' : '#8D8D8D',
                border: '2px solid #4137AF',
                borderRadius: '45px',
                paddingLeft: '14px',
                '& ,MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
                  backgroundColor: 'white',
                },
                '& .MuiPaper-root': {
                  backgroundColor: 'white',
              
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none',
                  outline: 'none',
                  border: 'none'
                },
                '& .MuiSelect-icon': {
                  marginRight: '10px'
                },
              }}
          >
            <MenuItem value="" disabled>
                {t("day")}
            </MenuItem>

            {[...Array(daysInMonth)].map((_, index) => (
              <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={5}>
        <FormControl fullWidth>
          <Select
            name='month'
            data-selector="dob-month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            label="Month"
            IconComponent={CustomArrowIcon}
            displayEmpty
            sx={{
                color: month ? 'black' : '#8D8D8D',
                border: '2px solid #4137AF',
                borderRadius: '45px',
                paddingLeft: '14px',
                '& ,MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
                  backgroundColor: 'white',
                },
                '& .MuiPaper-root': {
                  backgroundColor: 'white',
              
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none',
                  outline: 'none',
                  border: 'none'
                },
                '& .MuiSelect-icon': {
                  marginRight: '10px'
                },
              }}
          >
            <MenuItem value="" disabled>
                {t("month")}
            </MenuItem>
            {months.map((monthName) => (
              <MenuItem key={monthName} value={monthName}>{t(monthName.toLocaleLowerCase())}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth>
          <Select
            name='year'
            data-selector="dob-year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            label="Year"
            IconComponent={CustomArrowIcon}
            displayEmpty
            sx={{
                color: year ? 'black' : '#8D8D8D',
                border: '2px solid #4137AF',
                borderRadius: '45px',
                paddingLeft: '14px',
                '& ,MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
                  backgroundColor: 'white',
                },
                '& .MuiPaper-root': {
                  backgroundColor: 'white',
              
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none',
                  outline: 'none',
                  border: 'none'
                },
                '& .MuiSelect-icon': {
                  marginRight: '10px'
                },
              }}
          >
            <MenuItem value="" disabled>
                {t("year")}
            </MenuItem>
            {generateYears().map((yearValue) => (
              <MenuItem key={yearValue} value={yearValue}>{yearValue}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default React.memo(DateOfBirthPicker);
