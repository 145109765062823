import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.scss'
import  './Header.scss'
import classnames from 'classnames';
import logo from '../../assets/images/common/logo-orange-pin.svg';
import food from '../../assets/images/categories/food.svg';
import { Link, useNavigate } from 'react-router-dom';
import favouritesImage from '../../assets/images/authorization/favourites.svg';
import favouritesEllipse from '../../assets/images/authorization/ellipse-fav.svg';
import { getRequest } from '../../services/fetch/AxiosHelper';
import { useRef } from 'react';
import CategoryRow from '../category/CategoryRow';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import SideMenu from './SideMenu';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import TransparentButton from '../mui/transparentButton/TransparentButton';
import { getAuthToken, getUsername, logout } from '../../services/cookie/CookieManager';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateFavourites, resetUpdateHeader} from '../../redux/actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const mapDispatchToProps = (dispatch) => ({
  updateFavourites: () => dispatch(updateFavourites()),
  resetHeader: () => dispatch(resetUpdateHeader()),
});

const ResponsiveHeader = ({nameUpdated, resetHeader }) => {
  const shouldUpdateHeader = useSelector(state => state.favourites.shouldUpdateHeader);

  const languages = ['en', 'lv', 'ru'];

  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const location = useLocation();
  const renderAfterCalled = useRef(false);
  const params = useParams()
  const [isProfileActionsOpen, setIsProfileActionsOpen] = useState(false);
  
  const [categories, setCategories] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [isCategoriesOpened, setIsCategoriesOpened] = useState(false);
  
  const [isUserAuth, setIsUserAuth] = useState(getAuthToken())
  const [userFavs, setUserFavs] = useState(0)
  const [username, setUsername] = useState(null)

  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false)

  const [language, setLanguage] = useState(i18n.language)

  const navigate = useNavigate();
  

  const saveLanguage = (langCode) => {
    i18n.changeLanguage(langCode)
    setLanguage(langCode)
  }

  const onClickCategories = () => {
    setIsCategoriesOpened(!isCategoriesOpened)
  }

  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (languages.includes(pathSegments[0]) && pathSegments[0] != i18n.language) {
      i18n.changeLanguage(pathSegments[0])
      setLanguage(pathSegments[0])
    }
    
  }, [location])

  useEffect(() => {
      if (!renderAfterCalled.current) {
          getRequest(`categories/all?language=${i18n.language}`)
              .then((response) => response.data)
              .then((data) => {
                setCategories(data)
              })
              .catch((error) => {
                  console.log(error)
              });
      }
  
      renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    getRequest(`categories/all?language=${i18n.language}`)
        .then((response) => response.data)
        .then((data) => {
          setCategories(data)
        })
        .catch((error) => {
            console.log(error)
        });
}, [i18n.language]);

  useEffect(() => {
    setIsUserAuth(getAuthToken())
    setUsername(getUsername())
  }, [params.name, nameUpdated]);

  useEffect(() => {
    if (shouldUpdateHeader) {
      getFavCount()
      resetHeader()
    }
  }, [shouldUpdateHeader, resetHeader]);

  useEffect(() => {
    getFavCount()
  }, [])

  const getFavCount = () => {
    if (isUserAuth != null) {
      getRequest("user/favouritesCount")
          .then((response) => response.data)
          .then((data) => {
            setUserFavs(data)
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }

  const onClickMyProfileInfo = () => {
    setIsProfileActionsOpen(false)
    navigate(`/${i18n.language}/profile/#changeProfile`)
  }

  const onClickMyProfileFavouritesPlaces = () => {
    setIsProfileActionsOpen(false)
    navigate(`/${i18n.language}/profile/#favPlaces`)
  }

  const onClickMyProfileFavouritesEvents = () => {
    setIsProfileActionsOpen(false)
    navigate(`/${i18n.language}/profile/#favEvents`)
  }

  const onClickLogOut = () => {
    logout()
    setIsProfileActionsOpen(false)
    window.location.reload();
  }

  const onLangItemClick = (language) => {
    saveLanguage(language)
    setIsLanguagesOpen(false)
  }

  const handleInput = (newValue) => {
    getRequest(`places/search?placeName=${newValue}`)
    .then((response) => response.data)
    .then((data) => {
        setSearchResults(data);
    })
    .catch((error) => {
        console.log(error);
    });
  }

  return (
    <nav className={classnames('navbar', 'navbar-expand-lg', 'pb-0', styles.headerNav)}>

      <div className={classnames('row', styles.row, styles.navigationRow)}>

        <div className={classnames(styles.hamburgerMenu, 'navbar-toggler', 'col-1')}>
          <SideMenu />
        </div>
      
        <div className={classnames(styles.logoContainer, 'col-lg-3', 'col-xl-3', 'col-xxl-2', 'col-2', 'visibleDesktop', 'align-self-center', 'justify-content-start')}>
            <Link
              to={{
                    pathname: `/${i18n.language}/`,
              }} 
              style={{
                display: 'inline-block',
                textDecoration: 'none' 
              }}
            >
              <img width="150px" height="44px" className={styles.logo} src={logo} alt="logo" />
            </Link>
        </div>

        <div className={classnames('col-lg-6', 'col-xl-6', 'col-xxl-7', 'col-7', 'visibleDesktop', 'align-self-center', 'justify-content-center')}>
          <Autocomplete
              blurOnSelect    
              disablePortal
              popupIcon={null}
              autoHighlight={true}
              clearIcon={<CloseIcon style={{ color: 'white', zIndex: '99999999'}} />}
              id="placeSearch"
              options={searchResults|| []}
              noOptionsText={t("nothingExists")}
              className="autocompleteHeader"
              getOptionLabel={(option) => option.placeName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onInputChange={(event, newValue) => handleInput(newValue) }
              renderInput={(params) => 
              <TextField {...params} 
                variant="outlined"
                label={t("enterPlaceNamePlaceholder")}
              />}
              PaperComponent={({ children }) => (
                <Paper 
                  sx={{ 
                    '.MuiAutocomplete-noOptions': {
                      color: 'white'
                    },
                    '.MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: '#FE754B',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#FE754B',
                      },
                    },
                    backgroundColor: '#1A2532',
                    color: 'white', 
                    border: '2px solid #FE754B',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    borderTopRightRadius: '0',
                    borderTopLeftRadius: '0',
                    borderTop: 'none',
                    overflow: 'hidden',
                    '.MuiAutocomplete-listbox': {
                      paddingTop: '0px',
                      paddingBottom: '15px',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      scrollbarWidth: 'none', 
                      msOverflowStyle: 'none',  
                      '.MuiAutocomplete-option': {
                        '&:hover': {
                          backgroundColor: '#FE754B',
                        },
                      },
                    },
                  }} 
                  children={children} 
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  navigate(`/${i18n.language}/places/${newValue.uri}`);
                }
            }}
            />
        </div>

        <div className={classnames(styles.logoContainer, 'col-xl-10', 'col-lg-10', 'col-md-10', 'col-sm-10', 'col-xs-9', 'col-10', 'visibleMobile', 'visibleTablet')}>
          <Link
              to={{
                    pathname: `/${i18n.language}/`,
              }} 
              style={{
                display: 'inline-block',
                textDecoration: 'none' 
              }}
            >
              <img width="150px" height="44px" className={styles.logo} src={logo} alt="logo" />
            </Link>
        </div>
        <ClickAwayListener onClickAway={() => setIsLanguagesOpen(false)}>
          <div className={classnames(styles.languageDropdown, 'col-xl-1', 'col-lg-1', 'col-md-1', 'col-sm-1', 'col-xs-2', 'col-1', 'align-self-center', 'justify-content-start')}>
            <button className={classnames(styles.dropdownToggle, 'btn', 'text-white')} type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIsLanguagesOpen(!isLanguagesOpen)}>
              {language.toUpperCase()} 
              <ExpandMoreIcon style={{ color: 'white', marginTop: '-2px' }}/>
            </button>
            <div className={classnames(styles.languageDropDownList ,isLanguagesOpen ? styles.displayYes : styles.displayNo)}>
              <div key={"lv"} className={classnames(styles.langItem)} onClick={() => onLangItemClick("lv")}>LV</div>
              <div key={"en"} className={classnames(styles.langItem)} onClick={() => onLangItemClick("en")}>EN</div>
              <div key={"ru"} className={classnames(styles.langItem)} onClick={() => onLangItemClick("ru")}>RU</div>
            </div>
          </div>
        </ClickAwayListener>
        <div className={classnames(styles.loginButton, 'col-2', 'visibleDesktop', 'align-self-center', 'justify-content-start')}>
          {isUserAuth == null && 
            <Link
              to={{ pathname: `/${i18n.language}/auth/login` }} 
              state={{ previousPath: pathname }}
              style={{
                display: 'inline-block',
                width: '100%',
                textDecoration: 'none' 
              }}
            >
              <TransparentButton
                title={t("logIn")}
              />
            </Link>
          }
          {isUserAuth != null && 
            <ClickAwayListener onClickAway={() => setIsProfileActionsOpen(false)}>
              <div className={classnames(styles.profileContainer)}>
                  <div className={styles.favouritesContainer} onClick={ () => onClickMyProfileFavouritesPlaces()}> 
                    <div className={styles.favouritesCount}>
                      <img width="25px" height="26px" className={styles.favouritesEllipse} src={favouritesEllipse} alt="favourites" />
                      <div className={userFavs < 10 ? styles.countSingle : styles.countMultiple}>{userFavs > 9 ? "9+" : userFavs}</div>
                    </div>
                    <img width="25px" height="23px" className={styles.favouritesImage} src={favouritesImage} alt="favouritesHeart" />
                  </div>
                  
                  <div className={styles.profileImage} onClick={() => setIsProfileActionsOpen(!isProfileActionsOpen)}>
                      <div className={styles.profileLetter}>{username?.[0]}</div>
                  </div>
                  <div className={classnames(styles.profileActions, isProfileActionsOpen ? styles.displayYes : styles.displayNo)}> 
                      <div className={classnames(styles.itemProfileAction)} onClick={ () => onClickMyProfileInfo()}>{t("myPage")}</div>
                      <div className={classnames(styles.itemProfileAction)} onClick={ () => onClickMyProfileFavouritesPlaces()}>{t("favPlaces")}</div>
                      <div className={classnames(styles.itemProfileAction)} onClick={ () => onClickMyProfileFavouritesEvents()}>{t("favEvents")}</div>
                      <div className={classnames(styles.separator)}>&nbsp;</div>
                      <div className={classnames(styles.itemProfileAction)} onClick={onClickLogOut}>{t("logOut")}</div>
                  </div>
              </div>
            </ClickAwayListener>
          }
        </div>
      </div>

      <div className={classnames('row', styles.row, styles.navigationRow, styles.navigation, 'visibleDesktop')} id="navbarNav">
        <ClickAwayListener onClickAway={() => setIsCategoriesOpened(false)}>
          <div 
            onClick={onClickCategories}
            className={classnames(styles.relativeParent, isCategoriesOpened ? styles.categoriesExpanded : styles.categoriesDefault, 'col-lg-3', 'col-xl-2', 'col-xxl-2', 'col-2', 'd-flex', 'align-self-center', 'justify-content-start')}>
              <span className={classnames('align-self-center', styles.clickableSpan, isCategoriesOpened ? styles.clickedCategories : null)}
              >{t("categories").toLocaleUpperCase()}</span>
              <div>
                <div className={classnames(styles.clickedCategoriesBackgrounds, isCategoriesOpened ? styles.displayYes : styles.displayNo)}>&nbsp;</div>
                <div className={classnames(styles.categoriesContainer, isCategoriesOpened ? styles.displayYes : styles.displayNo)}>
                  <div className={classnames('d-flex')} >
                    <div id='categoryList' className={classnames(styles.categoryList)} >
                      {  
                      categories?.map((category) => {
                        return (
                        <Link key={category.id} to={`/${i18n.language}/places/category/${category.id}`} style={{ width: '100%', textDecoration: 'none' }}>
                          <CategoryRow
                            key = {category.id}
                            categoryImage = {category.image ? category.image : food}
                            categoryName = {category.name ? category.name : "Category"}
                            onClick = { null } 
                          />
                        </Link>
                        )
                      })
                      }
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </ClickAwayListener>
        <ul className={classnames('navbar-nav', styles.navigationLinks, 'col-lg-9', 'col-xl-10', 'col-xxl-10', 'col-10')}>

          <li className={classnames('nav-item', styles.navigationItem)}>
           <Link 
              to={`/${i18n.language}/news/all`} 
              style={{ width: '100%', textDecoration: 'none' }}
              className={classnames(styles.navLink)} 
            >
            {t("news")}
           </Link>
          </li>

          <li className={classnames('nav-item', styles.navigationItem)}>
           <Link 
              to={`/${i18n.language}/places/all`} 
              style={{ width: '100%', textDecoration: 'none' }}
              className={classnames(styles.navLink)} 
            >
            {t("places")}
           </Link>
          </li>

          <li className={classnames('nav-item', styles.navigationItem)}>
           <Link 
              to={`/${i18n.language}/events/all`} 
              style={{ width: '100%', textDecoration: 'none' }}
              className={classnames(styles.navLink)} 
            >
            {t("eventList")}
           </Link>
          </li>

          <li className={classnames('nav-item', styles.navigationItem)}>
           <Link 
              to={`/${i18n.language}/about-us`} 
              style={{ width: '100%', textDecoration: 'none' }}
              className={classnames(styles.navLink)} 
            >
            {t("aboutUs")}
           </Link>
          </li>

        </ul>

      </div>

    </nav>
  );
};

export default React.memo(connect(null, mapDispatchToProps)(ResponsiveHeader));