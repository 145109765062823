import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import Unauthorized from '../../unauthorized/Unauthorized';
import { postCsrf } from '../../../services/fetch/AxiosHelper';
import { Link } from 'react-router-dom';
import notFavouriteIcon from '../../../assets/images/common/favouriteOrange.svg'
import favouriteIcon from '../../../assets/images/common/heart-fill-orange.svg'
import ShareModal from '../../modals/shareModal/ShareModal';
import { useTranslation } from 'react-i18next';
import styles from './EventCard.module.scss'
import classnames from 'classnames';
import clock from '../../../assets/images/common/clockWhite.svg'
import EighteenUpRatingIcon from '@mui/icons-material/EighteenUpRating';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import VapeFreeIcon from '@mui/icons-material/VapeFree';
import VapingRoomsIcon from '@mui/icons-material/VapingRooms'
import { getAuthToken } from '../../../services/cookie/CookieManager';
import geoIcon from '../../../assets/images/common/geo-card.svg'
import { ClickAwayListener } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { useDispatch } from 'react-redux';
import { updateFavourites } from '../../../redux/actions';
import notFavouriteIconWgtChoice from '../../../assets/images/common/favourite.svg'
import favouriteIconWgtChoice from '../../../assets/images/common/heart-fill.svg'

function EventCard({ title, logo, isFavourite, uri, date, eventSpecifications, heartClicked = () => {}, onNavigate = () => {}, breadcrumbs = [], address, isWgtChoice = false}) {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [showUnauthorized, setShowUnauthorized] = useState(false);
    
    const [isFav, setIsFav] = useState(isFavourite)

    const [showTooltip, setShowTooltip] = useState(false)
    const [touchStart, setTouchStart] = useState(null);

    const handleShowShareModal = () => setShowShareModal(true); 
    const handleCloseShareModal = () => setShowShareModal(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const handleCloseUnauthorized = () => setShowUnauthorized(false);

    const handleClickOnFavouriteIcon = () => {
        if (getAuthToken() == null) {
            setShowUnauthorized(true)
        } else {
            postCsrf(`${uri}/toggleFavourite`)
            .then((response) => response.data)
            .then((data) => {
                setIsFav(data)
                dispatch(updateFavourites());
                heartClicked()
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }
    const handleScroll = () => {
        if (showTooltip) {
          setShowTooltip(false);
        }
    };

    const onTouchStart = (e) => {
        setShowTooltip(false);
    }

    useEffect(() => {
        window.addEventListener('touchstart', onTouchStart);
        return () => {
          window.removeEventListener('touchstart', onTouchStart);
        };
    }, [touchStart]);
      
    useEffect(() => {
        window.addEventListener('touchstart', onTouchStart);
        return () => {
        window.removeEventListener('touchstart', onTouchStart);

        };
    }, [touchStart]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
          window.removeEventListener('scroll', handleScroll, true);
        };
    }, [showTooltip])


    return (
        <div>
            {showUnauthorized && <Unauthorized show={showUnauthorized} handleClose={handleCloseUnauthorized}/>}
            <Card className={classnames(styles.eventCard, isWgtChoice ? styles.cardWgtChoice : styles.cardDefault)}>
                <Link
                    to={{ pathname: `/${i18n.language}${uri}` }} 
                    style={{ textDecoration: 'none' }}
                    state={{ breadcrumbs: breadcrumbs }}
                    >
                    <CardMedia
                        component="img"
                        alt="Logo"
                        height="161"
                        image={logo}
                        title="Logo"
                        loading="lazy"
                        onClick={() => onNavigate()}
                        decoding="async"
                    />
                </Link>
                {
                eventSpecifications?.adultsOnly && (
                    <div className={classnames(styles.hoverOverlayAdultsOnly)}>
                        <span className={classnames(styles.shadow, isWgtChoice ? styles.shadowWgtChoice : styles.shadowDefault)}>
                            <EighteenUpRatingIcon sx={{ fontSize: '2rem',  color: 'white' }}/>
                        </span>
                    </div>
                    )
                }
                {
                eventSpecifications != null && (
                    <div className={classnames(styles.hoverOverlaySmoking, !eventSpecifications?.adultsOnly ? styles.smokingTop : null)}>
                        <span className={classnames(styles.shadow, isWgtChoice ? styles.shadowWgtChoice : styles.shadowDefault)}>
                            {eventSpecifications?.allowSmoking ? <SmokingRoomsIcon sx={{ fontSize: '2rem',  color: 'white' }}/> : <SmokeFreeIcon sx={{ fontSize: '2rem',  color: 'white' }}/>}
                            {eventSpecifications?.allowVaping ? <VapingRoomsIcon sx={{ fontSize: '2rem',  color: 'white' }}/> : <VapeFreeIcon sx={{ fontSize: '2rem',  color: 'white' }}/>}
                        </span>
                    </div>
                    )
                }
                {
                eventSpecifications?.paidEntry && (
                    <div className={classnames(styles.hoverOverlayPaidEntry, !eventSpecifications?.adultsOnly ? styles.smokingTop : null)}>
                        <span className={classnames(styles.shadow, isWgtChoice ? styles.shadowWgtChoice : styles.shadowDefault, 'd-flex', 'align-items-center', 'mx-2')}>
                            <span className={classnames(styles.entry)}>
                                {eventSpecifications?.paidEntry ? t("paidEntry") : t("freeEntry")}
                            </span>
                        </span>
                    </div>
                    )
                }
                <div className={classnames(styles.mainEventCardContainer)}>
                    <CardContent>
                        <div className={classnames(styles.eventCardTitle)}>
                            {title}
                        </div>
                        { (date != null && date.length > 0) &&
                            <div className={classnames(styles.workingTimeContainer)}>
                                <img src={clock} alt="clock image"/>  <div className={classnames(styles.workingHours)}>{date}</div>
                            </div>
                        }
                        { (address != null && address) &&
                            <ClickAwayListener onClickAway={() => setShowTooltip(false) }>
                                <Tooltip
                                    arrow
                                    TransitionComponent={Zoom}
                                    placement="bottom-start"
                                    title={address}
                                    open={showTooltip}
                                    onOpen={() => setShowTooltip(true)}
                                    onClose={() => setShowTooltip(false)}
                                    disableTouchListener
                                    disableFocusListener
                                >
                                { address && address != null &&
                                    <div className={classnames(styles.eventCardTags)} onClick={() => setShowTooltip(!showTooltip)}>
                                        <div className={classnames(styles.addressContainer)}>
                                            <img src={geoIcon} alt=""/> <div className={classnames(styles.addressValue)}>{address}</div>
                                        </div>
                                    </div>
                                }
                                </Tooltip> 
                            </ClickAwayListener>
                        }
                    </CardContent>
                </div>
                <div className={classnames(styles.buttonsContainer)}>
                    <Link 
                        to={{ pathname: `/${i18n.language}${uri}` }} 
                        state={{ breadcrumbs: breadcrumbs }}
                        style={{textDecoration: 'none' }}
                        className={classnames(styles.eventCardButtonRead, isWgtChoice ? styles.eventCardButtonReadWgtChoice :styles.eventCardButtonReadDeafult)}
                        onClick={() => onNavigate()}
                        >
                        {t("readMore")}
                    </Link>
                    <div>
                        <Tooltip title={isFav ? t("removeFromFavorites") : t("addToFavorites")}>
                            <IconButton onClick={() => { handleClickOnFavouriteIcon() }}>
                            {isFav ? 
                                <img width="24px" height="24px" src={isWgtChoice ? favouriteIconWgtChoice : favouriteIcon} alt="Favourite" /> : 
                                <img width="24px" height="24px" src={isWgtChoice ? notFavouriteIconWgtChoice : notFavouriteIcon} alt="Not Favourite" />
                            }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("share")}>
                            <IconButton onClick={() => { handleShowShareModal() }}>
                                <ShareIcon style={{ color: isWgtChoice ? 'white' : '#FE754B' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Card>
            <ShareModal show={showShareModal} onHide={handleCloseShareModal} link={"https://wegotonight.com" + uri}/>
        </div>
    );
}

export default React.memo(EventCard);
