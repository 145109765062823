import React from 'react';
import Button from '@mui/material/Button';
import styles from './VioletButton.module.scss'
import classnames from 'classnames';

function VioletButton({ key, title, onClick = () => {}, type = 'button', isDisabled = false}) {

    return (
        <Button    
                className={classnames(styles.violetButton)}
                variant="contained"
                onClick={() => onClick()}
                type={type}
                disabled={isDisabled}
            >
                {title}
            </Button>     
    );
}

export default React.memo(VioletButton);
