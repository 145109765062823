import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const LanguageWrapper = ({ children }) => {

    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const languages = ['en', 'lv', 'ru'];
    const pathSegments = location.pathname.split('/').filter(Boolean);
  
    useEffect(() => {
      if (!languages.includes(pathSegments[0])) {
        navigate(`/${i18n.language}${location.pathname}`, { replace: true });
      }
    }, [location, navigate, i18n.language]);
  
    return <>{children}</>;
  };

  export default LanguageWrapper;