import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicComponent = ({children}) => {
 
const location = useLocation()

const [showNavBar, setShowNavBar] = useState(false)

useEffect(() => {
  const languagePrefixPattern = "\\/(en|ru|lv)";

  const doesNotMatch = !new RegExp(`^${languagePrefixPattern}\/auth\/password\/[^/]+$`).test(location.pathname);

  const isLogin = new RegExp(`^${languagePrefixPattern}\/auth\/login$`).test(location.pathname);
  const isRegistration = new RegExp(`^${languagePrefixPattern}\/auth\/registration$`).test(location.pathname);
  const isForgot = new RegExp(`^${languagePrefixPattern}\/auth\/forgot$`).test(location.pathname);
  const isAdminPanel = new RegExp(`^${languagePrefixPattern}\/adminPanel`).test(location.pathname);

  setShowNavBar(!isLogin && !isRegistration && !isForgot && doesNotMatch && !isAdminPanel);
}, [location]);


return (
    <div>
      {showNavBar && children}
    </div>
  );
};


export default DynamicComponent;