import React, { useState, useEffect } from 'react';
import { Divider, Typography } from '@mui/material';
import { getRequest } from '../../services/fetch/AxiosHelper';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import CustomCard from '../mui/card/CustomCard';
import './Top.scss'
import styles from './Top.module.scss'
import classNames from 'classnames';
import { Navigation } from 'swiper/modules';
import { CommonUtils } from '../../utils/CommonUtils';

const Top = ({ clickedOnCard = () => {} }) => {
    const [allTops, setAllTops] = useState([]);
    const { t, i18n } = useTranslation();
    const { formatDateAndStatus, getFormmatedWorkingHours } = CommonUtils();

    const getAllTops = () => {
        getRequest(`/top/all`)
            .then(response => setAllTops(response.data))
            .catch(error => console.error('Failed to fetch tops:', error));
    };
    useEffect(() => {
        getAllTops();
    }, [i18n.language]);

    const getTopName = (top) => {
        const currentLanguage = i18n.language
        if (currentLanguage === "ru") return top.nameRu
        if (currentLanguage === "lv") return top.nameLv
        return top.nameEn
    }

    function mapEventToObject(item) {
        return {
            key: item.id,
            objectName: item.name,
            mainImg: item.image,
            onClickNavigationUri: '/events/' + item.uri,
            date: formatDateAndStatus(item.startDate, item.endDate),
            isFav: item.isFavourite,
            isWgtChoice: item.isWgtChoice,
            address: item.address,
            isFranchise: false
          };
    }

    function mapPlaceToObject(item) {
        return {
            key: item.id,
            objectName: item.name,
            mainImg: item.image,
            onClickNavigationUri: item.isFranchise ? '/franchise/' + item.id : '/places/' + item.uri,
            date: item.isFranchise ? t("differentTimes") : getFormmatedWorkingHours(item.workingHours),
            isFav: item.isFavourite,
            isWgtChoice: item.isWgtChoice,
            address: item.isFranchise ? t("manyAddresses") : item.address,
            isFranchise: item.isFranchise
          };
    }
    
    return (  
        <div>
            {allTops?.map(top => {
                let topName = getTopName(top);
                let items = top.type === 'PLACE' ? top.places : top.events;
                let mappedObjects = items?.map(item => top.type === 'PLACE' ? mapPlaceToObject(item) : mapEventToObject(item));
                return (
                    <div key={top.id} className={classNames(styles.topRow)}>
                        <div className={classNames(styles.topTitleContainer)}> 
                            <Typography
                                className={classNames(styles.topTitle)}
                                variant="h4" 
                                gutterBottom
                            >
                                {topName}
                                <Divider 
                                    variant="middle"
                                    sx={{ borderBottomWidth: '4px', background: '#FE754B', opacity: '1' }}
                                    className={classNames(styles.dynamicDivider)}
                                 />
                            </Typography>
                        </div>
                     
                        <div id="homePageSwiperTop" className={classNames(styles.topContainer)}>
                        <Swiper
                            navigation={true}
                            grabCursor={true}
                            className="swiper"
                            slideClass="swiper-slide"
                            slideActiveClass="swiper-slide-active"
                            modules={[Navigation]}
                            touchStartPreventDefault={true}
                            breakpoints={{
                                100: {
                                    slidesPerView: 1.2,
                                    spaceBetween: 20
                                },
                                560: {
                                    slidesPerView: 1.8,
                                    spaceBetween: 10
                                },
                                650: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                                },
                                850: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 10
                                },
                                965: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                1160: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                1350: {
                                    slidesPerView: 4,
                                    spaceBetween: 30
                                }
                            }}
                        >
                            {mappedObjects?.map(topItem => (
                                <SwiperSlide key={topItem.key}>
                                    <div className="cardWrapper">
                                        <CustomCard
                                            title={topItem.objectName}
                                            logo={topItem.mainImg}
                                            isFavourite={topItem.isFav}
                                            uri={topItem.onClickNavigationUri}
                                            date={topItem.date}
                                            address={topItem.address}
                                            isWgtChoice={topItem.isWgtChoice}
                                            isFranchise={topItem.isFranchise}
                                            onNavigate={() => {
                                                clickedOnCard()
                                            }}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
      

export default React.memo(Top);