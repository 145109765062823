import React from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from '@mui/material';

const StyledCard = React.memo(styled(Card)(({ theme }) => ({
    width: 'auto',
    minWidth: '41%',
    maxWidth: '60%',
    border: '1px solid #FE754B',
    borderRadius: '8px',
    backgroundColor: 'transparent !important',
    color: 'white',
    marginBottom: 2,
    boxShadow: 'none',

    '&.MuiCardContent-root': {
      padding: '16px',
    },

    '.MuiTypography-root': {
        color: "white",
        fontFamily: 'Arial',
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '-0.24px', 
    },
  
})));


function Reminder({ key, title="", content }) {

    return (
        <StyledCard sx={{  }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={1}>
                    <InfoIcon sx={{ color: '#FE754B' }}/>
                    </Grid>
                    <Grid item xs={11}>
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {content}
                    </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>   
    );
}

export default React.memo(Reminder);
