import { useTranslation } from 'react-i18next';

const SortingOrders = () => {
    const { t, i18n } = useTranslation();

    const SortingOrder = {
        ALPHABETICAL: "ALPHABETICAL",
        TOTAL_RATING: "TOTAL_RATING"
    };
      
    const SortingOrderTranslation = {
        ALPHABETICAL: t("alphabetical"),
        TOTAL_RATING: t("totalRating")
    };

      return { SortingOrder, SortingOrderTranslation }
    }
    
export default SortingOrders;