import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Unauthorized.module.scss';
import './Unauthorized.scss';
import classnames from 'classnames';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';

const Unauthorized = ({ handleClose, show }) => {
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const onClickRegistration = () => {
        navigate(`/${i18n.language}/auth/registration`);
    };

    const onClickLogin = () => {
        navigate(`/${i18n.language}/auth/login`, { state: { previousPath: pathname } });
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            disableScrollLock={true}
            sx={{
                marginLeft: '20px',
                marginRight: '20px',
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '360px',
                bgcolor: '#1C1A32',
                border: '2px solid #FE754B',
                color: 'white',
                p: 3,
                borderRadius: '25px'
                }}>
                <div
                    className={classnames(styles.errorTitle)}
                    >
                    {t("authorizationError")}
                </div>

                <div
                    className={classnames(styles.errorText)}
                    id="modal-modal-description" 
                    >
                    {t("firstYouNeedToLogin")}
                </div>
                <div className={classnames(styles.buttonWrapper)}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickLogin}
                        sx={{
                            border: '1px solid #FE754B',
                            backgroundColor: "#FE754B",
                            borderRadius: '25px',
                            fontFamily: 'Arial',
                            fontSize: '12px',
                            fontWeight: '400',
                            letterSpacing: '-0.24px',
                            color: 'white',
                            '&:hover': {
                                border: '1px solid #4137AF',
                                backgroundColor: "#4137AF",
                                transition: 'background-color .3s, color .3s, border .3s'
                            }
                            
                        }}
                    >
                        {t("logIn")}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClickRegistration}
                        sx={{
                            ml: 2,
                            border: '1px solid #4137AF',
                            backgroundColor: "#4137AF",
                            borderRadius: '25px',
                            fontFamily: 'Arial',
                            fontSize: '12px',
                            fontWeight: '400',
                            letterSpacing: '-0.24px',
                            color: 'white',
                            '&:hover': {
                                border: '1px solid #FE754B',
                                backgroundColor: "#FE754B",
                                transition: 'background-color .3s, color .3s, border .3s'
                            }
                        }}
                    >
                        {t("registration")}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default React.memo(Unauthorized);