import styles from './LoginPage.module.scss'
import React, { useState, useEffect, useRef } from 'react';
import './LoginPage.module.scss';
import loginImage from '../../../assets/images/authorization/login.svg';
import passwordImage from '../../../assets/images/authorization/password.svg';
import backarrow from '../../../assets/images/common/arrow-left.svg';
import erorImage from '../../../assets/images/common/error.svg';
import logo from '../../../assets/images/common/logo-orange-pin.svg';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { postCsrf } from '../../../services/fetch/AxiosHelper';
import { setAuthHeader, setIsEmailActivated, setUserRole, setUsername } from '../../../services/cookie/CookieManager';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TitlesData from '../../../data/TitlesData';
import { useLocation } from 'react-router-dom';
import Privacy from '../../../components/privacy/Privacy';
import VioletButton from '../../../components/mui/violetButton/VioletButton';
import { InputAdornment, TextField } from '@mui/material';

const LoginPage = () => {
    const location = useLocation()
    const prevPath = location.state?.previousPath
    const initialMount = useRef(true);
    const {Titles} = TitlesData()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [showErrorBlock, setShowErrorBlock] = useState(false);
    const [errorVisibleDuration, setErrorVisibleDuration] = useState(0);
    const [formState, setFormState] = useState({
        login: '',
        password: ''
      });
    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);

    const handlePrivacyPolicyClose = () => {
      setPrivacyPolicyOpen(false);
    };
  
    const handleShowPrivacyPolicy = () => {
      setPrivacyPolicyOpen(true);
    };
  
    useEffect(() => {
        if (initialMount.current) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
          });
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        postCsrf('/v1/login', JSON.stringify(formState))
            .then((response) => response.data)
            .then((data) => {
                setAuthHeader(data.token)
                setUsername(data.firstName + " " + data.lastName)
                setIsEmailActivated(data.isActive)
                setUserRole(data.role)
                setFormState({
                    foodRating: '',
                    serviceRating: '',
                    financeRating: '',
                    feedback: ''
                });
                if (prevPath != null) {
                    navigate(-1);
                } else {
                    navigate(`/${i18n.language}/`);
                }
            })
            
            .catch((error) => {
                setShowErrorBlock(true)
                setShowError(true)
                setErrorVisibleDuration(1000)
                console.error('Error submitting form:', error)
            });
    };

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
    
        setFormState((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleOnForgot = () => {
        navigate(`/${i18n.language}/auth/forgot`)
    }

    const onBackPressed = () => {
        navigate(-1)
    };

    useEffect(() => {
        let timeoutId;
        if (showError && errorVisibleDuration > 0) {
            timeoutId = setTimeout(() => {
                setShowError(false);
            }, errorVisibleDuration);
        }
        return () => clearTimeout(timeoutId)
    }, [showError, errorVisibleDuration])

    return (
        <div className={classnames(styles.loginPageContainer, styles.backgroundTint)}>
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{Titles.login}</title>
        </Helmet>
        {showErrorBlock &&
            <div className={classnames(styles.errorMessageContainer, showError ? styles.fadeIn : styles.fadeOut,{[styles.fadeOut]: !showError}, 'col-12')}>
                <div className={classnames(styles.errorMessage)}><img src={erorImage} className={classnames(styles.errorIcon)} alt="icon" />Wrong password</div>
            </div>
        }
            <div className={classnames(styles.header, 'col-12')}>

                <div className={classnames(styles.backContainer, classnames('col-xl-3', 'col-lg-3', 'col-md-3', 'col-sm-2', 'col-2'))} onClick={onBackPressed}>
                    <img src={backarrow} alt="iconBack" />
                    <div className={classnames(styles.backText, 'd-none', 'd-lg-block')}>{t("back")}</div>
                </div>

                <div className={classnames(styles.logoContainer, 'col-xl-6', 'col-lg-6', 'col-md-6', 'col-sm-8', 'col-9')}>
                    <Link
                        to={{ pathname: `/${i18n.language}` }} 
                        style={{
                            display: 'inline-block',
                            width: 'auto',
                            textDecoration: 'none' 
                        }}
                    >
                        <img className={styles.logo} src={logo} alt="logo" />    
                    </Link>
                </div>

            </div>

            <div className={classnames(styles.loginFormContaner, 'col-12')}>
                <form onSubmit={handleSubmit} className={classnames('col-xl-4', 'col-lg-5','col-md-6', 'col-sm-6', 'col-10')}>

                    <TextField
                        placeholder={t("email")}
                        name="login"
                        onChange={handleChange} 
                        value={formState.name}
                        type='email'
                        sx={{
                            '&': {
                                width: '100%'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px', 
                                color: 'black',
                                backgroundColor: 'white',
                                width: '100%',
                                border: '2px #4137AF solid !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '16px 14px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important'
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important',
                            },
                            '& .Mui-focused.MuiInputLabel-root': {
                                color: '#FE754B !important',
                            },
                            '& .MuiInputAdornment-root img': {
                                paddingLeft: '8px'
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={loginImage} alt="login logo" />
                              </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        placeholder={t("password")}
                        name="password"
                        onChange={handleChange} 
                        value={formState.password}
                        type='password'
                        sx={{
                            '&': {
                                width: '100%',
                                marginTop: '20px'
                            },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '40px', 
                                color: 'black',
                                backgroundColor: 'white',
                                width: '100%',
                                border: '2px #4137AF solid !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '16px 14px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                backgroundColor: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important'
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white !important',
                            },
                            '& .Mui-focused.MuiInputLabel-root': {
                                color: '#FE754B !important',
                            },
                            '& .MuiInputAdornment-root img': {
                                paddingLeft: '8px'
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passwordImage} alt="password logo" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <div 
                                    onClick={() => handleOnForgot()}
                                    className={classnames(styles.forgotQuestion)}
                                >{t("forgotQuestion")}</div>
                              </InputAdornment>
                            )
                          }}
                    />

                    <div className='mt-4 mb-4'>
                        <VioletButton
                            title={t("logIn")}
                            type='submit'
                        />
                    </div>
                </form>
                <div className={classnames('col-12')}>
                        <div className={classnames(styles.registerQuestion)}>
                            <div className={classnames('col-1', 'col-md-0', 'col-sm-0', 'col-md-0', 'col-lg-0', 'col-xl-0')}></div>
                            <div className={classnames(styles.whiteLine, 'col-xl-1', 'col-lg-1', 'col-md-1', 'col-sm-1', 'col')}></div>
                            <div className={classnames(styles.headingLines, 'col-xl-2', 'col-lg-3', 'col-md-4', 'col-sm-4', 'col-6')}>{t("registerQuestion")}</div>
                            <div className={classnames(styles.whiteLine, 'col-xl-1', 'col-lg-1', 'col-md-1', 'col-sm-1', 'col')}></div>
                            <div className={classnames('col-1', 'col-md-0', 'col-sm-0', 'col-md-0', 'col-lg-0', 'col-xl-0')}></div>
                        </div>
                        <div className={classnames(styles.joinContainer, 'col-12', 'mt-xl-5', 'mt-lg-5', 'mt-md-4', 'mt-sm-4', 'mt-4')}>
                            <div className={classnames('col-xl-3', 'col-lg-3', 'col-md-6', 'col-sm-8', 'col-11')}>
                                <Link
                                    to={{ pathname: `/${i18n.language}/auth/registration` }} 
                                    className={classnames(styles.redirectoRegistration)}
                                >
                                    <b>{t('join')}</b>
                                </Link>
                                {t('toTakeAdvantage')}
                            </div>
                        </div>
                        <div className={classnames(styles.termsContainer, 'col-12', 'mt-xl-5', 'mt-lg-5', 'mt-md-4', 'mt-sm-4', 'mt-4')}>
                            <div className={classnames('col-xl-4', 'col-lg-5', 'col-md-6', 'col-sm-8', 'col-11')}>
                                {t('byContinueYouAgree')}<a className={styles.linkTerms} onClick={handleShowPrivacyPolicy}>{(t('privacy'))}</a> 
                            </div>
                        </div>
                </div>
            </div>
            <Privacy open={isPrivacyPolicyOpen} onClose={handlePrivacyPolicyClose}></Privacy>
        </div>

    );
};

export default LoginPage;