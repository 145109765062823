import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as CustomArrowIcon } from '../../../assets/images/common/orange-dropdown.svg'
import { useTheme } from '@mui/material/styles';
import { StyledTextField, StyledGrid, StyledSelect, StyledMenuItem, StyledTextHeading } from '../CustomStyles';


const AdditionalInfoForm = ({ formData, setFormData, formErrors, setFormErrors }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const ParkingOptions = [
    { id: 1, name: 'cityPaidParking' },
    { id: 2, name: 'cityFreeParking' },
    { id: 3, name: 'privatePaidParking' },
    { id: 4, name: 'privateFreeParking' },
    { id: 5, name: 'none' },
  ];

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
    setFormData({ ...formData, [name]: newValue });
  }, [formData, formErrors]);;

  const handleParkingChange = (e) => {
    const selectedParking = e.target.value;
    setFormErrors({ ...formErrors, parkingType: null });
    setFormData({
      ...formData,
      parkingType: selectedParking,
    });
  }
  
  const renderValueParkingType = (value) => {
    if (!value) {
      return <span style={{ color: 'white' }}>{t("parking")+"*"}</span>;
    }
    let categoryName = ParkingOptions.find(category => category.id === value)?.name;
    return <span>{t(categoryName)}</span>;
  };

  return (
    <div>
      <Grid 
          container 
          spacing={2}
          sx={{
              marginTop: '16px',
              '.MuiGrid-item' :{
                  paddingTop: 0
              }
          }}
          >

          <Grid item xs={12}>
            <StyledTextHeading>{t("additionalInfo")}</StyledTextHeading>
          </Grid>

          <StyledGrid item xs={12} sm={6}>
            <StyledTextField
              value={formData.menuUrl}
              placeholder={t("menuUrl")}
              name="menuUrl"
              type="text"
              helperText={"\u00A0"}
              onChange={handleInputChange}
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6}>
              <StyledTextField
                  value={formData.site}
                  placeholder={t("website")}
                  name="site"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={4}>
              <StyledTextField
                  value={formData.facebook}
                  placeholder={"Facebook"}
                  name="facebook"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={4}>
              <StyledTextField
                  value={formData.instagram}
                  placeholder={"Instagram"}
                  name="instagram"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={4}>
              <StyledTextField
                  value={formData.telegram}
                  placeholder={"Telegram"}
                  name="telegram"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6}>
              <StyledTextField
                  value={formData.wifiName}
                  placeholder={"Wi-Fi"}
                  name="wifiName"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6}>
              <StyledTextField
                  value={formData.wifiPassword}
                  placeholder={"Wi-Fi "+t("password").toLocaleLowerCase()}
                  name="wifiPassword"
                  type="text"
                  helperText={"\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6}>
              <StyledTextField
                  value={formData.phone}
                  placeholder={t("placePhone")+"*"}
                  name="phone"
                  type="number"
                  error={!!formErrors.phone}
                  helperText={formErrors.phone ? formErrors.phone : "\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6}>
              <StyledTextField
                  value={formData.email}
                  placeholder={t("placeEmail")+"*"}
                  name="email"
                  type="text"
                  error={!!formErrors.email}
                  helperText={formErrors.email ? formErrors.email : "\u00A0"}
                  onChange={handleInputChange}
              />
          </StyledGrid>
        <Grid item xs={12} sm={4}>
            <StyledSelect
              theme={theme}
              renderValue={renderValueParkingType}
              value={formData.parkingType}
              onChange={handleParkingChange}
              displayEmpty
              IconComponent={CustomArrowIcon}
              MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: 'transparent',
                    },
                  },
                  sx: {
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'inherit',  
                      }
                    },
                    '& .MuiMenuItem-root.Mui-selected.Mui-focusVisible': {
                      backgroundColor: 'transparent'
                    },
                    marginTop: '10px',
                    '& .MuiList-root': {
                      backgroundColor: '#1A2532',
                      border: '1px solid #FE754B',
                      borderRadius: '8px',
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#FE754B',
                        color: 'white', 
                      } 
                    },
                  },
                }}
              >
              {Object.entries(ParkingOptions).map(([key, value]) => (
                  <StyledMenuItem  key={`ParkingOptions-${value.id}`} value={value.id} >
                      {t(value.name)}
                      {formData.parkingType === value.id && (
                          <CheckIcon sx={{ pl: '10px', ml: 'auto', color: '#FE754B' }} />
                      )}
                  </StyledMenuItem >
              ))}
          </StyledSelect>
          <FormHelperText
            sx={{
                color: '#FFC0CB !important',
                marginLeft: '14px'
            }}
            >{formErrors.parkingType ? formErrors.parkingType : "\u00A0"}
          </FormHelperText>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(AdditionalInfoForm);