import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TitlesData from '../../../data/TitlesData';
import styled from 'styled-components';
import { Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledTextField = React.memo(styled(TextField)({
    '&&': {
        width: '100%',

        '& .MuiOutlinedInput-root': {
            borderRadius: '40px',
            color: 'black',
            backgroundColor: 'white',
            width: '100%',
            border: '2px #4137AF solid !important',
        },
       ' & .MuiOutlinedInput-input': {
            padding: '16px 28px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent'
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white!important'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
           borderColor: 'white!important'
        },
        '& .Mui-focused.MuiInputLabel-root': {
            color: '#FE754B !important',
        },
        '& .MuiInputAdornment-root img': {
            paddingLeft: '8px'
        },
        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& .MuiFormHelperText-root': {
        color: '#FFC0CB',
      }
    }  
}))

const StyledGrid = React.memo(styled(Grid)({
        
}))

const StyledTextHeading = React.memo(styled(Typography)({
    color: "white",
    paddingBottom: '20px',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 'bold!important',
    letterSpacing: '-0.24px'    
}))

const ContactInfoForm = ({ formData, setFormData, formErrors, setFormErrors }) => {
    const { t, i18n } = useTranslation();
    const {Titles} = TitlesData()
    const theme = useTheme();
  
    const handleInputChange = useCallback((e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === 'checkbox' ? checked : value;
      if (formErrors[name]) {
        setFormErrors({ ...formErrors, [name]: '' });
      }
      setFormData({ ...formData, [name]: newValue });
    }, [formData, formErrors]);;
  
    return (
      <div>
        <Grid 
            container 
            spacing={2}
            sx={{
                marginTop: '16px',
                '.MuiGrid-item' :{
                    paddingTop: 0
                }
            }}
            >
  
            <Grid item xs={12}>
                <StyledTextHeading>{t("contactInfo")}</StyledTextHeading>
            </Grid>
  
            <Grid item xs={12} sm={6}>
                <StyledTextField
                    value={formData.name}
                    placeholder={t("name") + "*"}
                    name="name"
                    type='text'
                    onChange={handleInputChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name ? formErrors.name : "\u00A0"}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <StyledTextField
                    value={formData.surname}
                    placeholder={t("surname") + "*"}
                    name="surname"
                    type='text'
                    onChange={handleInputChange}
                    error={!!formErrors.surname}
                    helperText={formErrors.surname ? formErrors.surname : "\u00A0"}
                />
            </Grid>
            <StyledGrid item xs={12} sm={6}>
                <StyledTextField
                    value={formData.email}
                    placeholder={t("email") + "*"}
                    name="email"
                    type='text'
                    error={!!formErrors.email}
                    helperText={formErrors.email ? formErrors.email : "\u00A0"}
                    onChange={handleInputChange}
                />
            </StyledGrid> 
            <StyledGrid item xs={12} sm={6}>
                <StyledTextField
                    value={formData.phone}
                    placeholder={t("phoneNumber") + "*"}
                    name="phone"
                    type="number"
                    error={!!formErrors.phone}
                    helperText={formErrors.phone ? formErrors.phone : "\u00A0"}
                    onChange={handleInputChange}
                />
            </StyledGrid> 
        </Grid>
      </div>
    );
  };

  export default React.memo(ContactInfoForm);