import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import styles from './CategoryCard.module.scss'
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function CategoryCard({ title, img, uri }) {
    const { t, i18n } = useTranslation();

    return (
        <div className={classnames(styles.cardContainer)}>
            <Link to={"/" + i18n.language + uri} style={{ textDecoration: 'none' }}>
                <Card className={classnames(styles.categoryCard)}>
                    <CardMedia
                        component="img"
                        alt="img"
                        height="100%"
                        image={img}
                        title="img"
                        loading="lazy"
                    />
                    <CardContent
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            bottom: '0',
                            right: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            
                        }}
                    >
                        <div className={classnames(styles.categroyCardTitle)}>
                            {title}
                        </div>
                    </CardContent>
                </Card>
            </Link>
        </div>
    );
}

export default React.memo(CategoryCard);
