import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const secretKey = "wegotonight4makaki";

const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getAuthToken = () => {
  const token = Cookies.get('auth_token');
  if (!token) return null;
  try {
    return decrypt(token);
  } catch (error) {
    console.error("Error decrypting token:", error);
    const encryptedToken = encrypt(token);
    const options = Cookies.get('cookie-consent') === "accepted" ? { expires: 365 } : {};
    Cookies.set('auth_token', encryptedToken, options);
    return token;
  }
};

export const isEmailActivated = () => {
  const activated = Cookies.get('email_activated');
  return activated ? decrypt(activated) === 'true' : false;
};

export const setAuthHeader = (token) => {
  const encryptedToken = encrypt(token);
  const options = Cookies.get('cookie-consent') === "accepted" ? { expires: 365 } : {};
  Cookies.set('auth_token', encryptedToken, options);
};

export const setIsEmailActivated = (isActivated) => {
  const encryptedIsActivated = encrypt(isActivated.toString());
  const options = Cookies.get('cookie-consent') === "accepted" ? { expires: 365 } : {};
  Cookies.set('email_activated', encryptedIsActivated, options);
};

export const setUsername = (username) => {
  const encryptedUsername = encrypt(username);
  const options = Cookies.get('cookie-consent') === "accepted" ? { expires: 365 } : {};
  Cookies.set('username', encryptedUsername, options);
};

export const getUsername = () => {
  const username = Cookies.get('username');
  return username ? decrypt(username) : null;
};

export const logout = () => {
  Cookies.remove('auth_token');
  Cookies.remove('username');
  Cookies.remove('email_activated');
  Cookies.remove('user_role');
};

export const toggleCookies = () => {
  if (Cookies.get('cookie-consent') === 'accepted') {
    Cookies.set('cookie-consent', 'declined');
    Cookies.set('analytic-cookies', encrypt('false'));
  } else {
    Cookies.set('cookie-consent', 'accepted', { expires: 365 });
    Cookies.set('analytic-cookies', encrypt('true'), { expires: 365 });
  }
};

export const isCookieAccepted = () => {
  return Cookies.get('cookie-consent') === 'accepted';
};

export const setLanguageCookie = (language) => {
  const encryptedLanguage = encrypt(language.toLowerCase());
  const options = isCookieAccepted() ? { expires: 365 } : {};
  Cookies.set('language', encryptedLanguage, options);
};

export const getCookieLanguage = () => {
    const language = Cookies.get('language');
    return language ? decrypt(language) : null;
};

export const acceptCookie = () => {
  Cookies.set('cookie-consent', 'accepted', { expires: 365 });
  Cookies.set('analytic-cookies', encrypt('true'), { expires: 365 });
};

export const declineCookie = () => {
  Cookies.set('cookie-consent', 'declined', { expires: 365 });
};

export const acceptPartialCookie = (analyticsAccepted) => {
  Cookies.set('analytic-cookies', encrypt(`${analyticsAccepted}`), { expires: 365 });
  Cookies.set('cookie-consent', 'accepted', { expires: 365 });
};

export const setUserRole = (role) => {
  const encryptedRole = encrypt(role);
  const options = isCookieAccepted() ? { expires: 365 } : {};
  Cookies.set('user_role', encryptedRole, options);
};

export const isUserAdmin = () => {
  const role = Cookies.get('user_role');
  return role ? decrypt(role) === 'ADMIN' : false;
};

export const cookieContestAppeared = () => {
    return !Cookies.get('cookie-consent')
}

export const isAnalyticsAccepted = () => {
  const analytics = Cookies.get('analytic-cookies');
  const isAnalyticsAccepted = analytics ? decrypt(analytics) : null;
  return isAnalyticsAccepted == 'accepted'
}