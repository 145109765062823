import classnames from 'classnames';
import styles from './AllEventsPage.module.scss'
import { useTranslation } from 'react-i18next';
import { getRequest } from '../../services/fetch/AxiosHelper';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TitlesData from '../../data/TitlesData';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventCard from '../../components/mui/eventCard/EventCard';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonUtils } from '../../utils/CommonUtils';

const AllEventsPage = () => {
  const {Titles} = TitlesData()
  const { t, i18n } = useTranslation();

  const { formatDateAndStatus } = CommonUtils();
  
  const [eventsList, setEventsList] = useState();
  const [emptyWeek, setEmptyWeek] = useState(true);
  const [emptyMonth, setEmptyMonth] = useState(true);
  const [emptyOngoing, setEmptyOngoing] = useState(true);
  const [emptyFuture, setEmptyFuture] = useState(true);
  const [openOngoing, setOpenOngoing] = useState(true);
  const [openWeek, setOpenWeek] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openFuture, setOpenFuture] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const linksData = [
    { path: "/", label: "home" },
    { path: "/events/all", label: "eventList" }
  ];

  const parfeInfoFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      openStatuses: searchParams.get('openStatuses') ? searchParams.get('openStatuses').split(',') : [],
      scrollY: Number(searchParams.get('scrollY') || 0),
    };
  }

  useEffect(() => {
    getRequest(`events/all?language=${i18n.language}`)
            .then((response) => response.data)
            .then((data) => {
                setEventsList(data)
                setEmptyWeek(data?.thisWeekEvents.length == 0)  
                setEmptyMonth(data?.thisMonthEvents.length == 0)  
                setEmptyOngoing(data?.ongoingEvents.length == 0)  
                setEmptyFuture(data?.futureEvents.length == 0)  
                restoreOpenStatuses()
            })
            .catch((error) => {
                console.log(error)
            });
}, [i18n.language]);

const updateUrlWithOpenStatuses = () => {
    const openStatuses = {
      openOngoing,
      openWeek,
      openMonth,
      openFuture
    };
  
    const openStatusesString = Object.entries(openStatuses)
      .filter(([key, value]) => value)
      .map(([key]) => key)
      .join(',');
  
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("scrollY", window.scrollY);
    searchParams.set('openStatuses', openStatusesString);
    navigate(`${location.pathname}?${searchParams}`, { replace: true });
};


const restoreOpenStatuses = () => {
    const searchParams = parfeInfoFromUrl()
    const openStatusesFromUrl = searchParams.openStatuses
    const scrollY = searchParams.scrollY

    const searchParamsUrl = new URLSearchParams(window.location.search);
    searchParamsUrl.delete("scrollY");
    searchParamsUrl.delete("openStatuses");
    const parameters = searchParamsUrl.toString();
    navigate(`?${parameters}`, { replace: true });

    const isAnyAccordionOpen = openStatusesFromUrl.length > 0;
    setOpenOngoing(isAnyAccordionOpen ? openStatusesFromUrl.includes('openOngoing') : true);
    setOpenWeek(openStatusesFromUrl.includes('openWeek'));
    setOpenMonth(openStatusesFromUrl.includes('openMonth'));
    setOpenFuture(openStatusesFromUrl.includes('openFuture'));

    setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: scrollY ? scrollY : 0,
          behavior: 'smooth'
        });
      }, 400);

};

const accordionStyle = {
    background: 'transparent', 
    boxShadow: 'none',
    border: 'none',
    '&:before': {
      display: 'none!important'
    },
};

const summaryStyle = {
    padding: 0,
    marging: 0
};

const detailsStyle = {
    padding: 0,
};

return (
    <div className={classnames(styles.mainContent)}>
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{Titles.eventList}</title>
            <meta name="description" content={t("allEventsMetaDesc")} />
            <meta name="keywords" content={t("allEventsMetaTags")} />
            <link rel="canonical" href="https://wegotonight.com/en/events/all" />
            <meta property="og:title" content={t("allEventsTitle")} />
            <meta property="og:description" content={t("allEventsMetaDesc")} />
            <meta property="og:url" content="https://wegotonight.com/en/events/all" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://wegotonight.com/img/ogImage.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={t("allEventsTitle")}/>
            <meta name="twitter:description" content={t("allEventsMetaDesc")} />
            <meta name="twitter:image" content="https://wegotonight.com/img/ogImage.png" />
        </Helmet>

        <div className={classnames('row', styles.row, styles.timePeriod)}>
            <div className={classnames('mb-5')}>
                <DynamicBreadcrumbs links={linksData} />
            </div>
            <Accordion
                  expanded={openOngoing}
                  onChange={() => setOpenOngoing(!openOngoing)}
                  style={accordionStyle}
                  sx={{
                    '&:before': {
                      display: 'none!important'
                    },
                  }}
                >
                  <AccordionSummary
                    style={summaryStyle}
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        justifyContent: 'flex-start',
                        width: 'auto',
                        '.MuiAccordionSummary-content': {
                            margin: 0,
                            flexGrow: 0
                        }
                    }}
                  >
                    <div className={classnames(styles.categoryWgtChoiceHeading)}>
                        <div className={classnames('d-flex', 'justify-content-left', 'align-items-center')}>
                            <h2>{t("ongoing").toUpperCase()}</h2>
                            {openOngoing ? <ExpandLess style={{ color: '#FE754B' }}/> : <ExpandMore style={{ color: '#FE754B' }}/>}
                        </div>
                        <Divider
                            variant="middle"
                            sx={{ borderBottomWidth: '4px', background: '#FE754B', opacity: '1' }}
                            className={classnames(styles.dividingLine, openOngoing ? styles.expandedDivider : styles.collapsedDivider)}
                        />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={detailsStyle}
                  >
                    <div className={classnames('row', styles.row, 'd-flex', 'justify-content-start', styles.listOfEventBlocks)}>
                    {
                    eventsList?.ongoingEvents.map((event) => {
                        return (            
                            <div key={event.id} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                                <EventCard
                                    title={event.eventName} 
                                    description={event.eventDescription} 
                                    logo={event.eventImage} 
                                    uri={'/events/' + event.uri}
                                    isFavourite={event.isFavourite}
                                    date={formatDateAndStatus(event.startDate, event.endDate)}
                                    address={event.address}
                                    eventSpecifications={event.eventSpecifications}
                                    breadcrumbs={linksData}
                                    isWgtChoice={event.isWgtChoice}
                                    onNavigate={() => {
                                        updateUrlWithOpenStatuses()
                                    }}
                                />                                            
                            </div> 
                        );
                    })
                    }
                    { emptyOngoing && <div className={classnames(styles.emptyContent)}> {t("nothingExists")} </div> }
                    </div>
                  </AccordionDetails>
                </Accordion>
        </div>

        <div className={classnames('row', styles.row, styles.timePeriod)}>
            <Accordion
                  expanded={openWeek}
                  onChange={() => setOpenWeek(!openWeek)}
                  style={accordionStyle}
                >
                  <AccordionSummary
                    style={summaryStyle}
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        justifyContent: 'flex-start',
                        width: 'auto',
                        '.MuiAccordionSummary-content': {
                            margin: 0,
                            flexGrow: 0
                        }
                    }}
                  >
                    <div className={classnames(styles.categoryWgtChoiceHeading)}>
                        <div className={classnames('d-flex', 'justify-content-left', 'align-items-center')}>
                            <h2>{t("thisWeek").toUpperCase()}</h2>
                            {openWeek ? <ExpandLess style={{ color: '#FE754B' }}/> : <ExpandMore style={{ color: '#FE754B' }}/>}
                        </div>
                        <Divider
                            variant="middle"
                            sx={{ borderBottomWidth: '4px', background: '#FE754B', opacity: '1' }}
                            className={classnames(styles.dividingLine, openWeek ? styles.expandedDivider : styles.collapsedDivider)}
                        />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={detailsStyle}
                  >
                    <div className={classnames('row', styles.row, 'd-flex', 'justify-content-start', styles.listOfEventBlocks)}>
                    {
                    eventsList?.thisWeekEvents.map((event) => {
                        return (            
                            <div key={event.id} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                                <EventCard
                                    title={event.eventName} 
                                    description={event.eventDescription} 
                                    logo={event.eventImage} 
                                    uri={'/events/' + event.uri}
                                    isFavourite={event.isFavourite}
                                    date={formatDateAndStatus(event.startDate, event.endDate)}
                                    address={event.address}
                                    eventSpecifications={event.eventSpecifications}
                                    breadcrumbs={linksData}
                                    onNavigate={() => {
                                        updateUrlWithOpenStatuses()
                                    }}
                                />                                            
                            </div> 
                        );
                    })
                    }
                    { emptyWeek && <div className={classnames(styles.emptyContent)}> {t("nothingExists")} </div> }
                    </div>
                  </AccordionDetails>
            </Accordion>
        </div>   
    
        <div className={classnames('row', styles.row, styles.timePeriod)} >
            <Accordion
                  expanded={openMonth}
                  onChange={() => setOpenMonth(!openMonth)}
                  style={accordionStyle}
                >
                  <AccordionSummary
                    style={summaryStyle}
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        justifyContent: 'flex-start',
                        width: 'auto',
                        '.MuiAccordionSummary-content': {
                            margin: 0,
                            flexGrow: 0
                        }
                    }}
                  >
                    <div className={classnames(styles.categoryWgtChoiceHeading)}>
                        <div className={classnames('d-flex', 'justify-content-left', 'align-items-center')}>
                            <h2>{t("thisMonth").toUpperCase()}</h2>
                            {openMonth ? <ExpandLess style={{ color: '#FE754B' }}/> : <ExpandMore style={{ color: '#FE754B' }}/>}
                        </div>
                        <Divider
                            variant="middle"
                            sx={{ borderBottomWidth: '4px', background: '#FE754B', opacity: '1' }}
                            className={classnames(styles.dividingLine, openMonth ? styles.expandedDivider : styles.collapsedDivider)}
                        />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={detailsStyle}
                  >
                    <div className={classnames('row', styles.row, 'd-flex', 'justify-content-start', styles.listOfEventBlocks)}>
                    {
                    eventsList?.thisMonthEvents.map((event) => {
                        return (            
                            <div key={event.id} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                                <EventCard
                                    title={event.eventName} 
                                    description={event.eventDescription} 
                                    logo={event.eventImage} 
                                    uri={'/events/' + event.uri}
                                    isFavourite={event.isFavourite}
                                    date={formatDateAndStatus(event.startDate, event.endDate)}
                                    address={event.address}
                                    eventSpecifications={event.eventSpecifications}
                                    breadcrumbs={linksData}
                                    onNavigate={() => {
                                        updateUrlWithOpenStatuses()
                                    }}
                                />                                            
                            </div> 
                        );
                    })
                    }
                    { emptyMonth && <div className={classnames(styles.emptyContent)}> {t("nothingExists")} </div> }
                    </div>
                  </AccordionDetails>
            </Accordion>
        </div> 
        <div className={classnames('row', styles.row, styles.timePeriod)} >
            <Accordion
                  expanded={openFuture}
                  onChange={() => setOpenFuture(!openFuture)}
                  style={accordionStyle}
                >
                  <AccordionSummary
                    style={summaryStyle}
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        justifyContent: 'flex-start',
                        width: 'auto',
                        '.MuiAccordionSummary-content': {
                            margin: 0,
                            flexGrow: 0
                        }
                    }}
                  >
                    <div className={classnames(styles.categoryWgtChoiceHeading)}>
                        <div className={classnames('d-flex', 'justify-content-left', 'align-items-center')}>
                            <h2>{t("futureEvents").toUpperCase()}</h2>
                            {openFuture ? <ExpandLess style={{ color: '#FE754B' }}/> : <ExpandMore style={{ color: '#FE754B' }}/>}
                        </div>
                        <Divider
                            variant="middle"
                            sx={{ borderBottomWidth: '4px', background: '#FE754B', opacity: '1' }}
                            className={classnames(styles.dividingLine, openFuture ? styles.expandedDivider : styles.collapsedDivider)}
                        />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={detailsStyle}
                  >
                    <div className={classnames('row', styles.row, 'd-flex', 'justify-content-start', styles.listOfEventBlocks)}>
                    {
                    eventsList?.futureEvents.map((event) => {
                        return (            
                            <div key={event.id} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                                <EventCard
                                    title={event.eventName} 
                                    description={event.eventDescription} 
                                    logo={event.eventImage} 
                                    uri={'/events/' + event.uri}
                                    isFavourite={event.isFavourite}
                                    date={formatDateAndStatus(event.startDate, event.endDate)}
                                    address={event.address}
                                    eventSpecifications={event.eventSpecifications}
                                    breadcrumbs={linksData}
                                    onNavigate={() => {
                                        updateUrlWithOpenStatuses()
                                    }}
                                />                                            
                            </div> 
                        );
                    })
                    }
                    { emptyFuture && <div className={classnames(styles.emptyContent)}> {t("nothingExists")} </div> }
                    </div>
                  </AccordionDetails>
            </Accordion>
        </div> 
    </div>
  );
};

export default AllEventsPage;