import React, { useState, useEffect } from 'react';
import './BackToTopButton.scss';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  let animationFrameId = null;

const handleScroll = () => {
  if (animationFrameId) {
    window.cancelAnimationFrame(animationFrameId);
  }

  animationFrameId = window.requestAnimationFrame(() => {
    const scrolled = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const percentage = (scrolled / (documentHeight - windowHeight)) * 100;

    setShowButton(percentage > 5);
  });
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
    if (animationFrameId) {
      window.cancelAnimationFrame(animationFrameId);
    }
  };
}, []);

  return (
    <div className={`back-to-top-button ${showButton ? 'visible' : ''}`} onClick={scrollToTop}>
        <div className="iconsContainer">
            <Fab style={{ backgroundColor: '#FE754B' }} className="arrow-up">
                <KeyboardArrowUpIcon style={{color: 'white'}} />
            </Fab>
        </div>
      
    </div>
  );
};

export default React.memo(BackToTopButton);