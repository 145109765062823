import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid } from '@mui/material';
import { CategoriesData } from '../../../data/CategoriesData';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as CustomArrowIcon } from '../../../assets/images/common/orange-dropdown.svg'
import { useTheme } from '@mui/material/styles';
import { StyledTextField, StyledGrid, StyledSelect, StyledMenuItem, StyledTextHeading } from '../CustomStyles';
import Reminder from '../../../components/mui/reminder/Reminder';


const PlaceInfoForm = ({ formData, setFormData, formErrors, setFormErrors }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { Categories, SubCategories, CategoriesTranslations, SubCategoriesTranslations } = CategoriesData();
  
    const handleInputChange = useCallback((e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === 'checkbox' ? checked : value;
      if (formErrors[name]) {
        setFormErrors({ ...formErrors, [name]: '' });
      }
      setFormData({ ...formData, [name]: newValue });
    }, [formData, formErrors]);;
  
    const renderValueCategory = (value) => {
      if (!value) {
        return <span style={{ color: 'white' }}>{t("category")+"*"}</span>;
      }
      let categoryName = Categories.find(category => category.id === value)?.name;
      return <span>{CategoriesTranslations[categoryName]}</span>;
    };
  
    const renderValueSubCategory = (value) => {
      if (!value) {
        return <span style={{ color: 'white' }}>{t("subcategory")+"*"}</span>;
      }
      let subcategoryName = SubCategories.find(subcategory => subcategory.id === value)?.name;
      return <span>{SubCategoriesTranslations[subcategoryName]}</span>;
    };
  
    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setFormErrors({ ...formErrors, category: null });
        setFormData({
          ...formData,
          category: selectedCategory,
          subcategory: '',
        });
    };
  
    const handleSubCategoryChange = (e) => {
      const selectedSubCategory = e.target.value;
      setFormErrors({ ...formErrors, subcategory: null });
      setFormData({
        ...formData,
        subcategory: selectedSubCategory,
      });
    };
  
    const getSubcategoriesForCategory = () => {
      if (formData.category) {
        const selectedCategory = Categories.find(
          (category) => category.id === formData.category
        );
        if (selectedCategory) {
          return SubCategories.filter(
            (subcategory) => subcategory.parent === selectedCategory.id
          );
        }
      }
      return [];
    };
  
    return (
          <div>
            <Grid 
                container 
                spacing={2}
                sx={{
                    marginTop: '16px',
                    justifyContent: 'center',
                    '.MuiGrid-item' :{
                        paddingTop: 0
                    }
                }}
                >

                <Reminder
                  content={t("placeInfoFormReminder")}
                />
  
                <Grid item xs={12}>
                    <StyledTextHeading>{t("infoAboutPlace")}</StyledTextHeading>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledSelect
                      theme={theme}
                      renderValue={renderValueCategory}
                      value={formData.category}
                      onChange={handleCategoryChange}
                      displayEmpty
                      IconComponent={CustomArrowIcon}
                      sx={{
                        marginTop: '0!important'
                      }}
                      MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: 'transparent',
                            },
                          },
                          sx: {
                            '& .MuiMenuItem-root.Mui-selected': {
                              backgroundColor: 'transparent',
                              '&:hover': {
                                backgroundColor: 'inherit',  
                              }
                            },
                            '& .MuiMenuItem-root.Mui-selected.Mui-focusVisible': {
                              backgroundColor: 'transparent'
                            },
                            marginTop: '10px',
                            '& .MuiList-root': {
                              backgroundColor: '#1A2532',
                              border: '1px solid #FE754B',
                              borderRadius: '8px',
                              '& .MuiMenuItem-root:hover': {
                                backgroundColor: '#FE754B',
                                color: 'white', 
                              } 
                            },
                          },
                        }}
                      >
                      {Object.entries(Categories).map(([key, value]) => (
                          <StyledMenuItem  key={`Categories-${key}`} value={value.id} >
                              {CategoriesTranslations[value.name]}
                              {formData.category === value.id && (
                                  <CheckIcon sx={{ pl: '10px', ml: 'auto', color: '#FE754B' }} />
                              )}
                          </StyledMenuItem >
                      ))}
                  </StyledSelect>
                  <FormHelperText
                    sx={{
                        color: '#FFC0CB !important',
                        marginLeft: '14px'
                    }}
                    >{formErrors.category ? formErrors.category : "\u00A0"}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledSelect
                      theme={theme}
                      renderValue={renderValueSubCategory}
                      value={formData.subcategory}
                      onChange={handleSubCategoryChange}
                      displayEmpty
                      IconComponent={CustomArrowIcon}
                      MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: 'transparent',
                            },
                          },
                          sx: {
                            '& .MuiMenuItem-root.Mui-selected': {
                              backgroundColor: 'transparent',
                              '&:hover': {
                                backgroundColor: 'inherit',  
                              }
                            },
                            '& .MuiMenuItem-root.Mui-selected.Mui-focusVisible': {
                              backgroundColor: 'transparent'
                            },
                            marginTop: '10px',
                            '& .MuiList-root': {
                              backgroundColor: '#1A2532',
                              border: '1px solid #FE754B',
                              borderRadius: '8px',
                              '& .MuiMenuItem-root:hover': {
                                backgroundColor: '#FE754B',
                                color: 'white', 
                              } 
                            },
                          },
                        }}
                      >
                      {getSubcategoriesForCategory().map((subcategory) => (
                          <StyledMenuItem  key={`Subcategories-${subcategory.id}`} value={subcategory.id} >
                            {SubCategoriesTranslations[subcategory.name]}
                            {formData.subcategory === subcategory.id && (
                                <CheckIcon sx={{ pl: '10px', ml: 'auto', color: '#FE754B' }} />
                            )}
                          </StyledMenuItem >
                      ))}
                  </StyledSelect>
                  <FormHelperText
                    sx={{
                        color: '#FFC0CB !important',
                        marginLeft: '14px'
                    }}
                    >{formErrors.subcategory ? formErrors.subcategory : "\u00A0"}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.name}
                        placeholder={t("placeName") + "*"}
                        name="name"
                        type='text'
                        error={!!formErrors.name}
                        helperText={formErrors.name ? formErrors.name : "\u00A0"}
                        onChange={handleInputChange}
                        sx={{marginTop: '25px'}}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.address}
                        placeholder={t("placeAddress") + "*"}
                        name="address"
                        type='text'
                        error={!!formErrors.address}
                        helperText={formErrors.address ? formErrors.address : "\u00A0"}
                        onChange={handleInputChange}
                        sx={{marginTop: '25px'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        value={formData.descriptionLv}
                        placeholder={t("placeDescLv") + "*"}
                        name="descriptionLv"
                        type='text'
                        multiline
                        rows={3}
                        error={!!formErrors.descriptionEn}
                        helperText={formErrors.descriptionEn ? formErrors.descriptionEn : "\u00A0"}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        value={formData.descriptionRu}
                        placeholder={t("placeDescRu") + "*"}
                        name="descriptionRu"
                        type='text'
                        multiline
                        rows={3}
                        error={!!formErrors.descriptionEn}
                        helperText={formErrors.descriptionEn ? formErrors.descriptionEn : "\u00A0"}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        value={formData.descriptionEn}
                        placeholder={t("placeDescEn") + "*"}
                        name="descriptionEn"
                        type='text'
                        multiline
                        rows={3}
                        error={!!formErrors.descriptionEn}
                        helperText={formErrors.descriptionEn ? formErrors.descriptionEn : "\u00A0"}
                        onChange={handleInputChange}
                    />
                </Grid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.mainImageUrl}
                        placeholder={t("mainImage") + "*"}
                        name="mainImageUrl"
                        type="text"
                        error={!!formErrors.mainImageUrl}
                        helperText={formErrors.mainImageUrl ? formErrors.mainImageUrl : "\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.mainImagePhoneUrl}
                        placeholder={t("mainImagePhone") + "*"}
                        name="mainImagePhoneUrl"
                        type="text"
                        error={!!formErrors.mainImagePhoneUrl}
                        helperText={formErrors.mainImagePhoneUrl ? formErrors.mainImagePhoneUrl : "\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.subImg1}
                        placeholder={t("subImg") + " 1"}
                        name="subImg1"
                        type="text"
                        helperText={"\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.subImg2}
                        placeholder={t("subImg") + " 2"}
                        name="subImg2"
                        type="text"
                        helperText={"\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.subImg3}
                        placeholder={t("subImg") + " 3"}
                        name="subImg3"
                        type="text"
                        helperText={"\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <StyledTextField
                        value={formData.subImg4}
                        placeholder={t("subImg") + " 4"}
                        name="subImg4"
                        type="text"
                        helperText={"\u00A0"}
                        onChange={handleInputChange}
                    />
                </StyledGrid>
            </Grid>
          </div>
    );
  };

  export default React.memo(PlaceInfoForm);