import { useTranslation } from 'react-i18next';
import parties from '../assets/images/categories/parties.svg';
import burger from '../assets/images/categories/burger.svg';
import food from '../assets/images/categories/food.svg';
import drink from '../assets/images/categories/drink.svg';
import coffe from '../assets/images/categories/coffe.svg';
import entertainment from '../assets/images/categories/entertainment.svg';
import partiesBlack from '../assets/images/categories/partiesBlack.svg';
import burgerBlack from '../assets/images/categories/burgerBlack.svg';
import foodBlack from '../assets/images/categories/foodBlack.svg';
import coffeBlack from '../assets/images/categories/coffeBlack.svg';
import drinkBlack from '../assets/images/categories/drinkBlack.svg';
import entertainmentBlack from '../assets/images/categories/entertainmentBlack.svg';

const CategoriesData = () => {

const { t, i18n } = useTranslation();

const CategoriesImages = {
    RESTAURANTS: food,
    CLUBS: parties,
    BARS: drink,
    CAFES: coffe,
    FAST_FOOD: burger,
    ENTERTAINMENT: entertainment
  };
  
  const CategoriesImagesBlack = {
    RESTAURANTS: foodBlack,
    CLUBS: partiesBlack,
    BARS: drinkBlack,
    CAFES: coffeBlack,
    FAST_FOOD: burgerBlack,
    ENTERTAINMENT: entertainmentBlack
  };

  const CategoriesTranslations = {
    RESTAURANTS: t("restaurants"),
    CLUBS: t("clubs"),
    BARS: t("bars"),
    CAFES: t("cafes"),
    FAST_FOOD: t("fastFood"),
    ENTERTAINMENT: t("entertainment")
  };
  
  const SubCategoriesTranslations = {
    RESTAURANTS: t("restaurants"),
    CLUBS: t("clubs"),
    BARS: t("bars"),
    CAFES: t("cafes"),
    FAST_FOOD: t("fastFood"),
    ENTERTAINMENT: t("entertainment")
  };

  const SubCategoriesTranslationsNotResolved = {
    RESTAURANTS: "restaurants",
    CLUBS: "clubs",
    BARS: "bars",
    CAFES: "cafes",
    FAST_FOOD: "fastFood",
    ENTERTAINMENT: "entertainment"
  };

  const CategoriesTranslationsNotResolved = {
    RESTAURANTS: "restaurants",
    CLUBS: "clubs",
    BARS: "bars",
    CAFES: "cafes",
    FAST_FOOD: "fastFood",
    ENTERTAINMENT: "entertainment"
  };

  const Categories = [
    { id: 1, name: "RESTAURANTS", parent: 1 },
    { id: 2, name: "CLUBS", parent: 4 },
    { id: 3, name: "BARS", parent: 1 },
    { id: 4, name: "CAFES", parent: 1 },
    { id: 5, name: "FAST_FOOD", parent: 1 },
    { id: 6, name: "ENTERTAINMENT", parent: 1 },
  ];
  
  const SubCategories = [
    { id: 1, name: "RESTAURANTS", parent: 1 },
    { id: 2, name: "CLUBS", parent: 4 },
    { id: 3, name: "BARS", parent: 1 },
    { id: 4, name: "CAFES", parent: 1 },
    { id: 5, name: "FAST_FOOD", parent: 1 },
    { id: 6, name: "ENTERTAINMENT", parent: 1 },
  ];

  return { CategoriesImages, CategoriesTranslations, SubCategoriesTranslations, Categories, SubCategories, CategoriesImagesBlack, SubCategoriesTranslationsNotResolved, CategoriesTranslationsNotResolved};

}
export { CategoriesData };