// favouriteReducer.js
import { UPDATE_HEADER, RESET_UPDATE_HEADER } from './actions';

const initialState = {
  shouldUpdateHeader: false,
};

const favouriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HEADER:
      return {
        ...state,
        shouldUpdateHeader: action.payload
      };
    case RESET_UPDATE_HEADER:
      return {
        ...state,
        shouldUpdateHeader: false
      };
    default:
      return state;
  }
};

export default favouriteReducer;