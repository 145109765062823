export const UPDATE_HEADER = 'UPDATE_HEADER';
export const RESET_UPDATE_HEADER = 'RESET_UPDATE_HEADER';

export const updateFavourites = () => {
  return {
    type: UPDATE_HEADER,
    payload: true
  };
};


export const resetUpdateHeader = () => {
  return {
    type: RESET_UPDATE_HEADER
  };
};
