import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../PartnershipRequestPage.module.scss'
import { FormHelperText, Grid, MenuItem } from '@mui/material';
import { getRequest } from '../../../services/fetch/AxiosHelper';
import { FixedSizeList } from 'react-window';
import { StyledGrid, StyledTextHeading, StyledCheckBox } from '../CustomStyles';


const SpecificationForm = ({ selectedClassifications, setSelectedClassifications, selectedFeatures, setSelectedFeatures, 
                              specificationData, setSpecificationData, formErrors, setFormErrors }) => {
  const { t, i18n } = useTranslation();

  
  const [classifications, setClassifications] = useState([])
  const [features, setFeatures] = useState([])

  useEffect(() => {
    getRequest(`/classification/all?language=${i18n.language}`)
      .then(response => setClassifications(response.data))
      .catch(error => console.error('Failed to fetch classifications:', error));
  }, [])

  useEffect(() => {
    getRequest(`/feature/all?language=${i18n.language}`)
      .then(response => setFeatures(response.data))
      .catch(error => console.error('Failed to fetch features:', error));
  }, [])

  const handleRestrictionsClick = (name, value) => {
    setSpecificationData({
      ...specificationData,
      [name]: !value,
    });
  };

  const handleClassificationsClick = (value) => {
    setSelectedClassifications((prevClassifications) => {
      const isSelected = prevClassifications.includes(value);
  
      let newClassificationIds;
      if (isSelected) {
        newClassificationIds = prevClassifications.filter(id => id !== value);
      } else {
        if (prevClassifications.length === 5) {
          return prevClassifications
        }
        newClassificationIds = [...prevClassifications, value];
      }

      if (formErrors["selectedClassifications"]) {
        setFormErrors({ ...formErrors, ["selectedClassifications"]: '' });
      }
  
      return newClassificationIds
    });
  }

  const handleFeaturesClick = (value) => {
    setSelectedFeatures((prevFeatures) => {
      const isSelected = prevFeatures.includes(value);
  
      let newFeaturesIds;
      if (isSelected) {
        newFeaturesIds = prevFeatures.filter(id => id !== value);
      } else {
        if (prevFeatures.length === 5) {
          return prevFeatures
        }
        newFeaturesIds = [...prevFeatures, value];
      }
  
      if (formErrors["selectedFeatures"]) {
        setFormErrors({ ...formErrors, ["selectedFeatures"]: '' });
      }

      return newFeaturesIds
    });
  }

  const Row = React.memo(({ index, style }) => {
    const feature = features[index];
    return (
      <div style={{ ...style }}>
        <MenuItem
          disableRipple
          disableTouchRipple
          value={feature.id}
          key={"features" + feature.id}
          onClick={() => handleFeaturesClick(feature.id)}
          sx={{ paddingLeft: '0px!important' }}
        >
          <StyledCheckBox
            disableRipple
            disableFocusRipple
            disableTouchRipple
            checked={selectedFeatures.includes(feature.id)}
          />
          <div className={classnames(styles.checkboxText)}>
            {feature.name}
          </div>
        </MenuItem>
        </div>
    );
  });

  return (
    <div>
      <Grid 
          container 
          spacing={2}
          sx={{
              marginTop: '16px',
              '.MuiGrid-item' :{
                  paddingTop: 0
              }
          }}
          >

          <Grid item xs={12}>
            <StyledTextHeading>{t("placeRestrictions")}</StyledTextHeading>
          </Grid>
          <StyledGrid item xs={12} sm={3}>
            <MenuItem
            disableRipple
            disableTouchRipple
            key={"Specification-smoking"} 
            value={specificationData.allowSmoking}
            onClick={() => handleRestrictionsClick("allowSmoking", specificationData.allowSmoking)}
            sx={{
              paddingLeft: '0px!important'
            }}
            >
              <StyledCheckBox 
                disableRipple
                disableFocusRipple
                disableTouchRipple
                checked={specificationData.allowSmoking} 
              />
              <div className={classnames(styles.checkboxText)}>
                {t("smokingNotAllowed")}
              </div>
          </MenuItem> 
        </StyledGrid>
        <StyledGrid item xs={12} sm={3}>
          <MenuItem
            disableRipple
            disableTouchRipple
            key={"Specification-vaping"} 
            value={specificationData.allowVaping}
            onClick={() => handleRestrictionsClick("allowVaping", specificationData.allowVaping)}
            >
              <StyledCheckBox 
                disableRipple
                disableFocusRipple
                disableTouchRipple
                checked={specificationData.allowVaping} 
              />
              <div className={classnames(styles.checkboxText)}>
                {t("vapingNotAllowed")}
              </div>
          </MenuItem> 
        </StyledGrid>
        <StyledGrid item xs={12} sm={3}>
          <MenuItem
            disableRipple
            disableTouchRipple
            key={"Specification-adultsOnly"} 
            value={specificationData.adultsOnly}
            onClick={() => handleRestrictionsClick("adultsOnly", specificationData.adultsOnly)}
            >
              <StyledCheckBox 
                disableRipple
                disableFocusRipple
                disableTouchRipple
                checked={specificationData.adultsOnly} 
              />
              <div className={classnames(styles.checkboxText)}>
                {t("ageRestrictions")}
              </div>
          </MenuItem> 
        </StyledGrid>
        <StyledGrid item xs={12} sm={6}>
          <StyledTextHeading>{t("selectFiveClassifications")+"*"}</StyledTextHeading>
          <FormHelperText
            sx={{
                color: '#FFC0CB !important',
                marginLeft: '2px'
            }}
            >{formErrors.selectedClassifications ? formErrors.selectedClassifications : "\u00A0"}
          </FormHelperText>
          <div className={classnames(styles.placeFeaturesContainer)}>
            {classifications.map((classification) => {
              return (
              <MenuItem
                disableRipple
                disableTouchRipple
                key={"classification" + classification.id} 
                value={classification.id}
                onClick={() => handleClassificationsClick(classification.id)}
                sx={{
                  paddingLeft: '0px!important'
                }}
                >
                  <StyledCheckBox 
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={selectedClassifications.includes(classification.id)} 
                  />
                  <div className={classnames(styles.checkboxText)}>
                    {classification.name}
                  </div>
              </MenuItem>
              )
            })

            }
          </div>
        </StyledGrid>
        <StyledGrid item xs={12} sm={6}>
          <StyledTextHeading>{t("selectFiveFeatures") + "*"}</StyledTextHeading>
          <FormHelperText
            sx={{
                color: '#FFC0CB !important',
                marginLeft: '2px'
            }}
            >{formErrors.selectedFeatures ? formErrors.selectedFeatures : "\u00A0"}
          </FormHelperText>
          <FixedSizeList
            height={350}
            width="100%"
            itemSize={45}
            itemCount={features?.length}>
            {Row}
          </FixedSizeList>
        </StyledGrid>
      </Grid>
    </div>
  );
};

export default React.memo(SpecificationForm);