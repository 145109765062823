import React from 'react';
import Button from '@mui/material/Button';
import styles from './TransparentButton.module.scss'
import classnames from 'classnames';
import arrowRight from '../../../assets/images/common/arrow-right.svg'

function TransparentButton({ key, title, onClick = () => {}, showForwardIcon = false, isDisabled = false}) {

    return (
        <Button    
                className={classnames(styles.transparentButton)}
                variant="outlined"
                onClick={() => onClick()}
                disabled={isDisabled}
            >
                {title}
                {showForwardIcon && <img width="24px" height="24px" className={classnames(styles.arrowRight)} src={arrowRight} alt="forward arrow"/>}
            </Button>     
    );
}

export default React.memo(TransparentButton);
