import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import classnames from 'classnames';
import robotHead from '../../assets/images/common/robot-no-content.svg';
import robotTg from '../../assets/images/common/tg-robot.svg';
import phone from '../../assets/images/common/phone-instagram.webp';
import styles from './HomePage.module.scss'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import TitlesData from '../../data/TitlesData';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getRequest } from '../../services/fetch/AxiosHelper';
import './HomePage.scss';
import Top from '../../components/top/Top';
import TransparentButton from '../../components/mui/transparentButton/TransparentButton';
import CategoryCard from '../../components/mui/categoryCard/CategoryCard';
import foodImg from '../../assets/images/categories/food.webp';
import barsImg from '../../assets/images/categories/bars.webp';
import fastfoodImg from '../../assets/images/categories/fastfood.webp';
import entertainmentImg from '../../assets/images/categories/entertainment.webp';
import cafeImg from '../../assets/images/categories/cafe.webp';
import clubImg from '../../assets/images/categories/club.webp';
import OrangeButton from '../../components/mui/orangeButton/OrangeButton';
import OrangeButtonVioletHover from '../../components/mui/orangeButton/OrangeButtonVioletHover';

const HomePage = () => {

  const { t, i18n } = useTranslation();
  const {Titles} = TitlesData()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate()
  const [placesList, setPlacesList] = useState();
  const location = useLocation();

  useEffect(() => {
    requestWgtChoice()
    window.addEventListener('resize', handleResize);
  }, [i18n.language]);

  const requestWgtChoice = () => {
    getRequest(`places/main?language=${i18n.language}`)
            .then((response) => response.data)
            .then((data) => {
                setPlacesList(data)
            })
            .catch((error) => {
                console.log(error)
            });
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  function openLinkInNewTab(url) {
    window.open(url, "_blank")
  };

  const parseDataFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      scrollY: searchParams.get('scrollY') || 0,
    };
  }

  const setScrollToUrl = (position) => {
    const searchParams = new URLSearchParams();
    searchParams.set("scrollY", position);
    const parameters = searchParams.toString()
    navigate(`?${parameters}`, { replace: true });
  }

  useEffect(() => {
    let scrollY = parseDataFromUrl().scrollY
    if (scrollY && scrollY > 0) {
        setTimeout(() => {
            window.scrollTo({
            left: 0,
            top: scrollY,
            behavior: 'smooth'
            });
        }, 300);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("scrollY");
        const parameters = searchParams.toString();
        navigate(`?${parameters}`, { replace: true });
    }
  }, [])

  return (
    <div className={classnames(styles.mainContent)}>
       <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{Titles.home}</title>
        <meta name='description' content={t("homePageMetaDesc")} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t("homePageOgTitle")} />
        <meta property="og:description" content={t("homePageOgDescription")} />
        <meta property="og:url" content={t("websiteUrl")} />
        <meta name="keywords" content={t("keyWords")} />
       </Helmet>
    <div className={classnames(styles.introContainer)}>
        <div className={classnames('container-fluid', styles.imageContainer, styles.noPadding)}>
            <div>
                <div id="carouselExampleIndicators" className={classnames('carousel', 'slide', styles.imageContainer, styles.noPadding)}>
                    <div className={classnames(styles.pictureContainer)}>
                        <Carousel>
                        {
                        placesList?.length > 0 ? ( 
                            placesList?.map((place) => {
                                return (            
                                    <Carousel.Item key={place.id} className="rounded-slide">
                                        <div className="tint">
                                            <img
                                                className={classnames(styles.sliderImagesSize, styles.sliderImageFit, "d-block w-100 rounded-slide")}
                                                src={screenWidth > 576 ? place.mainImage: place.mainImagePhone}
                                                alt={place.placeName}
                                            />
                                        </div>
                                    
                                    <div className={classnames(styles.overlayText)}>
                                            <div className={classnames(styles.carouselTextContent)}>
                                                <p className={classnames('text-white', styles.objectName)}>{place.placeName.toLocaleUpperCase()}</p>
                                                <p className={classnames('text-white', styles.objectDescription)}>{place.placeDescription}</p>
                                            </div>
                                            <div className={classnames(styles.detailsButton)}>
                                            <Link to={`/${i18n.language}/` + (place.isFranchise ? `franchise/${place.id}` : `places/${place.uri}`)} style={{ display: 'inline-block', width: '100%', textDecoration: 'none' }}>
                                                    <OrangeButtonVioletHover title={t("more")} />
                                                </Link>
                                            </div>
                                    </div>
                                    </Carousel.Item>
                                );
                            })
                        ) : (
                            <Carousel.Item key={"placeholder"} className="rounded-slide">
                                <div className="tint">
                                    <img
                                        className={classnames('text-white', "rounded-slide", "d-block", styles.sliderImagesSize)}
                                        src={robotHead}
                                        alt={"placeholder"}
                                        width="300px"
                                        height="600px"
                                        />
                                </div>
                            </Carousel.Item>
                        )
                        }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>


        <div className={classnames(styles.wgtBanner)}>
            <div className={classnames('row', styles.bannerContent)}>
                <div className={classnames('col-xs-5', 'col-sm-6', 'col-md-6', 'col-lg-5', 'col-xl-5', 'col-xxl-5', 'col-5', styles.contentSide)}>
                    <div className={classnames(styles.row, 'd-flex', 'justify-content-left', styles.bannerHeader)}>
                        <div className={classnames('col-12')}>
                            <h2>{t("yourGuide").toUpperCase()}</h2>
                        </div>
                    </div>
                </div>
                <div className={classnames('col-xs-7', 'col-sm-6', 'col-md-6', 'col-lg-7', 'col-xl-7', 'col-xxl-7', 'col-7', styles.logoSide)}>
                    <div className={classnames('align-self-center', 'justify-content-center')}>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className={classnames('container-fluid', styles.categoryContainer)}>
        <div className={classnames('row', styles.row)}>
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"1"} img={foodImg} title={t("restaurants")} uri={"/places/category/1"}/>
            </div>
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"2"} img={clubImg} title={t("clubs")} uri={"/places/category/2"}/>
            </div>
            
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"3"} img={barsImg} title={t("bars")} uri={"/places/category/3"}/>
            </div>
            
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"4"} img={cafeImg} title={t("cafes")} uri={"/places/category/4"}/>
            </div>
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"5"} img={fastfoodImg} title={t("fastFood")} uri={"/places/category/5"}/>
            </div>
            <div className={classnames('col-xs-6', 'col-sm-6', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-6')}>
                <CategoryCard key={"6"} img={entertainmentImg} title={t("entertainment")} uri={"/places/category/6"}/>
            </div>
        </div>
    </div>

    <div className={classnames(styles.seacrhPlacesTgBot)}>
            <div className={classnames('row', styles.row, 'noMargin')}>
                <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-6', 'col-xl-6', 'col-xxl-5', 'col-12', styles.robotTgImage, 'd-flex', 'justify-content-center')}>
                    <img width={screenWidth > 600 ? '416px' : '100%' }height={screenWidth > 600 ? '440px' : '100%' } loading="lazy" className={styles.robotTg} src={robotTg} alt="robotTg" />
                </div>
                <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-6', 'col-xl-6', 'col-xxl-7', 'col-12', styles.followUs, 'align-self-center')}>
                    <div className={classnames('row', styles.row, styles.headingTgBot)}>
                        <h1>{t("whantToSpendEvening").toLocaleUpperCase()}</h1>
                    </div>
                    <div className={classnames('row', styles.row, styles.navButtons, 'd-flex', 'justify-content-end')}>
                        <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.navButton)}>
                        <Link to={`/${i18n.language}/places/all`} style={{width: '100%', textDecoration: 'none' }}>
                            <OrangeButton title={t("searchPlaces")} />
                        </Link>
                            
                        </div>
                        <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.navButton)}>
                            <TransparentButton
                                title={t("goToBot")}
                                onClick={() => {openLinkInNewTab("https://t.me/wegotonight_bot")}}
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className={classnames(styles.topContainer)}>
            <Top 
                clickedOnCard={ () => {
                    setScrollToUrl(window.scrollY)
                }}
                />
        </div>


         <div className={classnames(styles.followUsOnDifferentPlatforms)}>
            <div className={classnames('row', styles.row, 'noMargin')}>
                <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-5', 'col-xl-5', 'col-xxl-5', 'col-12', styles.phoneImage, 'd-flex', 'justify-content-center')}>
                    <img width="336px" height="374px" loading="lazy" className={styles.phone} src={phone} alt="phone" />
                </div>
                <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-7', 'col-xl-7', 'col-xxl-7', 'col-12', styles.followUs, 'align-self-center')}>
                    <div className={classnames('row', styles.row, styles.heading)}>
                        <p>{t("followUsOnDifferentSocialMedias").toLocaleUpperCase()}</p>
                    </div>
                    <div className={classnames('row', styles.row, styles.navButtons, 'd-flex', 'justify-content-start')}>
                        <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.navButton)}>
                            <TransparentButton
                                title="INSTAGRAM"
                                onClick={() => {openLinkInNewTab("https://www.instagram.com/wegotonight_riga/")}}
                                showForwardIcon={true}
                            />
                        </div>
                        <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.navButton)}>
                            <TransparentButton
                                title="TELEGRAM"
                                onClick={() => {openLinkInNewTab("https://t.me/wegotonightinriga")}}
                                showForwardIcon={true}
                            />
                        </div>
                        <div className={classnames('col-xs-12', 'col-sm-12', 'col-md-12', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.navButton)}>
                            <TransparentButton
                                title="TG BOT"
                                onClick={() => {openLinkInNewTab("https://t.me/wegotonight_bot")}}
                                showForwardIcon={true}
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  );
};

export default HomePage;